import {
  DriverStatusesArabic,
  DriverStatusesChines,
  DriverStatusesEnglish,
} from "./driverStatuses/driverStatuses";
import {
  manageTaxArabic,
  manageTaxChines,
  manageTaxEnglish,
} from "./manage Tax/manageTax";
import {
  metaFieldArabic,
  metaFieldChines,
  metaFieldEnglish,
} from "./metaFields/metaField";
import {
  permissionArabic,
  permissionChines,
  permissionEnglish,
} from "./permissions/permission";
import {
  profileArabic,
  profileChines,
  profileEnglish,
} from "./profile/profile";
import {
  SecurityArabic,
  SecurityChines,
  SecurityEnglish,
} from "./security/security";
import {
  shipmentTabArabic,
  shipmentTabChines,
  shipmentTabEnglish,
} from "./shipmentTab/shipmentTab";
import {
  userRolesArabic,
  userRolesChines,
  userRolesEnglish,
} from "./userRoles/userRoles";
import { UserArabic, UserChines, UserEnglish } from "./users/user";

export const settingEnglish = {
  ...DriverStatusesEnglish,
  ...metaFieldEnglish,
  ...permissionEnglish,
  ...shipmentTabEnglish,
  ...userRolesEnglish,
  ...profileEnglish,
  ...SecurityEnglish,
  ...UserEnglish,
  ...manageTaxEnglish,
  SETTINGS: "Settings",
  APP_SETTING: "App Setting",
  NOTIFICATION: "Notification",
  BILLING_HISTORY: "Billing History",
  DOCUMENT_SETTINGS: "Document Settings",
};
export const settingArabic = {
  ...DriverStatusesArabic,
  ...metaFieldArabic,
  ...permissionArabic,
  ...shipmentTabArabic,
  ...userRolesArabic,
  ...profileArabic,
  ...SecurityArabic,
  ...UserArabic,
  ...manageTaxArabic,
  SETTINGS: "إعدادات",
  APP_SETTING: "App Setting",
  NOTIFICATION: "Notification",
  BILLING_HISTORY: "Billing History",
  DOCUMENT_SETTINGS: "Document Settings",
};
export const settingChinese = {
  ...DriverStatusesChines,
  ...metaFieldChines,
  ...permissionChines,
  ...shipmentTabChines,
  ...userRolesChines,
  ...profileChines,
  ...SecurityChines,
  ...UserChines,
  ...manageTaxChines,
  SETTINGS: "设置",
  APP_SETTING: "应用程序设置",
  NOTIFICATION: "通知",
  BILLING_HISTORY: "账单历史",
  DOCUMENT_SETTINGS: "文档设置",
};
