import React, { useEffect, useState } from "react";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import { purple } from "@mui/material/colors";
import { Grid, InputLabel, TextField } from "@mui/material";
import { styleSheet } from "../../../assets/styles/style";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  ActionButtonCustom,
  useMapAutocompleteSetter,
} from "../../../utilities/helpers/Helpers";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import GoogleMapWithSearch from "../resuseAbleModals/GoogleMapWithSearch";

import { UpdateOrderLatLng } from "../../../api/AxiosInterceptors";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";

const UpdateOrderAddressLatLangModal = (props) => {
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [isLoading, setIsLoading] = useState(false);
  const handleFocus = (event) => event.target.select();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control,
  } = useForm();
  let {
    open,
    setOpen,
    slectedAddress,
    setSelectedAddress,
    getAllDeliveryTask,
  } = props;
  const handleClose = () => {
    setSelectedAddress((prev) => ({
      ...prev,
      open: false,
      coordinates: {},
    }));
    setValue("latitude", null);
    setValue("longitude", null);
  };
  useMapAutocompleteSetter(
    autocomplete
    // allCountries,
    // allRegions,
    // allCities,
    // setValue
  );

  useEffect(() => {
    if (slectedAddress.selectedItem) {
      setValue("latitude", slectedAddress?.coordinates?.lat);
      setValue("longitude", slectedAddress?.coordinates?.lng);
    }
  }, [slectedAddress]);
  const updateOrderAddressLatLang = (data) => {
    let param = {
      OrderNo: slectedAddress?.selectedItem?.OrderNo,
      latitude: data.latitude,
      longitude: data.longitude,
    };
    setIsLoading(true);
    UpdateOrderLatLng(param)
      .then((res) => {
        if (!res.data.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res.data.errors);
        } else {
          successNotification("Address lat lng updated successfully");
          getAllDeliveryTask();
          setOpen(false);
        }
      })
      .catch((e) => {
        errorNotification(
          LanguageReducer?.languageType
            ?.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST
        );
        console.log("e", e);
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };
  const splitLatAndLong = () => {};
  return (
    <ModalComponent
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title={"Update Order Address Coordinates "}
      actionBtn={
        <ModalButtonComponent
          title={"Update Order Address Coordinates "}
          bg={purple}
          loading={isLoading}
          type={"submit"}
        />
      }
      component={"form"}
      onSubmit={handleSubmit(updateOrderAddressLatLang)}
    >
      <Grid container spacing={2} sx={{ p: "15px", paddingLeft: "0" }}>
        <Grid item xs={12}>
          <InputLabel sx={styleSheet.inputLabel}>
            {LanguageReducer?.languageType?.LATITUDE_TEXT}
          </InputLabel>
          <TextField
            placeholder={""}
            onFocus={handleFocus}
            type="text"
            size="small"
            id="latitude"
            name="latitude"
            fullWidth
            variant="outlined"
            {...register("latitude", {
              required: {
                value: true,
                message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
              },
            })}
            error={Boolean(errors.latitude)} // set error prop
            helperText={errors.latitude?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={styleSheet.inputLabel}>
            {LanguageReducer?.languageType?.LONGITUDE_TEXT}
          </InputLabel>
          <TextField
            placeholder={""}
            onFocus={handleFocus}
            type="text"
            size="small"
            id="longitude"
            name="longitude"
            fullWidth
            variant="outlined"
            {...register("longitude", {
              required: {
                value: true,
                message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
              },
            })}
            error={Boolean(errors.longitude)} // set error prop
            helperText={errors.longitude?.message}
          />
        </Grid>
        <Grid item xs={12} alignSelf={"end"}>
          <ActionButtonCustom
            onClick={() => setOpenLocationModal(true)}
            startIcon={<FmdGoodOutlinedIcon />}
            label={LanguageReducer?.languageType?.FROM_MAP_TEXT}
            height={{ ...styleSheet.fromMapButton }}
          />
        </Grid>
      </Grid>
      {openLocationModal && (
        <GoogleMapWithSearch
          open={openLocationModal}
          setOpen={setOpenLocationModal}
          setValue={setValue}
          setAutocomplete={setAutocomplete}
          splitLatAndLong={splitLatAndLong}
        />
      )}
    </ModalComponent>
  );
};

export default UpdateOrderAddressLatLangModal;
