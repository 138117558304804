import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Colors from "../../utilities/helpers/Colors";
export default function SwitchMui({
  checked,
  sx = {
    mx: 1,
  },
  label = "",
  onChange = () => {},
}) {
  return (
    <FormGroup>
      <FormControlLabel
        sx={{ ...sx, "& .MuiTypography-root": { fontSize: 12 }, p: 0, m: 0 }}
        control={
          <Switch sx={{ mx: 0 }} checked={checked} onChange={onChange} />
        }
        label={label}
      />
    </FormGroup>
  );
}
