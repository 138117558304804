export const codPendingEnglish = {
  MY_CARRIER_COD_PENDING_TEXT: "COD Pending",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "Payment Method",
  MY_CARRIER_COD_PENDING_CUSTOMER: "Customer",
  MY_CARRIER_COD_PENDING_RETURNS: "Returns",
  MY_CARRIER_COD_PENDING_START_DATE: "Start Date",
  MY_CARRIER_COD_PENDING_END_DATE: "End Date",
  MY_CARRIER_COD_PENDING_STORE: "Store",
  MY_CARRIER_COD_PENDING_ORDER_TYPE: "Order Type",
  MY_CARRIER_COD_PENDING_ORDER_NO: "Order No.",
  MY_CARRIER_COD_PENDING_CARRIER_NAME: "Carrier Name",
  MY_CARRIER_COD_PENDING_STORE_REF: "Store Ref",
  MY_CARRIER_COD_PENDING_TRACKING_STATUS: "Tracking Status",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "Payment Method",
  MY_CARRIER_COD_PENDING_PAYMENT: "Payment",
  MY_CARRIER_COD_PENDING_ORDER_DATE: "Order Date",
  MY_CARRIER_COD_PENDING_AMOUNT: "Amount",
  MY_CARRIER_COD_PENDING_ACTION: "Action",
  MY_CARRIER_COD_PENDING_UPDATE_ORDER_AMOUNT: "Update Order Amount",
  MY_CARRIER_COD_PENDING_NEW_COD_AMOUNT: "New COD Amount",
  MY_CARRIER_COD_PENDING_UPDATE_AMOUNT: "Update Amount",
  MY_CARRIER_COD_PENDING_CREATE_SETTLEMENT: "Create Settlement",
};
export const codPendingArabic = {
  MY_CARRIER_COD_PENDING_TEXT: "COD Pending",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "طريقة الدفع",
  MY_CARRIER_COD_PENDING_CUSTOMER: "العميل",
  MY_CARRIER_COD_PENDING_RETURNS: "المرتجعات",
  MY_CARRIER_COD_PENDING_START_DATE: "تاريخ البداية",
  MY_CARRIER_COD_PENDING_END_DATE: "تاريخ النهاية",
  MY_CARRIER_COD_PENDING_STORE: "المتجر",
  MY_CARRIER_COD_PENDING_ORDER_TYPE: "نوع الطلب",
  MY_CARRIER_COD_PENDING_ORDER_NO: "رقم الطلب",
  MY_CARRIER_COD_PENDING_CARRIER_NAME: "اسم الناقل",
  MY_CARRIER_COD_PENDING_STORE_REF: "رقم المتجر",
  MY_CARRIER_COD_PENDING_TRACKING_STATUS: "حالة التتبع",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "طريقة الدفع",
  MY_CARRIER_COD_PENDING_PAYMENT: "الدفع",
  MY_CARRIER_COD_PENDING_ORDER_DATE: "تاريخ الطلب",
  MY_CARRIER_COD_PENDING_AMOUNT: "المبلغ",
  MY_CARRIER_COD_PENDING_ACTION: "الإجراء",
  MY_CARRIER_COD_PENDING_UPDATE_ORDER_AMOUNT: "تحديث مبلغ الطلب",
  MY_CARRIER_COD_PENDING_NEW_COD_AMOUNT: "مبلغ الدفع الجديد",
  MY_CARRIER_COD_PENDING_UPDATE_AMOUNT: "تحديث المبلغ",
  MY_CARRIER_COD_PENDING_CREATE_SETTLEMENT: "إنشاء تسوية",
};
export const codPendingChinese = {
  MY_CARRIER_COD_PENDING_TEXT: "COD Pending",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "付款方式",
  MY_CARRIER_COD_PENDING_CUSTOMER: "客户",
  MY_CARRIER_COD_PENDING_RETURNS: "退货",
  MY_CARRIER_COD_PENDING_START_DATE: "开始日期",
  MY_CARRIER_COD_PENDING_END_DATE: "结束日期",
  MY_CARRIER_COD_PENDING_STORE: "店铺",
  MY_CARRIER_COD_PENDING_ORDER_TYPE: "订单类型",
  MY_CARRIER_COD_PENDING_ORDER_NO: "订单号",
  MY_CARRIER_COD_PENDING_CARRIER_NAME: "承运人名称",
  MY_CARRIER_COD_PENDING_STORE_REF: "店铺参考",
  MY_CARRIER_COD_PENDING_TRACKING_STATUS: "跟踪状态",
  MY_CARRIER_COD_PENDING_PAYMENT_METHOD: "付款方式",
  MY_CARRIER_COD_PENDING_PAYMENT: "付款",
  MY_CARRIER_COD_PENDING_ORDER_DATE: "订单日期",
  MY_CARRIER_COD_PENDING_AMOUNT: "金额",
  MY_CARRIER_COD_PENDING_ACTION: "操作",
  MY_CARRIER_COD_PENDING_UPDATE_ORDER_AMOUNT: "更新订单金额",
  MY_CARRIER_COD_PENDING_NEW_COD_AMOUNT: "新货到付款金额",
  MY_CARRIER_COD_PENDING_UPDATE_AMOUNT: "更新金额",
  MY_CARRIER_COD_PENDING_CREATE_SETTLEMENT: "C创建结算",
};