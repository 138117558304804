export const manageTaxEnglish = {
  SETTING_MANAGE_TAX_TEXT: "Manage Tax",
  SETTING_MANAGE_TAX_NAME: "Name",
  SETTING_MANAGE_TAX_PERCENTAGE: "Percentage",
  SETTING_MANAGE_TAX_STATUS: "Status",
  SETTING_MANAGE_TAX_ACTION: "Action",
  SETTING_MANAGE_TAX_ADD_TAX: "Add Tax",
  SETTING_MANAGE_TAX_FILTER: "Filter",
  SETTING_MANAGE_TAX_START_DATE: "Start Date",
  SETTING_MANAGE_TAX_END_DATE: "End Date",
  SETTING_MANAGE_TAX_CLEAR_FILTER: "Clear Filter",
  SETTING_MANAGE_TAX_SELECT_TAX: "Select Tax",
  SETTING_MANAGE_TAX_ADD_PERCENTAGE: "Add Percentage",
};
export const manageTaxArabic = {
  SETTING_MANAGE_TAX_TEXT: "إدارة الضرائب",
  SETTING_MANAGE_TAX_NAME: "الاسم",
  SETTING_MANAGE_TAX_PERCENTAGE: "النسبة المئوية",
  SETTING_MANAGE_TAX_STATUS: "الحالة",
  SETTING_MANAGE_TAX_ACTION: "الإجراء",
  SETTING_MANAGE_TAX_ADD_TAX: "إضافة ضريبة",
  SETTING_MANAGE_TAX_FILTER: "الفلاتر",
  SETTING_MANAGE_TAX_START_DATE: "Start Date",
  SETTING_MANAGE_TAX_END_DATE: "End Date",
  SETTING_MANAGE_TAX_CLEAR_FILTER: "Clear Filter",
  SETTING_MANAGE_TAX_SELECT_TAX: "الضريبة المحددة",
  SETTING_MANAGE_TAX_ADD_PERCENTAGE: "إضافة نسبة",
};
export const manageTaxChines = {
  SETTING_MANAGE_TAX_TEXT: "管理税收",
  SETTING_MANAGE_TAX_NAME: "名称",
  SETTING_MANAGE_TAX_PERCENTAGE: "百分比",
  SETTING_MANAGE_TAX_STATUS: "状态",
  SETTING_MANAGE_TAX_ACTION: "操作",
  SETTING_MANAGE_TAX_ADD_TAX: "添加税收",
  SETTING_MANAGE_TAX_FILTER: "过滤器",
  SETTING_MANAGE_TAX_START_DATE: "Start Date",
  SETTING_MANAGE_TAX_END_DATE: "End Date",
  SETTING_MANAGE_TAX_CLEAR_FILTER: "Clear Filter",
  SETTING_MANAGE_TAX_SELECT_TAX: "选择税",
  SETTING_MANAGE_TAX_ADD_PERCENTAGE: "添加百分比",
};