import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonComponent from "../../../.reUseableComponents/Buttons/ButtonComponent";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import {
  GetAllClientRolePermissionGroup,
  AddUpdateClientRolePermissionGroup,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import { EnumOptions } from "../../../utilities/enum";

import {
  CheckboxComponent,
  CicrlesLoading,
  GridContainer,
  GridItem,
  PageMainBox,
  fetchMethod,
  useGetAllClientUserRole,
} from "../../../utilities/helpers/Helpers";
import AddUserRoleModal from "../../../components/modals/settingsModals/AddUserRoleModal";
import { ProfileDetailsBox } from "../../Profile/Profile/Profile";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import UtilityClass from "../../../utilities/UtilityClass";
import { useSelector } from "react-redux";
export default function Permission() {
  const [openAddUserRoleModal, setOpenAddUserRoleModal] = useState(false);
  const [isRoleAdded, setIsRoleAdded] = useState(false);
  const handleOpenAddUserRoleModal = () => {
    setOpenAddUserRoleModal(true);
  };

  const handleCloseAddUserRoleModal = () => {
    setOpenAddUserRoleModal(false);
  };

  const [selectedClientRole, setSelectedClientRole] = useState({
    clientUserRoleId: 0,
    roleName: "Select Please",
  });
  const { clientUserRole } = useGetAllClientUserRole(isRoleAdded);

  const [expandedTab, setExpandedTab] = useState({});
  const [isloading, setIsLoading] = useState(false);
  const [clientPermissionGroup, setClientPermissionGroup] = useState({
    loading: true,
    data: [],
  });
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [selectedClientPermissionGroup, setSelectedClientPermissionGroup] =
    useState([]);
  const handleGetAllClientRolePermissionGroup = async (id) => {
    const { response } = await fetchMethod(
      () => GetAllClientRolePermissionGroup(id),
      setClientPermissionGroup
    );
    if (response && response.result) {
      setClientPermissionGroup((prev) => ({ ...prev, data: response.result }));
      setSelectedClientPermissionGroup(response.result);
    }
  };

  useEffect(() => {
    handleGetAllClientRolePermissionGroup(selectedClientRole.clientUserRoleId);
  }, [selectedClientRole]);

  const hasExpandedTab = Object.values(expandedTab).some((tab) => tab === true);
  const handleUpdatePermisions = () => {
    setIsLoading(true);
    const listItems = clientPermissionGroup.data.flatMap((group) =>
      group.items.map((item) => ({
        clientRolePgid: item.clientRolePGId,
        rolePermissionGroupId: item.permissionGroupId,
        havePermission: item.havePermission === 1 ? true : false,
      }))
    );
    const requestData = {
      listItems,
      clientUserRoleId: selectedClientRole.clientUserRoleId,
    };
    AddUpdateClientRolePermissionGroup(requestData)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res.data.errors);
        } else {
          successNotification("Permissions updated successfully");
        }
      })
      .catch((e) => {
        console.log("e", e);
        errorNotification("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <PageMainBox>
      {clientPermissionGroup.loading ? (
        <CicrlesLoading />
      ) : (
        <ProfileDetailsBox
          // title={"Store Permissions"}
          // description={"Manage permission for Shipra"}
          rightBtn={
            <div style={{ display: "flex", gap: "10px" }}>
              <ButtonComponent
                style={{ marginRight: "10px" }}
                title={
                  LanguageReducer?.languageType
                    ?.SETTING_PERMISSION_ADD_USER_ROLE
                }
                loading={isloading}
                onClick={handleOpenAddUserRoleModal}
              />
              <ButtonComponent
                title={
                  LanguageReducer?.languageType
                    ?.SETTING_PERMISSION_UPDATE_PERMISSIONS
                }
                loading={isloading}
                onClick={() => {
                  handleUpdatePermisions();
                }}
              />
            </div>
          }
        >
          <GridContainer>
            <GridItem item xs={12}>
              <SelectComponent
                name="clientUserRole"
                options={clientUserRole}
                value={selectedClientRole}
                optionLabel={EnumOptions.ROLE.LABEL}
                optionValue={EnumOptions.ROLE.VALUE}
                onChange={(name, val) => setSelectedClientRole(val)}
              />
            </GridItem>
            <GridItem xs={12}>
              <Box className={"flex_between"} sx={{ marginLeft: "10px" }}>
                <CheckboxComponent
                  label={
                    LanguageReducer?.languageType?.SETTING_PERMISSION_SELECT_ALL
                  }
                  checked={selectedClientPermissionGroup.every((tab) =>
                    tab.items.every((dt) => dt.havePermission === 1)
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedClientPermissionGroup((prev) => {
                        const _selectedClientPermissionGroup = [...prev];
                        const _mappedData = _selectedClientPermissionGroup.map(
                          (tab) => {
                            return {
                              ...tab,
                              items: tab.items.map((dt) => {
                                return {
                                  ...dt,
                                  havePermission: 1,
                                };
                              }),
                            };
                          }
                        );
                        return _mappedData;
                      });
                    } else {
                      setSelectedClientPermissionGroup(
                        clientPermissionGroup.data
                      );
                    }
                  }}
                />
                <ButtonComponent
                  title={
                    hasExpandedTab
                      ? "Collapse all"
                      : LanguageReducer?.languageType
                          ?.SETTING_PERMISSION_EXPAND_ALL
                  }
                  onClick={() => {
                    const _expandedTab = {};
                    if (hasExpandedTab) {
                      clientPermissionGroup.data.forEach((dt, index) => {
                        _expandedTab[index] = false;
                      });
                    } else {
                      clientPermissionGroup.data.forEach((dt, index) => {
                        _expandedTab[index] = true;
                      });
                    }
                    setExpandedTab(_expandedTab);
                  }}
                />
              </Box>
            </GridItem>
            <GridItem xs={12}>
              {selectedClientPermissionGroup.map((item, index) => (
                <Accordion
                  key={item.index}
                  expanded={expandedTab[index] ? true : false}
                  onChange={() =>
                    setExpandedTab((prev) => ({
                      ...prev,
                      [index]: !prev[index],
                    }))
                  }
                  sx={{
                    boxShadow: "none !important",
                    borderTop: "1px solid #d3d3d375",
                    margin: "0px !important",
                    backgroundColor: "unset",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={styleSheet.permissionAccordion}
                  >
                    <CheckboxComponent
                      label={item.groupName}
                      checked={item.items.every(
                        (dt) => dt.havePermission === 1
                      )}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedClientPermissionGroup((prev) => {
                            const _selectedClientPermissionGroup = [...prev];
                            const _mappedData = _selectedClientPermissionGroup[
                              index
                            ].items.map((dt) => {
                              return {
                                ...dt,
                                havePermission: 1,
                              };
                            });
                            _selectedClientPermissionGroup[index].items =
                              _mappedData;
                            return _selectedClientPermissionGroup;
                          });
                        } else {
                          setSelectedClientPermissionGroup((prev) => {
                            const _selectedClientPermissionGroup = [...prev];
                            const _mappedData = _selectedClientPermissionGroup[
                              index
                            ].items.map((dt) => {
                              return {
                                ...dt,
                                havePermission: 0,
                              };
                            });
                            _selectedClientPermissionGroup[index].items =
                              _mappedData;
                            return _selectedClientPermissionGroup;
                          });
                        }
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails sx={styleSheet.permissionAccordionDetail}>
                    {item.items.map((data, item_ind) => (
                      <FormControlLabel
                        key={data.permissionGroupId}
                        control={
                          <CheckboxComponent
                            checked={data.havePermission === 0 ? false : true}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedClientPermissionGroup((prev) => {
                                  const _selectedClientPermissionGroup = [
                                    ...prev,
                                  ];
                                  const _selectedSubTab =
                                    _selectedClientPermissionGroup[index].items[
                                      item_ind
                                    ];
                                  _selectedSubTab.havePermission = 1;
                                  _selectedClientPermissionGroup[index].items[
                                    item_ind
                                  ] = _selectedSubTab;
                                  return _selectedClientPermissionGroup;
                                });
                              } else {
                                setSelectedClientPermissionGroup((prev) => {
                                  const _selectedClientPermissionGroup = [
                                    ...prev,
                                  ];
                                  const _selectedSubTab =
                                    _selectedClientPermissionGroup[index].items[
                                      item_ind
                                    ];
                                  _selectedSubTab.havePermission = 0;
                                  _selectedClientPermissionGroup[index].items[
                                    item_ind
                                  ] = _selectedSubTab;
                                  return _selectedClientPermissionGroup;
                                });
                              }
                            }}
                          />
                        }
                        sx={{ display: "block" }}
                        label={data.groupName}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </GridItem>
          </GridContainer>
        </ProfileDetailsBox>
      )}
      {openAddUserRoleModal && (
        <AddUserRoleModal
          open={openAddUserRoleModal}
          setOpen={setOpenAddUserRoleModal}
          onClose={handleCloseAddUserRoleModal}
          setIsRoleAdded={setIsRoleAdded}
        />
      )}
    </PageMainBox>
  );
}
