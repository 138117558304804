import { Box, InputLabel, TextField } from "@mui/material";
import { purple } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { UpdateActiveCarrierClientSettingConfig } from "../../api/AxiosInterceptors";
import { styleSheet } from "../../assets/styles/style";
import { EnumOptions } from "../../utilities/enum";
import {
  CustomColorLabelledOutline,
  GridContainer,
  GridItem,
  fetchMethod,
  getLowerCase,
  getTrimValue,
} from "../../utilities/helpers/Helpers";
import { errorNotification, successNotification } from "../../utilities/toast";
import ModalButtonComponent from "../Buttons/ModalButtonComponent";
import SelectComponent from "../TextField/SelectComponent";
import ModalComponent from "./ModalComponent";

export const inputTypesEnum = Object.freeze({
  SELECT: "select",
  TEXT: "text",
  NUMBER: "number",
});

const ConfigSettingModal = (props) => {
  const { open, onClose, data } = props;
  const [configSettings, setConfigSettings] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleInputChange = (section_index, input_index, value) => {
    setConfigSettings((prev) => {
      const _configSettings = [...prev];
      _configSettings[section_index].inputData[input_index].value = value;
      return _configSettings;
    });
  };

  const handleUpdateConfigSettings = async () => {
    const _submitData = configSettings.map((section) => {
      const _section = { ...section };
      const _section_inputData = section.inputData.map((input_dt) => {
        const _input_data = { ...input_dt };
        if (!getTrimValue(input_dt.value) || input_dt.value.value === 0) {
          errorNotification(`The ${input_dt.label}: Is required to proceed`);
          return false;
        }
        if (getLowerCase(_input_data.type) === inputTypesEnum.SELECT) {
          const manipulated_data = _input_data.data.map((option) => {
            return option.value;
          });

          _input_data.data = manipulated_data;
          _input_data.value = input_dt.value.value;
        }
        return _input_data;
      });
      _section.inputData = _section_inputData;
      return _section;
    });

    const { response } = await fetchMethod(
      () =>
        UpdateActiveCarrierClientSettingConfig(
          data.selectedActiveCarrierId,
          _submitData
        ),
      setSubmitLoading,
      false
    );
    if (response.isSuccess) {
      successNotification("Config Settings updated successfully");
      onClose();
    } else {
      errorNotification("Something went wrong while updating Config settings");
    }
  };

  useEffect(() => {
    const _configSettings = data.data.map((section) => {
      const _section = { ...section };
      const _section_inputData = section.inputData.map((input_dt) => {
        const _input_data = { ...input_dt };
        if (getLowerCase(_input_data.type) === inputTypesEnum.SELECT) {
          const manipulated_data = _input_data.data.map((option) => {
            return { label: option, value: option };
          });

          _input_data.data = manipulated_data;
          _input_data.value = { label: input_dt.value, value: input_dt.value };
        }
        return _input_data;
      });
      _section.inputData = _section_inputData;
      return _section;
    });
    setConfigSettings(_configSettings);
  }, [open]);

  return (
    <>
      <ModalComponent
        open={open}
        onClose={onClose}
        maxWidth="md"
        title={"Config Settings"}
        actionBtn={
          <ModalButtonComponent
            loading={submitLoading}
            title={"Save"}
            bg={purple}
            onClick={handleUpdateConfigSettings}
          />
        }
      >
        <GridContainer>
          {configSettings.map((section, section_index, arr) => (
            <GridItem
              lg={arr.length > 1 ? 6 : 12}
              md={arr.length > 1 ? 6 : 12}
              sm={12}
            >
              <CustomColorLabelledOutline
                label={section.sectionName}
                key={section.key}
              >
                <Box key={section.key}>
                  {section.inputData.map((input, input_index) => (
                    <Box key={input.key} marginBottom={1}>
                      <InputLabel
                        required={input.required}
                        sx={styleSheet.inputLabel}
                      >
                        {input.label}
                      </InputLabel>
                      {getLowerCase(input.type) === inputTypesEnum.SELECT && (
                        <SelectComponent
                          height={40}
                          name={input.key}
                          options={input.data}
                          optionLabel={
                            EnumOptions.CONFIG_SETTING_INPUTDATA_OPTIONS.LABEL
                          }
                          optionValue={
                            EnumOptions.CONFIG_SETTING_INPUTDATA_OPTIONS.VALUE
                          }
                          value={input.value}
                          onChange={(e, val) => {
                            handleInputChange(section_index, input_index, val);
                          }}
                        />
                      )}
                      {(getLowerCase(input.type) === inputTypesEnum.TEXT ||
                        getLowerCase(input.type) === inputTypesEnum.NUMBER) && (
                        <TextField
                          type={getLowerCase(input.type)}
                          placeholder={input.value}
                          size="small"
                          fullWidth
                          variant="outlined"
                          required={input.required}
                          value={input.value}
                          onChange={(e) => {
                            const val = e.target.value;
                            handleInputChange(section_index, input_index, val);
                          }}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              </CustomColorLabelledOutline>
            </GridItem>
          ))}
        </GridContainer>
      </ModalComponent>
    </>
  );
};

export default ConfigSettingModal;
