import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  TextField
} from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import {
  GetAllSaleChannelLookupForSelection,
  GetStoresForSelection,
  UpdateSaleChannelConfig
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import UtilityClass from "../../../utilities/UtilityClass";
import { EnumOptions } from "../../../utilities/enum";
import { purple } from "../../../utilities/helpers/Helpers";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function UpdatePlatFormModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({});
  const [activateData, setActivateData] = useState();
  let { open, setOpen, carrierId, getAll, data } = props;
  const [allPlatformForSelection, setAllPlatformForSelection] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [inputFields, setInputFields] = useState({});
  const [store, setStore] = useState({
    storeId: 0,
    storeName: "Select Please",
  });
  const [selectedSaleChannel, setSelectedSaleChannel] = useState({
    saleChannelLookupId: 0,
    saleChannelName: "Select Please",
  });
  const [channelConfigData, setChannelConfigData] = useState("");
  const [saleChannelName, setSaleChannelName] = useState("");
  const [isAllowSaleChannel, setIsAllowSaleChannel] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  let getAllStores = async () => {
    let res = await GetStoresForSelection();
    if (res.data.result !== null) {
      setAllStores(res.data.result);
    }
  };
  let gtAllSaleChannelLookupForSelection = async () => {
    let res = await GetAllSaleChannelLookupForSelection();

    if (res.data.result !== null) {
      let allRecords = res.data.result;
      let obj = allRecords?.find(
        (x) => x.saleChannelLookupId == data.saleChannelLookupId
      );
      if (obj) {
        let updatedConfig = UtilityClass.updateDictionaryObjectValue(
          obj.inputRequiredConfig,
          data.config
        );

        obj.inputRequiredConfig = updatedConfig;
        let index = allRecords.findIndex(
          (x) => x.saleChannelLookupId == data.saleChannelLookupId
        );

        if (index !== -1) {
          allRecords[index] = obj;
        }
        setAllPlatformForSelection(allRecords);
      } else {
        setAllPlatformForSelection(allRecords);
      }
    }
  };

  useEffect(() => {
    getAllStores();
    gtAllSaleChannelLookupForSelection();
  }, []);
  const handleConnect = async () => {
    setIsLoading(true);
    console.log("activate data function", activateData);
    try {
      if (saleChannelName == "") {
        errorNotification(`Please enter sale channel name`);
        return false;
      }
      if (!store?.storeId || store?.storeId == 0) {
        errorNotification(`Please choose store`);
        return false;
      }
      if (
        !selectedSaleChannel?.saleChannelLookupId ||
        selectedSaleChannel?.saleChannelLookupId == 0
      ) {
        errorNotification(`Please choose store channel`);
        return false;
      }
      let isError = false;
      for (var key in activateData) {
        if (activateData[key] === "") {
          errorNotification(`The ${key}: Is required to proceed`);
          isError = true;
        }
      }
      //if any property is not filled then return with error
      if (isError) {
        return false;
      }
      activateData.saleChannelLookupId =
        selectedSaleChannel?.saleChannelLookupId;
      let body = {
        inputParameters: activateData,
        storeId: store?.storeId,
        saleChannelName: saleChannelName,
        IsAllowToDisplayInSaleChannel: isAllowSaleChannel,
        IsActive: isActiveChecked,
        saleChannelLookupId: selectedSaleChannel?.saleChannelLookupId,
        saleChannelConfigId: data?.saleChannelConfigId,
      };
      console.log("body", body);
      const response = await UpdateSaleChannelConfig(body);

      if (!response.data.isSuccess) {
        let jsonData = response.data.errors;
        UtilityClass.showErrorNotificationWithDictionary(jsonData);
      } else {
        successNotification(
          LanguageReducer?.languageType?.SUCCESSFULLY_CONNECT_TOAST
        );
        handleClose();
        getAll(true);
      }
    } catch (error) {
      console.error("Error to connect", error.response);
      errorNotification(LanguageReducer?.languageType?.UNABLE_TO_CONNECT_TOAST);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (channelConfigData) {
      const parsedData = JSON.parse(channelConfigData);
      setActivateData(parsedData);
      const { PlatformLookupId, ...fields } = parsedData;
      setInputFields(fields);
    }
  }, [channelConfigData]);
  useEffect(() => {
    if (data) {
      if (allPlatformForSelection && allPlatformForSelection.length > 0) {
        let defRecord = allPlatformForSelection.find(
          (x) => x?.saleChannelLookupId == data?.saleChannelLookupId
        );
        let updatedConfig = UtilityClass.updateDictionaryObjectValue(
          defRecord.inputRequiredConfig,
          data.config
        );

        setInputFields(updatedConfig);
        setChannelConfigData(updatedConfig);
      } else {
        setChannelConfigData();
        setInputFields();
      }
    }
  }, [allPlatformForSelection]);

  const handleFocus = (event) => event.target.select();

  useEffect(() => {
    if (data) {
      if (allStores && allStores.length > 0) {
        let defRecord = allStores.find((x) => x?.storeId == data?.storeId);
        setStore(defRecord);
        setIsActiveChecked(data?.active);
        setIsDeleted(!data?.active);
        setSaleChannelName(data?.saleChannelName);
        // debugger
        setIsAllowSaleChannel(data?.isAllowToDisplayInSaleChannel);
      }
    }
  }, [allStores]);
  useEffect(() => {
    if (data) {
      if (allPlatformForSelection && allPlatformForSelection.length > 0) {
        let defRecord = allPlatformForSelection.find(
          (x) => x?.saleChannelLookupId == data?.saleChannelLookupId
        );
        setSelectedSaleChannel(defRecord);
      }
    }
  }, [allPlatformForSelection]);
  const handelAction = (isActiveClicked) => {
    if (isActiveClicked) {
      setIsDeleted(false);
      //  handleActive();
    } else {
      setIsDeleted(true);

      //  handleDelete();
    }
  };
  const handleActiveInActive = (e) => {
    handelAction(!isActiveChecked);
    setIsActiveChecked(!isActiveChecked);
  };

  return (
    <ModalComponent
      open={open}
      onClose={handleClose}
      maxWidth="md"
      title={"Update Sale Channel"}
      actionBtn={
        <ModalButtonComponent
          title={"Update Sale Channel"}
          bg={purple}
          onClick={(e) => handleConnect()}
          loading={isLoading}
        />
      }
    >
      <Grid container spacing={2} md={12} sm={12}>
        <Grid item md={12} sm={12}>
          <InputLabel sx={styleSheet.inputLabel}>Channel Name</InputLabel>
          <TextField
            disabled={!isActiveChecked}
            placeholder={"Channel Name"}
            size="small"
            fullWidth
            variant="outlined"
            value={saleChannelName}
            onChange={(e) => setSaleChannelName(e.target.value)}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          {" "}
          <InputLabel sx={styleSheet.inputLabel}>Select Store</InputLabel>
          <SelectComponent
            disabled
            name="store"
            options={allStores}
            value={store}
            optionLabel={EnumOptions.STORE.LABEL}
            optionValue={EnumOptions.STORE.VALUE}
            onChange={(e, newValue) => {
              const resolvedId = newValue ? newValue : null;
              setStore(resolvedId);
            }}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          <InputLabel sx={styleSheet.inputLabel}>
            Select Sale Channel
          </InputLabel>
          <SelectComponent
            disabled
            name="platform"
            size="md"
            options={allPlatformForSelection}
            value={selectedSaleChannel}
            optionLabel={EnumOptions.SALE_CHANNEL.LABEL}
            optionValue={EnumOptions.SALE_CHANNEL.VALUE}
            onChange={(e, val) => {
              setSelectedSaleChannel(val);
              !val && setChannelConfigData("");
            }}
          />
        </Grid>
        {inputFields && Object.keys(inputFields).length > 0 ? (
          <>
            {Object.entries(inputFields).map(
              ([key, value]) =>
                key !== "PlatformLookupId" && (
                  <Grid key={key} item md={12} sm={12}>
                    <InputLabel sx={styleSheet.inputLabel}>{key}</InputLabel>
                    <TextField
                      disabled={!isActiveChecked}
                      onFocus={handleFocus}
                      placeholder={value}
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={(activateData && activateData[key]) || ""}
                      onChange={(e) =>
                        setActivateData({
                          ...activateData,
                          [key]: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )
            )}
          </>
        ) : null}
      </Grid>
      <Grid item md={12} sm={12} sx={{ textAlign: "right" }}>
        {" "}
        <FormControlLabel
          // sx={{ margin: "3px 0px" }}
          control={
            <Checkbox
              disabled={!isActiveChecked}
              sx={{
                color: "#563AD5",
                "&.Mui-checked": {
                  color: "#563AD5",
                },
              }}
              checked={isAllowSaleChannel}
              onChange={(e) => setIsAllowSaleChannel(e.target.checked)}
              edge="start"
              disableRipple
              defaultChecked
            />
          }
          label={"Allow to display in Sale Channel"}
        />
        <FormControlLabel
          control={
            <Switch
              checked={isActiveChecked}
              defaultChecked={isActiveChecked}
              onClick={() => {
                handleActiveInActive();
              }}
            />
          }
          label={isActiveChecked ? "Active" : "In Active"}
        />
      </Grid>
    </ModalComponent>
  );
}
export default UpdatePlatFormModal;
