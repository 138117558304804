export const createCarrierReturnEnglish = {
  CARRIER_RETURN_CREATE_CARRIER_RETURNS: "Create Carrier Returns",
  CARRIER_RETURN_CREATE_CARRIER_RETURN_S: "Create Carrier Return(s)",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_CARRIER: "Carrier",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SELECT_PLEASE: "Select Please",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FIND_BY_FILE: "Find by File",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FIND_BY_NUMBER: "Find by Number",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_IMPORT: "Import",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SAMPLE_DOWNLOAD: "Sample Download",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_NO: "Order No",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_TRACKING_NO: "Tracking No",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_STORE_INFO: "Store Info",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_DROP_OF_ADDRESS: "Drop of Address",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_PAYMENT_STATUS: "Payment Status",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_TRACKING_STATUS: "Tracking Status",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_TYPE: "Order Type",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ITEM_COUNT: "Item Count",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_DATE: "Order Date",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_AMOUNT: "Amount",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ACTION: "Action",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_1:
    "Fastline Express - Fastline Express 35",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_CREATE_RETURN_REPORT:
    "Create Return Report",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_2:
    "Fastline Express - check35998",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_3:
    "Fastline Express - Fast Line",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SELECT_OPTION:
    "Please select the option",
};
export const createCarrierReturnArabic = {
  CARRIER_RETURN_CREATE_CARRIER_RETURNS: "Create Carrier Returns",
  CARRIER_RETURN_CREATE_CARRIER_RETURN_S: "Create Carrier Return(s)",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_CARRIER: "الناقل",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SELECT_PLEASE: "يرجى التحديد",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FIND_BY_FILE: "البحث بواسطة الملف",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FIND_BY_NUMBER: "البحث بواسطة الرقم",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_IMPORT: "استيراد",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SAMPLE_DOWNLOAD: "تحميل عينة",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_NO: "رقم الطلب",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_STORE_INFO: "معلومات المتجر",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_DROP_OF_ADDRESS: "عنوان التسليم",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_PAYMENT_STATUS: "حالة الدفع",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_TRACKING_STATUS: "حالة التتبع",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_TYPE: "نوع الطلب",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ITEM_COUNT: "عدد العناصر",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_DATE: "تاريخ الطلب",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_AMOUNT: "المبلغ",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_TRACKING_NO: "تتبع أي",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ACTION: "فعل",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_1:
    "Fastline Express - Fastline Express 35",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_CREATE_RETURN_REPORT:
    "إنشاء تقرير الإرجاع",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_2:
    "Fastline Express - check35998",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_3:
    "Fastline Express - Fast Line",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SELECT_OPTION: "يرجى اختيار الخيار",
};
export const createCarrierReturnChines = {
  CARRIER_RETURN_CREATE_CARRIER_RETURNS: "Create Carrier Returns",
  CARRIER_RETURN_CREATE_CARRIER_RETURN_S: "Create Carrier Return(s)",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_CARRIER: "承运人",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SELECT_PLEASE: "请选择",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FIND_BY_FILE: "按文件查找",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FIND_BY_NUMBER: "按编号查找",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_IMPORT: "导入",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SAMPLE_DOWNLOAD: "下载示例",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_NO: "订单号",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_STORE_INFO: "店铺信息",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_DROP_OF_ADDRESS: "投递地址",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_PAYMENT_STATUS: "付款状态",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_TRACKING_STATUS: "跟踪状态",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_TYPE: "订单类型",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ITEM_COUNT: "商品数量",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ORDER_DATE: "订单日期",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_AMOUNT: "金额",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_TRACKING_NO: "追踪号码",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_ACTION: "行动",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_1:
    "Fastline Express - Fastline Express 35",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_CREATE_RETURN_REPORT: "创建退货报告",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_2:
    "Fastline Express - check35998",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_FASTLINE_EXPRESS_3:
    "Fastline Express - Fast Line",
  CARRIER_RETURN_CREATE_CARRIER_RETURNS_SELECT_OPTION: "请选择选项",
};
