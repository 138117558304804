export const analyticsEnglish = {
  ANALYTICS_TEXT: "Analytics",
  ANALYTICS_WELCOME_BACK: "welcome Back",
  ANALYTICS_LOAD_ORIGINAL: "Load Original",
  ANALYTICS_LOAD_SAMPLE: "Load Sample",
  ANALYTICS_FILTER: "Filter",
  ANALYTICS_ORDERS_ACTIVITY: "Orders Activity",
  ANALYTICS_FINANCE: "Finance",
  ANALYTICS_TOTAL: "Total",
  ANALYTICS_REGULAR: "Regular",
  ANALYTICS_FULLFILLABLE: "Fulfillable",
  ANALYTICS_DELIVERED: "Delivered",
  ANALYTICS_TO_BE_SHIPPED: "To Be Shipped",
  ANALYTICS_To_BE_PACKED: "To Be Packed",
  ANALYTICS_COD: "COD",
  ANALYTICS_PAID: "Paid",
  ANALYTICS_STOCK_VALUE: "Stock Value",
  ANALYTICS_PURCHASE_VALUE: "Purchase Value",
  ANALYTICS_CARRIERS_STATS: "Carriers Stats",
  ANALYTICS_COD_PENDING: "COD Pending",
  ANALYTICS_COD_SETTLED: "COD Settled",
  ANALYTICS_DELIVERY_RATIO: "Delivery Ratio",
  ANALYTICS_DATE: "Date",
  ANALYTICS_CARRIERS_ACTIVITY: "Carriers Activity",
  ANALYTICS_ACTIVE: "Active",
  ANALYTICS_IN_PROGRESS: "In Progress",
  ANALYTICS_COMPLETED: "Completed",
  ANALYTICS_SALES_ACTIVITY: "Sales Activity",
  ANALYTICS_STORES_CHANNELS: "Stores/Channels",
  ANALYTICS_TOTAL_PRODUCTS: "Total Products",
  ANALYTICS_TOTAL_PRODUCTS_SKUS: "Total Products SKU's",
  ANALYTICS_ACTIVE_CARRIER_PARTNERS: "Active Carrier Partners",
  ANALYTICS_TOP_PRODUCTS: "Top Products",
  ANALYTICS_NAME: "Name",
  ANALYTICS_SKU: "SKU",
  ANALYTICS_QUANTITY: "Quantity",
  ANALYTICS_REVENUE: "Revenue",
};
export const analyticsArabic = {
  ANALYTICS_TEXT: "تحليلات",
  ANALYTICS_WELCOME_BACK: "مرحبًا بعودتك",
  ANALYTICS_LOAD_ORIGINAL: "تحميل الأصل",
  ANALYTICS_LOAD_SAMPLE: "تحميل العينة",
  ANALYTICS_FILTER: "فلتر",
  ANALYTICS_ORDERS_ACTIVITY: "نشاط الطلبات",
  ANALYTICS_FINANCE: "المالية",
  ANALYTICS_TOTAL: "الإجمالي",
  ANALYTICS_REGULAR: "منتظم",
  ANALYTICS_FULLFILLABLE: "قابل للتنفيذ",
  ANALYTICS_DELIVERED: "تم التسليم",
  ANALYTICS_TO_BE_SHIPPED: "سيتم الشحن",
  ANALYTICS_To_BE_PACKED: "سيتم التعبئة",
  ANALYTICS_COD: "الدفع عند الاستلام",
  ANALYTICS_PAID: "مدفوع",
  ANALYTICS_STOCK_VALUE: "قيمة المخزون",
  ANALYTICS_PURCHASE_VALUE: "قيمة الشراء",
  ANALYTICS_CARRIERS_STATS: "إحصائيات الناقلين",
  ANALYTICS_DELIVERY_RATIO: "نسبة التسليم",
  ANALYTICS_DATE: "تاريخ",
  ANALYTICS_CARRIERS_ACTIVITY: "نشاط الناقلين",
  ANALYTICS_ACTIVE: "نشط",
  ANALYTICS_IN_PROGRESS: "قيد التنفيذ",
  ANALYTICS_COMPLETED: "مكتمل",
  ANALYTICS_SALES_ACTIVITY: "نشاط المبيعات",
  ANALYTICS_STORES_CHANNELS: "المتاجر/القنوات",
  ANALYTICS_TOTAL_PRODUCTS: "إجمالي المنتجات",
  ANALYTICS_TOTAL_PRODUCTS_SKUS: "إجمالي عدد وحدات SKU",
  ANALYTICS_ACTIVE_CARRIER_PARTNERS: "الشركاء الناقلون النشطون",
  ANALYTICS_TOP_PRODUCTS: "أفضل المنتجات",
  ANALYTICS_NAME: "الاسم",
  ANALYTICS_SKU: "س كيو يو",
  ANALYTICS_QUANTITY: "الكمية",
  ANALYTICS_REVENUE: "الإيرادات",
};
export const analyticsChinese = {
  ANALYTICS_TEXT: "Analytics",
  ANALYTICS_WELCOME_BACK: "欢迎回来",
  ANALYTICS_LOAD_ORIGINAL: "加载原始数据",
  ANALYTICS_LOAD_SAMPLE: "加载样本",
  ANALYTICS_FILTER: "筛选",
  ANALYTICS_ORDERS_ACTIVITY: "订单活动",
  ANALYTICS_FINANCE: "财务",
  ANALYTICS_TOTAL: "总计",
  ANALYTICS_REGULAR: "常规",
  ANALYTICS_FULLFILLABLE: "可履行",
  ANALYTICS_DELIVERED: "已交付",
  ANALYTICS_TO_BE_SHIPPED: "待发货",
  ANALYTICS_To_BE_PACKED: "待打包",
  ANALYTICS_COD: "货到付款",
  ANALYTICS_PAID: "已支付",
  ANALYTICS_STOCK_VALUE: "库存价值",
  ANALYTICS_PURCHASE_VALUE: "采购价值",
  ANALYTICS_CARRIERS_STATS: "运输公司统计",
  ANALYTICS_DELIVERY_RATIO: "交付比率",
  ANALYTICS_DATE: "日期",
  ANALYTICS_CARRIERS_ACTIVITY: "运输公司活动",
  ANALYTICS_ACTIVE: "活跃的",
  ANALYTICS_IN_PROGRESS: "进行中",
  ANALYTICS_COMPLETED: "已完成",
  ANALYTICS_SALES_ACTIVITY: "销售活动",
  ANALYTICS_STORES_CHANNELS: "店铺/渠道",
  ANALYTICS_TOTAL_PRODUCTS: "总产品数",
  ANALYTICS_TOTAL_PRODUCTS_SKUS: "总产品SKU数",
  ANALYTICS_ACTIVE_CARRIER_PARTNERS: "活跃运输合作伙伴",
  ANALYTICS_TOP_PRODUCTS: "畅销产品",
  ANALYTICS_NAME: "名称",
  ANALYTICS_SKU: "SKU",
  ANALYTICS_QUANTITY: "数量",
  ANALYTICS_REVENUE: "收入",
};
