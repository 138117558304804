import { useEffect, useState } from "react";
import {
  GetAllActivedCarrier,
  GetAllCarrierTrackingStatusForSelection,
  GetAllCitiesByRegionIds,
  GetAllFullFillmentStatusLookup,
  GetAllOrderTypeLookup,
  GetAllPaymentMethodLookup,
  GetAllPaymentStatusLookup,
  GetAllRegionbyCountryIds,
  GetAllSaleChannelsByStoreId,
  GetAllSalePersons,
  GetAllStationLookup,
  GetStoresForSelection,
} from "../../api/AxiosInterceptors";
import { fetchMethod } from "./Helpers";
export const useGetAllCarrierStatus = () => {
  const [allCarrierStatus, setAllCarrierStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCarrierStatus, setSelectedCarrierStatus] = useState();
  const [carrierStatusId, setCarrierStatusId] = useState();
  // handleGetAllCities
  const handleAllCarrierTrackingStatusForSelection = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() =>
      GetAllCarrierTrackingStatusForSelection()
    );
    if (response) {
      setAllCarrierStatus(response.result);
    }
    setLoading(false);
  };
  const reset = () => {
    if (allCarrierStatus && allCarrierStatus.length > 0) {
      setSelectedCarrierStatus(allCarrierStatus[0]);
    }
  };
  useEffect(() => {
    reset();
  }, [allCarrierStatus]);
  useEffect(() => {
    if (selectedCarrierStatus) {
      setCarrierStatusId(selectedCarrierStatus?.carrierTrackingStatusId);
    }
  }, [selectedCarrierStatus]);

  useEffect(() => {
    handleAllCarrierTrackingStatusForSelection();
  }, []);
  return {
    loading,
    allCarrierStatus,
    selectedCarrierStatus,
    setSelectedCarrierStatus,
    carrierStatusId,
    handleResetCarrier: reset,
  };
};
export const handleGetAllSaleChannelsByStoreId = async (storeId) => {
  let data = [];
  const { response } = await fetchMethod(() =>
    GetAllSaleChannelsByStoreId(storeId)
  );
  if (response) {
    data = response.result;
  }
  return data;
};

export const useGetAllStationLookup = () => {
  const [allProductStations, setAllProductStations] = useState([]);
  const [loading, setLoading] = useState(false);
  // handleGetAllCities
  const handleAllGetAllStationLookup = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() => GetAllStationLookup());
    if (response) {
      setAllProductStations(response.result);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleAllGetAllStationLookup();
  }, []);
  return { loading, allProductStations };
};
export const useGetAllOrderType = () => {
  const [allOrderType, setAllOrderType] = useState([]);
  const [loading, setLoading] = useState(false);
  // handleGetAllCities
  const handleAllGetAllOrderType = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() => GetAllOrderTypeLookup());
    if (response) {
      setAllOrderType(response.result);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleAllGetAllOrderType();
  }, []);
  return { loading, allOrderType };
};
export const useGetAllActivedCarrier = () => {
  const [allActiveCarriers, setAllActiveCarriers] = useState([]);
  const [loading, setLoading] = useState(false);
  // handleGetAllCities
  const handleGetAllActivedCarrier = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() => GetAllActivedCarrier());
    if (response) {
      setAllActiveCarriers(response.result);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleGetAllActivedCarrier();
  }, []);
  return { loading, allActiveCarriers };
};
export const useGetAllFullFillmentStatus = () => {
  const [allAllFullFillmentStatus, setAllAllFullFillmentStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  // handleGetAllCities
  const handleGetAllFullFillmentStatus = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() =>
      GetAllFullFillmentStatusLookup()
    );
    if (response) {
      setAllAllFullFillmentStatus(response.result);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleGetAllFullFillmentStatus();
  }, []);
  return { loading, allAllFullFillmentStatus };
};
export const useGetAllPaymentStatus = () => {
  const [allPaymentStatus, setAllPaymentStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  // handleGetAllCities
  const handleGetAllPaymentStatus = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() => GetAllPaymentStatusLookup());
    if (response) {
      setAllPaymentStatus(response.result);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleGetAllPaymentStatus();
  }, []);
  return { loading, allPaymentStatus };
};
export const useAllPaymentMethod = () => {
  const [allPaymentMethod, setAllPaymentMethod] = useState([]);
  const [loading, setLoading] = useState(false);
  // handleGetAllCities
  const handleAllPaymentMethod = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() => GetAllPaymentMethodLookup());
    if (response) {
      setAllPaymentMethod(response.result);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleAllPaymentMethod();
  }, []);
  return { loading, allPaymentMethod };
};
export const useGetAllStores = () => {
  const [allStores, setAllStores] = useState([]);
  const [loading, setLoading] = useState(false);

  const [storeId, setStoreId] = useState();

  const [selectedStore, setSelctedStore] = useState();

  // handleGetAllCities
  const handleGetAllStores = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() => GetStoresForSelection());
    if (response) {
      setAllStores(response.result);
    }
    setLoading(false);
  };
  const resetSelctedStore = () => {
    if (allStores && allStores.length > 0) {
      setSelctedStore(allStores[0]);
    }
  };
  useEffect(() => {
    handleGetAllStores();
  }, []);
  useEffect(() => {
    if (selectedStore) {
      setStoreId(selectedStore.storeId);
    }
  }, [selectedStore]);
  useEffect(() => {
    if (allStores && allStores.length > 0) {
      resetSelctedStore();
    }
  }, [allStores]);
  return {
    loading,
    allStores,
    selectedStore,
    setSelctedStore,
    storeId,
    resetSelctedStore,
  };
};
export const useAllStations = () => {
  const [allStations, setAllStations] = useState([]);
  const [loading, setLoading] = useState(false);
  // handleGetAllCities
  const handleAllStations = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() => GetAllStationLookup());
    if (response) {
      setAllStations(response.result);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleAllStations();
  }, []);
  return { loading, allStations };
};
//#region all region by country ids
export const useGetAllRegionbyCountryIds = () => {
  const [allRegions, setAllRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);

  const handleGetAllRegionsByIds = async (contryIds) => {
    setLoading(true);
    const data = await handleGetAllRegionbyCountryIds(contryIds);
    if (data) {
      setAllRegions(data);
    }
    setLoading(false);
  };
  return {
    loading,
    allRegions,
    handleGetAllRegionsByIds,
    selectedRegions,
    setSelectedRegions,
    setAllRegions,
  };
};

export const handleGetAllRegionbyCountryIds = async (selectedCountry) => {
  let data = [];
  const { response } = await fetchMethod(() =>
    GetAllRegionbyCountryIds(selectedCountry)
  );
  if (response) {
    data = response.result;
  }
  return data;
};
//#endregion
//#region get all city by ids
export const useGetAllCitiesByRegionIds = () => {
  const [allCities, setAllCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  // handleGetAllCities
  const handleGetAllCitiesByIds = async (regionIds) => {
    setLoading(true);
    const data = await handleGetAllCitiesByRegionIds(regionIds);
    if (data) {
      setAllCities(data);
    }
    setLoading(false);
  };
  return {
    loading,
    allCities,
    handleGetAllCitiesByIds,
    selectedCities,
    setSelectedCities,
    setAllCities,
  };
};

export const handleGetAllCitiesByRegionIds = async (selectedRegion) => {
  let data = [];
  const { response } = await fetchMethod(() =>
    GetAllCitiesByRegionIds(selectedRegion)
  );
  if (response) {
    data = response.result;
  }
  return data;
};
//#endregion
