export const shipmentEnglish = {
  SHIPMENT_S_TEXT: "Shipment(s)",
  SHIPMENTS_TEXT: "Shipments",
  SHIPMENTS_ALL: "ALL",
  SHIPMENTS_NEW_TEST_BENZ: "NEW TEST BENZ",
  SHIPMENTS_COMPLETED: "COMPLETED",
  SHIPMENTS_TO_BE_DISPATCHED: "TO BE DISPATCHED",
  SHIPMENTS_TO_BE_DELIVERED: "TO BE DELIVERED",
  SHIPMENTS_TESTING2: "TESTING2",
  SHIPMENTS_TAB_SETTING: "Tab Setting",
  SHIPMENTS_ORDER_NO_REF_NO: "Order No. /Ref No",
  SHIPMENTS_CARRIER_NAME: "Carrier Name",
  SHIPMENTS_STORE_INFO: "Store Info",
  SHIPMENTS_DROP_OFF_ADDRESS: "Drop of Address",
  SHIPMENTS_TRACKING_STATUS: "Tracking Status",
  SHIPMENTS_ORDER_TYPE: "Order Type",
  SHIPMENTS_PAYMENT_STATUS: "Payment Status",
  SHIPMENTS_REMARKS: "Remarks",
  SHIPMENTS_ACTION: "Action",
  SHIPMENTS_EXCEL_EXPORT: "Excel Export",
  SHIPMENTS_PRINT_LABELS: "Print Labels",
  SHIPMENTS_PRINT_CARRIER_LABELS: "Print Carrier Labels",
  SHIPMENTS_REFRESH_TRACKING_STATUS: "Refresh Tracking Status",
  SHIPMENTS_UNASSIGN_FROM_CARRIER: "Unassign From Carrier",
  SHIPMENTS_START_DATE: "Start Date",
  SHIPMENTS_END_DATE: "End Date",
  SHIPMENTS_STORE: "Store",
  SHIPMENTS_CARRIER: "Carrier",
  SHIPMENTS_ORDER_TYPE: "Order Type",
  SHIPMENTS_FULFILLMENT_STATUS: "Fullfillment Status",
  SHIPMENTS_PAYMENT_STATUS: "Payment Status",
  SHIPMENTS_PAYMENT_METHOD: "Payment Method",
  SHIPMENTS_STATION: "Station",
  SHIPMENTS_SALES_PERSON: "Sales Person",
  SHIPMENTS_COUNTRY: "Country",
  SHIPMENTS_REGION_CITY: "Region/City",
  SHIPMENTS_AREA_NEIGHBOURHOOD: "Area/Neighbourhood",
  SHIPMENTS_CARRIER_TRACKING_STATUS: "Carrier Tracking Status",
  SHIPMENTS_SELECT_PLEASE: "Select Please",
  SHIPMENTS_CLEAR_FILTER: "Clear Filter",
  SHIPMENTS_INCLUDE: "Include",
};
export const shipmentArabic = {
  SHIPMENT_S_TEXT: "Shipment(s)",
  SHIPMENTS_TEXT: "Shipments",
  SHIPMENTS_ALL: "الكل",
  SHIPMENTS_NEW_TEST_BENZ: "NEW TEST BENZ",
  SHIPMENTS_COMPLETED: "مكتمل",
  SHIPMENTS_TO_BE_DISPATCHED: "قيد الإرسال",
  SHIPMENTS_TO_BE_DELIVERED: "قيد التوصيل",
  SHIPMENTS_TESTING2: "TESTING2",
  SHIPMENTS_TAB_SETTING: "إعدادات اللسان",
  SHIPMENTS_ORDER_NO_REF_NO: "رقم الطلب / الرقم المرجعي",
  SHIPMENTS_CARRIER_NAME: "اسم الناقل",
  SHIPMENTS_STORE_INFO: "معلومات المتجر",
  SHIPMENTS_DROP_OFF_ADDRESS: "عنوان التسليم",
  SHIPMENTS_TRACKING_STATUS: "حالة التتبع",
  SHIPMENTS_ORDER_TYPE: "نوع الطلب",
  SHIPMENTS_PAYMENT_STATUS: "حالة الدفع",
  SHIPMENTS_REMARKS: "ملاحظات",
  SHIPMENTS_ACTION: "الإجراء",
  SHIPMENTS_EXCEL_EXPORT: "تصدير إكسل",
  SHIPMENTS_PRINT_LABELS: "طباعة الملصقات",
  SHIPMENTS_PRINT_CARRIER_LABELS: "طباعة ملصقات الناقل",
  SHIPMENTS_REFRESH_TRACKING_STATUS: "تحديث حالة التتبع",
  SHIPMENTS_UNASSIGN_FROM_CARRIER: "إلغاء التعيين من الناقل",
  SHIPMENTS_START_DATE: "من التاريخ",
  SHIPMENTS_END_DATE: "إلى التاريخ",
  SHIPMENTS_STORE: "المتجر",
  SHIPMENTS_CARRIER: "الناقل",
  SHIPMENTS_ORDER_TYPE: "نوع الطلب",
  SHIPMENTS_FULFILLMENT_STATUS: "حالة التنفيذ",
  SHIPMENTS_PAYMENT_STATUS: "حالة الدفع",
  SHIPMENTS_PAYMENT_METHOD: "طريقة الدفع",
  SHIPMENTS_STATION: "المحطة",
  SHIPMENTS_SALES_PERSON: "مندوب المبيعات",
  SHIPMENTS_COUNTRY: "الدولة",
  SHIPMENTS_REGION_CITY: "المنطقة/المدينة",
  SHIPMENTS_AREA_NEIGHBOURHOOD: "المنطقة/الحي",
  SHIPMENTS_CARRIER_TRACKING_STATUS: "حالة تتبع الناقل",
  SHIPMENTS_SELECT_PLEASE: "يرجى التحديد",
  SHIPMENTS_CLEAR_FILTER: "مسح الفلتر",
  SHIPMENTS_INCLUDE: "شمل",
};
export const shipmentChinese = {
  SHIPMENT_S_TEXT: "Shipment(s)",
  SHIPMENTS_TEXT: "Shipments",
  SHIPMENTS_ALL: "所有",
  SHIPMENTS_NEW_TEST_BENZ: "NEW TEST BENZ",
  SHIPMENTS_COMPLETED: "已完成",
  SHIPMENTS_TO_BE_DISPATCHED: "待发货",
  SHIPMENTS_TO_BE_DELIVERED: "待送达",
  SHIPMENTS_TESTING2: "TESTING2",
  SHIPMENTS_TAB_SETTING: "选项设置",
  SHIPMENTS_ORDER_NO_REF_NO: "订单号/参考号",
  SHIPMENTS_CARRIER_NAME: "承运人名称",
  SHIPMENTS_STORE_INFO: "店铺信息",
  SHIPMENTS_DROP_OFF_ADDRESS: "投递地址",
  SHIPMENTS_TRACKING_STATUS: "跟踪状态",
  SHIPMENTS_ORDER_TYPE: "订单类型",
  SHIPMENTS_PAYMENT_STATUS: "付款状态",
  SHIPMENTS_REMARKS: "备注",
  SHIPMENTS_ACTION: "操作",
  SHIPMENTS_EXCEL_EXPORT: "导出Excel",
  SHIPMENTS_PRINT_LABELS: "打印标签",
  SHIPMENTS_PRINT_CARRIER_LABELS: "打印承运标签",
  SHIPMENTS_REFRESH_TRACKING_STATUS: "刷新跟踪状态",
  SHIPMENTS_UNASSIGN_FROM_CARRIER: "取消指定承运人",
  SHIPMENTS_START_DATE: "开始日期",
  SHIPMENTS_END_DATE: "结束日期",
  SHIPMENTS_STORE: "店铺",
  SHIPMENTS_CARRIER: "承运人",
  SHIPMENTS_ORDER_TYPE: "订单类型",
  SHIPMENTS_FULFILLMENT_STATUS: "履行状态",
  SHIPMENTS_PAYMENT_STATUS: "付款状态",
  SHIPMENTS_PAYMENT_METHOD: "付款方式",
  SHIPMENTS_STATION: "站点",
  SHIPMENTS_SALES_PERSON: "销售人员",
  SHIPMENTS_COUNTRY: "国家",
  SHIPMENTS_REGION_CITY: "地区/城市",
  SHIPMENTS_AREA_NEIGHBOURHOOD: "区域/社区",
  SHIPMENTS_CARRIER_TRACKING_STATUS: "承运人跟踪状态",
  SHIPMENTS_SELECT_PLEASE: "请选择",
  SHIPMENTS_CLEAR_FILTER: "清除筛选",
  SHIPMENTS_INCLUDE: "包括",
};
