export const DriverStatusesEnglish = {
  SETTING_DRIVER_STATUSES_TEXT: "Driver Statuses",
  SETTING_DRIVER_STATUSES_TEXT_S: "Driver Statuse(s)",
  SETTING_DRIVER_STATUSES_ADD_DRIVER_STATUS: "Add Driver Status",
  SETTING_DRIVER_STATUSES_STATUS_NAME: "Status Name",
  SETTING_DRIVER_STATUSES_STATUS_NAME_AR: "Status Name Ar",
  SETTING_DRIVER_STATUSES_Action: "Action",
};
export const DriverStatusesArabic = {
  SETTING_DRIVER_STATUSES_TEXT: "Driver Statuses",
  SETTING_DRIVER_STATUSES_TEXT_S: "Driver Statuse(s)",
  SETTING_DRIVER_STATUSES_ADD_DRIVER_STATUS: "إضافة حالة السائق",
  SETTING_DRIVER_STATUSES_STATUS_NAME: "اسم الحالة",
  SETTING_DRIVER_STATUSES_STATUS_NAME_AR: "اسم الحالة بالعربية",
};
export const DriverStatusesChines = {
  SETTING_DRIVER_STATUSES_TEXT: "添加驾驶员状态",
  SETTING_DRIVER_STATUSES_TEXT_S: "添加驾驶员状态",
  SETTING_DRIVER_STATUSES_ADD_DRIVER_STATUS: "状态名称",
  SETTING_DRIVER_STATUSES_STATUS_NAME: "Status Name",
  SETTING_DRIVER_STATUSES_STATUS_NAME_AR: "状态名称 Ar",
};
