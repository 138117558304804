export const metaFieldEnglish = {
  SETTING_META_FIELD_TEXT: "Meta Fields",
  SETTING_META_FIELD_META_FIELD_DEFINITION: "Metafield definition",
  SETTING_META_FIELD_ORDER: "Order",
  SETTING_META_FIELD_SHIPMENTS: "Shipments",
  SETTING_META_FIELD_SAVE: "Save",
  SETTING_META_FIELD_ORDER_META_FIELD_DEFINITION: "Order metafield definition",
  SETTING_META_FIELD_ADD_META_FIELD: "+Add Metafield",
  SETTING_META_FIELD_ADD_CUSTOM_DATA_ORDER:
    "Add a custom piece of data to a specific part of your store (Order)",
  SETTING_META_FIELD_SHIPMENT_META_FIELD_DEFINITION:
    "Shipment metafield definition",
  SETTING_META_FIELD_ADD_CUSTOM_DATA_SHIPMENT:
    "Add a custom piece of data to a specific part of your store (Shipment)",
  SETTING_META_FIELD_ADD_CUSTOM_DATA:
    "Add a custom piece of data to a specific part of your store",
};
export const metaFieldArabic = {
  SETTING_META_FIELD_TEXT: "Meta Fields",
  SETTING_META_FIELD_META_FIELD_DEFINITION: "تعريف الحقل الفرعي",
  SETTING_META_FIELD_ORDER: "الطلب",
  SETTING_META_FIELD_SHIPMENTS: "الشحنات",
  SETTING_META_FIELD_SAVE: "يحفظ",
  SETTING_META_FIELD_ORDER_META_FIELD_DEFINITION:
    "تعريف حقل البيانات الفرعية للطلب",
  SETTING_META_FIELD_ADD_META_FIELD: "+إضافة حقل فرعي",
  SETTING_META_FIELD_ADD_CUSTOM_DATA_ORDER:
    "إضافة قطعة بيانات مخصصة إلى جزء معين من متجرك (الطلب)",
  SETTING_META_FIELD_SHIPMENT_META_FIELD_DEFINITION:
    "تعريف حقل البيانات الفرعية للشحن",
  SETTING_META_FIELD_ADD_CUSTOM_DATA_SHIPMENT:
    "إضافة قطعة بيانات مخصصة إلى جزء معين من متجرك (الشحنات)",
  SETTING_META_FIELD_ADD_CUSTOM_DATA:
    "إضافة قطعة بيانات مخصصة إلى جزء معين من متجرك",
};
export const metaFieldChines = {
  SETTING_META_FIELD_TEXT: "Meta Fields",
  SETTING_META_FIELD_META_FIELD_DEFINITION: "元字段定义",
  SETTING_META_FIELD_ORDER: "订单",
  SETTING_META_FIELD_SHIPMENTS: "发货",
  SETTING_META_FIELD_SAVE: "节省",
  SETTING_META_FIELD_ORDER_META_FIELD_DEFINITION: "订单元字段定义",
  SETTING_META_FIELD_ADD_META_FIELD: "+添加元字段",
  SETTING_META_FIELD_ADD_CUSTOM_DATA_ORDER:
    "将自定义数据添加到商店的特定部分 (订单)",
  SETTING_META_FIELD_SHIPMENT_META_FIELD_DEFINITION: "发货元字段定义",
  SETTING_META_FIELD_ADD_CUSTOM_DATA_SHIPMENT:
    "将自定义数据添加到商店的特定部分 (发货)",
  SETTING_META_FIELD_ADD_CUSTOM_DATA: "将自定义数据添加到商店的特定部分",
};
