import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutline from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../.reUseableComponents/Buttons/ButtonComponent";
import ModalButtonComponent from "../../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ModalComponent from "../../../../.reUseableComponents/Modal/ModalComponent";
import SelectComponent from "../../../../.reUseableComponents/TextField/SelectComponent";
import {
  CheckUniqueProductStockSku,
  CheckUniqueProductStockSkus,
  CreateProduct,
  CreateProductCategory,
  GetAllProductCategoryLookup,
  GetAllProductOptionLookup,
  GetUniqueAutogeneratedSku,
} from "../../../../api/AxiosInterceptors";
import {
  getAllStationLookupFunc,
  getAllStoresForSelectionFunc,
} from "../../../../apiCallingFunction";
import ImageIcon from "../../../../assets/images/uploadImage.png";
import { styleSheet } from "../../../../assets/styles/style";
import DragDropFile from "../../../../components/dragAndDrop/featureImage";
import UtilityClass from "../../../../utilities/UtilityClass";
import { EnumAllProductOption, EnumOptions } from "../../../../utilities/enum";
import {
  ActionButtonCustom,
  CustomColorLabelledOutline,
  GridContainer,
  GridItem,
  fetchMethod,
  placeholders,
  purple,
  useSetNumericInputEffect,
} from "../../../../utilities/helpers/Helpers";
import NotificationMessage from "../../../../utilities/helpers/NotificationMessage";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../../../utilities/toast";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function EnhancedTableToolbar(props) {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const {
    numSelected,
    handleOpenPrices,
    handleOpenQuantities,
    handleOpenSKU,
    handleCloseQuantities,
    handleCloseSKU,
    handleClosePrices,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > -1 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {LanguageReducer?.languageType?.SELECTED_TEXT}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {0} {LanguageReducer?.languageType?.SELECTED_TEXT}
        </Typography>
      )}

      {numSelected > -1 ? ( //always show edit button
        <div>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              background:
                "radial-gradient(100% 5102.04% at 100% 100%, rgb(86, 58, 213) 0%, rgb(86, 58, 213) 100%)",
              fontFamily: "'Lato Medium', 'Inter Medium', 'Arial' !important",
              textTransform: "capitalize  !important",
            }}
          >
            {LanguageReducer?.languageType?.EDIT_TEXT}
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleOpenPrices();
                handleCloseSKU();
                handleCloseQuantities();
                setAnchorEl(null);
              }}
              disableRipple
            >
              <EditIcon />
              {LanguageReducer?.languageType?.EDIT_PRICES_TEXT}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenSKU();
                handleClosePrices();
                handleCloseQuantities();
                setAnchorEl(null);
              }}
              disableRipple
            >
              <EditIcon />
              {LanguageReducer?.languageType?.EDIT_SKU_TEXT}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenQuantities();
                handleCloseSKU();
                handleClosePrices();
                setAnchorEl(null);
              }}
              disableRipple
            >
              <EditIcon />
              {LanguageReducer?.languageType?.EDIT_QUNATITIES_TEXT}
            </MenuItem>
          </StyledMenu>
        </div>
      ) : null}
    </Toolbar>
  );
}
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Products(props) {
  let navigate = useNavigate();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [stores, setStores] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSKU, setOpenSKU] = useState(false);
  const [openPrices, setOpenPrices] = useState(false);
  const [openQuantities, setOpenQuantities] = useState(false);
  let initInputFileds = [{ selectedOption: "", options: [] }];
  const [inputFields, setInputFields] = useState(initInputFileds);
  const [categoryName, setCategoryName] = useState("");
  const [stationValues, setStationValues] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [
    productStationsWithPleaseSelectOp,
    setProductStationsWithPleaseSelectOp,
  ] = useState([]);
  const [productStations, setProductStations] = useState([]);
  const [allProductOptions, setAllProductOptions] = useState([]);
  const [optionsCheckbox, setOptionsCheckbox] = useState(false);
  const [trackInventoryCheckbox, setTrackInventoryCheckbox] = useState(true);
  const [autoGenerateSku, setAutoGenerateSku] = useState(true);
  const [selected, setSelected] = useState([]);
  const [productFile, setProductFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLowerPrice, setIsLowerPrice] = useState(false);
  const [optionValuePlaceHolder, setOptionValuePlaceHolder] = useState();

  const [combinations, setCombinations] = useState([]);
  const [tempCombinations, setTempCombinations] = useState([]);
  const [tempCombinationsWithAllStation, setTempCombinationsWithAllStation] =
    useState([]);
  const [updateQtyShowRecord, setUpdateQtyShowRecord] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    watch,
    control,
    unregister,
  } = useForm({
    defaultValues: {
      productCategoryId: productCategories && productCategories[1],
    },
  });
  useWatch({ name: "SKU", control });
  useWatch({ name: "store", control });
  useWatch({ name: "productCategoryId", control });

  const handleFocus = (event) => event.target.select();
  const handleKeyDown = (e, index) => {
    if (e.key === "ArrowLeft") {
      e.preventDefault();
      if (e.target.value && typeof e.target.select === "function") {
        e.target.select();
      }
    }
  };

  const [autocompleteInput, setAutocompleteInput] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const groupData = (data) => {
    // Create an object to store the grouped data
    const groupedData = {};

    // Iterate through the given data
    data?.forEach((item) => {
      // Destructure the item
      const {
        SKU,
        ORGSKU,
        quantities,
        lowQuantityLimit,
        stationIds,
        prices,
        variantOption,
        variants,
        SKUChecked,
        isAddedStationQty,
      } = item;
      // Check if SKU is already present in groupedData
      if (groupedData[SKU]) {
        // If SKU is present, update the quantities and lowQuantityLimit
        groupedData[SKU].quantities += quantities;
        groupedData[SKU].lowQuantityLimit += lowQuantityLimit;
        groupedData[SKU].prices = prices;
        groupedData[SKU].variantOption = variantOption;
        groupedData[SKU].SKUChecked = SKUChecked;
        groupedData[SKU].isAddedStationQty = isAddedStationQty;
        groupedData[SKU].ORGSKU = ORGSKU;
        if (variants) {
          groupedData[SKU].variants = variants;
        }

        // Check if the stationId is already present for the SKU
        if (!groupedData[SKU].stationIds.includes(stationIds)) {
          // If not, add the stationId to the list
          if (quantities > 0) {
            groupedData[SKU].stationIds.push(stationIds);
          }
        }
      } else {
        // If SKU is not present, initialize a new entry in groupedData
        let stationId = quantities > 0 ? [stationIds] : [];
        groupedData[SKU] = {
          quantities,
          lowQuantityLimit,
          stationIds: stationId,
          prices,
          variantOption,
          variants,
          SKUChecked,
          isAddedStationQty,
          ORGSKU: ORGSKU,
        };
      }
    });

    //get required object
    const result = Object.keys(groupedData).map((SKU) => ({
      SKU,
      ORGSKU: groupedData[SKU].ORGSKU,
      quantities: groupedData[SKU].quantities,
      lowQuantityLimit: groupedData[SKU].lowQuantityLimit,
      stationCount: groupedData[SKU].stationIds.length,
      prices: groupedData[SKU].prices,
      variantOption: groupedData[SKU].variantOption,
      variants: groupedData[SKU].variants,
      SKUChecked: groupedData[SKU].SKUChecked,
      isAddedStationQty: groupedData[SKU].isAddedStationQty,
    }));

    return result;
  };
  const getGroupedDataFromTempArray = () => {
    let targetedData = tempCombinations?.filter((x) => x.SKUChecked == true);
    var gdata = groupData(targetedData);
    return gdata;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClosePrices = () => {
    setOpenPrices(false);
  };
  const isValidaSkuCheckedInventory = () => {
    let isvalid = false;
    let gCombnination = groupedCombination.filter((x) => x.SKUChecked);
    if (gCombnination.length == 0) {
      console.log(groupedCombination.length);
      warningNotification(NotificationMessage.chooseOneRecord);
      return false;
    } else {
      isvalid = true;
    }
    return isvalid;
  };
  const handleOpenPrices = () => {
    if (!isValidaSkuCheckedInventory()) {
      return false;
    }
    let targetedData = getGroupedDataFromTempArray();
    setUpdateQtyShowRecord(targetedData);
    setOpenPrices(true);
  };

  const handleCloseSKU = () => {
    setOpenSKU(false);
  };

  const handleOpenSKU = () => {
    if (!isValidaSkuCheckedInventory()) {
      return false;
    }
    let targetedData = getGroupedDataFromTempArray();
    setUpdateQtyShowRecord(targetedData);
    setOpenSKU(true);
  };

  const handleCloseQuantities = () => {
    setOpenQuantities(false);
  };

  const handleOpenQuantities = () => {
    if (!isValidaSkuCheckedInventory()) {
      return false;
    }
    let targetedData = tempCombinations?.filter(
      (x) =>
        x.SKUChecked == true &&
        x.stationIds == qtySelectedStation?.productStationId
    );
    setUpdateQtyShowRecord(targetedData);
    setOpenQuantities(true);
  };
  const [isAllVariantSelected, setIsAllVariantSelected] = useState(false);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const updCombination = combinations.map((product, indx) => {
        return {
          ...product,
          SKUChecked: true,
        };
      });
      setCombinations(updCombination);
      var newSelected = groupData(updCombination);
      setGroupedCombination(newSelected);
      setSelected(newSelected);
      return;
    } else {
      const updCombination = combinations.map((product, indx) => {
        return {
          ...product,
          SKUChecked: false,
        };
      });
      setCombinations(updCombination);
      var newSelected = groupData(updCombination);
      setGroupedCombination(newSelected);
      setSelected([]);
    }
  };

  function updateSKUCheckedByUuid(array, Sku) {
    const updatedProducts = array?.map((product) => {
      if (product.SKU === Sku) {
        let check = product?.stationIds == 0 ? true : !product?.SKUChecked;
        return {
          ...product,
          SKUChecked: check,
        };
      }
      return product;
    });
    return updatedProducts;
  }

  const handleCombinationIsSkuCheckd = (event, name, item) => {
    let updatedCombinations = updateSKUCheckedByUuid(combinations, item?.SKU);
    setCombinations(updatedCombinations);

    //update grouped combination

    var groupedData = groupData(updatedCombinations);
    let allChecked = groupedData?.filter((x) => x.SKUChecked == true);
    setSelected(allChecked);
  };

  const handleClick = (event, name, item) => {
    handleCombinationIsSkuCheckd(event, name, item);

    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // setSelected(newSelected);
  };
  useEffect(() => {
    // combinations.forEach((item, i) => {
    //   handleCombinationIsSkuCheckdForAll(undefined, i, item);
    // });
  }, [isAllVariantSelected]);

  // useEffect(() => {
  //   var products = [
  //     {
  //       uuid: "a4ef5356",
  //       SKU: "RLVQ999181-0",
  //       prices: 0,
  //       quantities: 5,
  //       lowQuantityLimit: 3,
  //       variants: ["sm"],
  //       stationIds: 3,
  //       SKUChecked: true,
  //       variantOption: "sm",
  //       isAddedStationQty: true,
  //     },
  //     {
  //       uuid: "167fd6a7",
  //       SKU: "RLVQ999181-1",
  //       prices: 0,
  //       quantities: 0,
  //       lowQuantityLimit: 0,
  //       variants: ["lg"],
  //       stationIds: 3,
  //       SKUChecked: false,
  //       variantOption: "lg",
  //       isAddedStationQty: false,
  //     },
  //     {
  //       uuid: "35669504",
  //       SKU: "RLVQ999181-0",
  //       prices: 0,
  //       quantities: 0,
  //       lowQuantityLimit: 0,
  //       stationIds: 4,
  //       SKUChecked: true,
  //       variantOption: "sm",
  //       isAddedStationQty: false,
  //     },
  //     {
  //       uuid: "4157831d",
  //       SKU: "RLVQ999181-1",
  //       prices: 0,
  //       quantities: 0,
  //       lowQuantityLimit: 0,
  //       stationIds: 4,
  //       SKUChecked: false,
  //       variantOption: "lg",
  //       isAddedStationQty: false,
  //     },
  //     {
  //       uuid: "714e7632",
  //       SKU: "RLVQ999181-0",
  //       prices: 0,
  //       quantities: 3,
  //       lowQuantityLimit: 1,
  //       stationIds: 7,
  //       SKUChecked: true,
  //       variantOption: "sm",
  //       isAddedStationQty: true,
  //     },
  //     {
  //       uuid: "59d66d70",
  //       SKU: "RLVQ999181-1",
  //       prices: 0,
  //       quantities: 0,
  //       lowQuantityLimit: 0,
  //       stationIds: 7,
  //       SKUChecked: false,
  //       variantOption: "lg",
  //       isAddedStationQty: false,
  //     },
  //   ];
  //   var groupedData = groupData(products);

  //   const result = Object.keys(groupedData).map((SKU) => ({
  //     SKU,
  //     quantities: groupedData[SKU].quantities,
  //     lowQuantityLimit: groupedData[SKU].lowQuantityLimit,
  //     stationCount: groupedData[SKU].stationIds.length,
  //     prices: groupedData[SKU].prices,
  //     variantOption: groupedData[SKU].variantOption,
  //     variants: groupedData[SKU].variants,
  //     SKUChecked: groupedData[SKU].SKUChecked,
  //     isAddedStationQty: groupedData[SKU].isAddedStationQty,
  //   }));
  //   setGroupedCombination(result);
  // }, []);
  const [groupedCombination, setGroupedCombination] = useState([]);
  useEffect(() => {
    var groupedData = groupData(combinations);
    setGroupedCombination(groupedData);
  }, [combinations]);

  const isSelected = (name) => {
    return selected.indexOf(selected.find((x) => x?.SKU == name?.SKU)) !== -1;
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: `${LanguageReducer?.languageType?.SHOWING_TEXT} ${groupedCombination.length} ${LanguageReducer?.languageType?.VARIANTS_TEXT} `,
    },
    {},
  ];
  let initDefaultStation = {
    productStationId: 0,
    sname: "Please select station",
  };
  const [qtySelectedStation, setQtySelectedStation] = useState([]);

  useEffect(() => {
    if (productStations.length > 0) {
      setQtySelectedStation(productStations[0]);

      /// set values
      updateProductStationQty();
    }
  }, [productStations]);
  const updateProductStationQty = () => {
    //filled station value
    const updatedProductStations = productStations?.map((item) => {
      return {
        ...item,
        quantityAvailable: 0,
        lowQuantityLimit: 0,
      };
    });
    setStationValues(updatedProductStations);
  };
  function generateCombinations(optionsArray) {
    const combinations = [[]]; // Start with an empty combination

    optionsArray.forEach((optionsObj) => {
      const newCombinations = [];

      optionsObj.options.forEach((option) => {
        combinations.forEach((combination) => {
          newCombinations.push([...combination, option]);
        });
      });
      combinations.push(...newCombinations);
    });

    return combinations.slice(1); // Exclude the empty combination
  }
  const handleSetDefaultSkusWithStations = (filteredArray) => {
    let structureArray = [];
    const groupId = UtilityClass.randomId();
    productStations.forEach((element) => {
      for (let index = 0; index < filteredArray.length; index++) {
        let isSKUChecked =
          element.productStationId == 0 && index == 0 ? true : false;
        let obj = {
          uuid: UtilityClass.randomId(),
          groupId: groupId,
          SKU: getValues("SKU") + "-" + index,
          ORGSKU: getValues("SKU") + "-" + index,
          prices: getValues("price") ? getValues("price") : 0,
          quantities: 0,
          lowQuantityLimit: 0,
          variants: filteredArray[index],
          stationIds: element.productStationId,
          SKUChecked: isSKUChecked,
          variantOption: filteredArray[index]?.join("/"),
          isAddedStationQty: false,
        };
        structureArray.push(obj);
      }
    });
    setCombinations(structureArray);
  };
  const optionsCombinations = (optionsArray) => {
    const filteredOptionsArray = optionsArray.filter((optionArray) => {
      return optionArray.options.length !== 0;
    });
    const combinations = generateCombinations(optionsArray);
    const filteredArray = combinations.filter((combination) => {
      return combination.length == filteredOptionsArray.length;
    });
    // let structureArray = [];
    // for (let index = 0; index < filteredArray.length; index++) {
    //   structureArray[index] = {
    //     uuid: UtilityClass.randomId(),
    //     SKU: getValues("SKU") + "-" + index,
    //     prices: getValues("price") ? getValues("price") : 0,
    //     quantities: 0,
    //     lowQuantityLimit: 0,
    //     variants: filteredArray[index],
    //     stationIds: 0,
    //     SKUChecked: false,
    //     variantOption: filteredArray[index]?.join("/"),
    //     isAddedStationQty: false,
    //   };
    // }
    handleSetDefaultSkusWithStations(filteredArray);

    // setTempCombinations(structureArray);
    // setCombinations(structureArray);
  };
  useEffect(() => {
    let targetedData = combinations?.filter((x) => x.SKUChecked == true);
    setTempCombinations(targetedData);
    //for price and sku model without filter
  }, [combinations]);

  let getUniqueAutogeneratedSku = async () => {
    setValue("SKU", "loading...");
    let res = await GetUniqueAutogeneratedSku();
    if (res.data.isSuccess) {
      setValue("SKU", res.data.result.sku);
      unregister("SKU");
    } else {
      setValue("SKU", "");
    }
  };
  // useEffect(() => {
  //   if (autoGenerateSku) {
  //     getUniqueAutogeneratedSku();
  //   } else {
  //     setValue("SKU", "");
  //   }
  // }, [autoGenerateSku]);
  const handleAutoGenerate = () => {
    setAutoGenerateSku(!autoGenerateSku);
  };
  useEffect(() => {
    optionsCombinations(inputFields);
  }, [inputFields]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        selectedOption: "",
        options: [],
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handlePlaceHolderForOption = (value, index) => {
    console.log(value, index);

    if (value == EnumAllProductOption.Size) {
      setOptionValuePlaceHolder((prevStates) => ({
        ...prevStates,
        [index]: "Small",
      }));
    } else if (value == EnumAllProductOption.Company) {
      setOptionValuePlaceHolder((prevStates) => ({
        ...prevStates,
        [index]: "Shipra",
      }));
    } else if (value == EnumAllProductOption.Color) {
      setOptionValuePlaceHolder((prevStates) => ({
        ...prevStates,
        [index]: "Purple",
      }));
    } else if (value == EnumAllProductOption.Model) {
      setOptionValuePlaceHolder((prevStates) => ({
        ...prevStates,
        [index]: "2023",
      }));
    } else {
      setOptionValuePlaceHolder((prevStates) => ({
        ...prevStates,
        [index]: "Select option",
      }));
    }
  };
  const handleChange = (index, evnt) => {
    console.log("row index" + index);
    const { name, value } = evnt.target;
    handlePlaceHolderForOption(value, index);
    const list = [...inputFields];
    list[index]["selectedOption"] = value;
    setInputFields(list);
    let priceArr = [...combinations];
    priceArr[index] = {
      ...priceArr[index],
      optionId: value,
    };
    setCombinations(priceArr);
    setTempCombinations(priceArr);
  };
  let getAllStores = async () => {
    let data = await getAllStoresForSelectionFunc();
    if (data?.length > 0) {
      setStores(data);
    }
  };
  let getAllProductCategoryLookup = async () => {
    let res = await GetAllProductCategoryLookup();
    if (res.data.result !== null) setProductCategories(res.data.result);
  };
  useEffect(() => {
    if (categoryName != null && categoryName != "") {
      let currentObj = productCategories.find(
        (x) => x?.categoryName?.trim() == categoryName.trim()
      );
      setValue("productCategoryId", currentObj);
      setCategoryName("");
    }
  }, [productCategories]);

  let getAllStationLookup = async () => {
    let data = await getAllStationLookupFunc();
    if (data.length > 0) {
      // setProductStationsWithPleaseSelectOp(data);
      if (data?.find((x) => x.productStationId == 0)) {
        data.shift();
      }
      setProductStations(data);
    }
  };
  let getAllProductOptionLookup = async () => {
    let res = await GetAllProductOptionLookup({});
    if (res.data.result !== null) setAllProductOptions(res.data.result);
  };

  useEffect(() => {
    getAllStores();
    getAllProductCategoryLookup();
    getAllStationLookup();
    getAllProductOptionLookup();
  }, []);

  const createCategory = () => {
    if (categoryName == "") {
      errorNotification(
        LanguageReducer?.languageType?.CATEGORY_NAME_CANNOT_BE_EMPTY_TOAST
      );
      return;
    }
    CreateProductCategory({ categoryName: categoryName })
      .then((res) => {
        console.log("res:", res);
        if (!res?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res?.data?.errors);
        } else {
          successNotification(
            LanguageReducer?.languageType?.CATEGORY_CREATED_SUCCESSFULLY_TOAST
          );
          handleClose();
          getAllProductCategoryLookup();
        }
      })
      .catch((e) => {
        console.log("e", e);
        errorNotification(
          LanguageReducer?.languageType?.UNABLE_TO_CREATE_CATEGORY_TOAST
        );
      });
  };
  const checkUniqueProductStockSkus = async () => {
    let isExist = false;
    const combinations_skus = groupedCombination.map((dt) => {
      return dt.SKU;
    });
    const { response } = await fetchMethod(() =>
      CheckUniqueProductStockSkus(String(combinations_skus))
    );

    if (response) {
      if (!response.isSuccess) {
        isExist = true;
        UtilityClass.showErrorNotificationWithDictionary(response.errors);
      }
    }
    return isExist;
  };
  const createProduct = async (data) => {
    let res = await CheckUniqueProductStockSku(data.SKU);
    if (res.data.result.isExist) {
      errorNotification(
        LanguageReducer?.languageType?.SKU_NOT_AVAILBE_EMPTY_TOAST
      );
      return;
    }

    let productStocks = [];
    let count = 0;

    //remove 0 station value
    let filteredCombination = combinations?.filter((x) => x.stationIds != 0);
    let errForStations = [];

    if (!optionsCheckbox) {
      for (let index = 0; index < stationValues.length; index++) {
        if (stationValues[index]) {
          productStocks[count] = {
            Sku: data.SKU,
            price: data.price,
            QuantityAvailable: stationValues[index].quantityAvailable,
            LowQuantityLimit: stationValues[index].lowQuantityLimit,
            ProductStationId: stationValues[index].productStationId,
            VarientOption: "",
          };
          count++;
        }
      }
    } else {
      console.log("before");
      let existResult = await checkUniqueProductStockSkus();

      if (!existResult) {
        for (let index = 0; index < filteredCombination.length; index++) {
          if (filteredCombination[index]) {
            // if (filteredCombination[index].stationIds.length > 0) {
            // for (
            //   let j = 0;
            //   j < filteredCombination[index].stationIds.length;
            //   j++
            // ) {
            productStocks[count] = {
              Sku:
                filteredCombination[index].SKU == ""
                  ? data.SKU + "-" + index
                  : filteredCombination[index].SKU,
              price: filteredCombination[index].prices,
              QuantityAvailable: filteredCombination[index].quantities,
              LowQuantityLimit: filteredCombination[index].lowQuantityLimit,
              ProductStationId: filteredCombination[index].stationIds,
              VarientOption: filteredCombination[index].variantOption,
            };
            count++;
            //}
            // } else {
            //   errForStations.push({
            //     index: index,
            //     sku: filteredCombination[index].SKU,
            //   });
            // }
          }
        }
      } else {
        console.log("after");

        return false;
      }

      console.log("last");
    }
    if (errForStations.length > 0 && optionsCheckbox) {
      errForStations.forEach((element) => {
        errorNotification(
          "Please add quanity and choose station against " +
            element.sku +
            " at row " +
            element.index +
            1
        );
        return false;
      });
    } else {
      setIsLoading(true);

      let productOptions = [];
      let productOptionCount = 0;
      for (let index = 0; index < inputFields.length; index++) {
        if (inputFields[index]) {
          for (
            let jindex = 0;
            jindex < inputFields[index].options.length;
            jindex++
          ) {
            productOptions[productOptionCount] = {
              OptionId: inputFields[index].selectedOption,
              OptionValue: inputFields[index].options[jindex],
            };
            productOptionCount++;
          }
        }
      }
      const params = {
        sku: data.SKU,
        productName: data.productName,
        price: data.price,
        purchasePrice: data.purchasePrice,
        description: data.description,
        productCategoryId: data.productCategoryId.productCategoryId,
        featureImage: productFile,
        weight: data.weight,
        haveOptions: optionsCheckbox,
        storeId: data.store.storeId,
        trackInventory: trackInventoryCheckbox,
        productOptions: optionsCheckbox == false ? [] : productOptions,
        productStocks: productStocks,
      };
      console.log("params", params);
      CreateProduct(params)
        .then((res) => {
          console.log("res:::", res);
          if (!res?.data?.isSuccess) {
            UtilityClass.showErrorNotificationWithDictionary(res?.data?.errors);
            setIsLoading(false);
          } else {
            successNotification(
              LanguageReducer?.languageType?.PRODUCT_CREATED_SUCCESSFULLY_TOAST
            );
            setIsLoading(false);
            navigate("/products");
          }
        })
        .catch((e) => {
          console.log("e", e);
          UtilityClass.showErrorNotificationWithDictionary(
            e?.response?.data?.errors
          );
          setIsLoading(false);
        })
        .finally((e) => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    let sp = getValues("price");
    let pp = getValues("purchasePrice");
    if (parseInt(sp) && parseInt(pp)) {
      if (parseInt(sp) < parseInt(pp)) {
        setIsLowerPrice(true);
      } else {
        setIsLowerPrice(false);
      }
    } else {
      setIsLowerPrice(false);
    }
  }, [watch("purchasePrice"), watch("price")]);

  useEffect(() => {
    if (combinations.length > 0) {
      const updatedArr = combinations.map((item, idx) => {
        return {
          ...item,
          SKU: getValues("SKU") + "-" + (item.SKU.split("-")[1] || 0),
          ORGSKU: getValues("SKU") + "-" + (item.SKU.split("-")[1] || 0),
          prices: getValues("price"),
        };
      });
      setCombinations(updatedArr);
      var groupedData = groupData(updatedArr);
      setGroupedCombination(groupedData);
    } else {
    }
  }, [watch("SKU"), watch("price")]);
  //#region
  // Function to find an object by its uuid and update the quantity
  function updateQuantityByUuid(array, uuid, newQuantity) {
    const updatedProducts = array?.map((product) => {
      if (product.uuid === uuid) {
        let llQty = product.lowQuantityLimit;
        if (newQuantity == 0) {
          llQty = 0;
        }
        return {
          ...product,
          quantities: newQuantity,
          lowQuantityLimit: llQty,
        };
      }
      return product;
    });

    return updatedProducts;
  }
  function updateLowQuantityByUuid(array, uuid, newQuantity) {
    const updatedProducts = array?.map((product) => {
      if (product.uuid === uuid) {
        return {
          ...product,
          lowQuantityLimit: newQuantity,
        };
      }
      return product;
    });

    return updatedProducts;
  }
  const updateIsAddedQtyAgainstSku = (tempCombinations, item) => {
    const updatedProducts = tempCombinations?.map((product) => {
      if (
        product.SKU === item.SKU &&
        product.stationIds == qtySelectedStation
      ) {
        return {
          ...product,
          isAddedStationQty: true,
        };
      }
      return product;
    });

    return updatedProducts;
  };
  const handleStationQuantity = (e, item) => {
    let qty = Number(e.target.value) ? Number(e.target.value) : 0;
    //#region
    const showTempArr = updateQuantityByUuid(tempCombinations, item?.uuid, qty);
    setTempCombinations(showTempArr);
    //#endregion
    //#region
    const showArr = updateQuantityByUuid(updateQtyShowRecord, item?.uuid, qty);
    setUpdateQtyShowRecord(showArr);
    //#endregion
  };
  const handleStationLowQuantity = (e, item) => {
    let qty = Number(e.target.value) ? Number(e.target.value) : 0;
    //#region
    const showTempArr = updateLowQuantityByUuid(
      tempCombinations,
      item?.uuid,
      qty
    );
    setTempCombinations(showTempArr);
    //#endregion
    //#region
    const showArr = updateLowQuantityByUuid(
      updateQtyShowRecord,
      item?.uuid,
      qty
    );
    setUpdateQtyShowRecord(showArr);
    //#endregion
  };
  //#region update sku
  function updateSkuByUuid(array, SKU, SKUAreChanged) {
    const updatedProducts = array?.map((product) => {
      if (product.SKU === SKU) {
        return {
          ...product,
          SKU: SKUAreChanged,
        };
      }
      return product;
    });

    return updatedProducts;
  }
  const handleSkuChanged = (e, item) => {
    let sku = e.target.value;
    if (sku == "") {
      errorNotification("Please enter sku");
      sku = item.SKU;
      return;
    }
    //#region
    const showTempArr = updateSkuByUuid(tempCombinations, item?.SKU, sku);
    setTempCombinations(showTempArr);
    //#endregion
    //#region
    const showArr = updateSkuByUuid(updateQtyShowRecord, item?.SKU, sku);
    setUpdateQtyShowRecord(showArr);
    //#endregion
  };
  function updateCombinationArrayWithUpdatedSKUValues() {
    console.log("before com" + combinations.length, combinations);
    let updatedArray = [];
    updateQtyShowRecord.forEach((srecord) => {
      updatedArray = combinations.map((item) => {
        if (item.SKU === srecord.ORGSKU) {
          // Modify the properties you want to update
          item.SKU = srecord.SKU;
          item.ORGSKU = srecord.SKU;
        }
        return item;
      });
    });
    console.log("after com " + combinations.length, combinations);

    return updatedArray;
  }
  const handleUpdateSKU = () => {
    //set final array data
    let updatedProducts = updateCombinationArrayWithUpdatedSKUValues();
    setCombinations(updatedProducts);
    //updated group data
    var groupedData = groupData(updatedProducts);
    setGroupedCombination(groupedData);
    // setTempCombinations([]);
    // setUpdateQtyShowRecord([]);
  };
  //#endregion
  //#region update price
  function updatePriceBySKU(array, SKU, prices) {
    const updatedProducts = array?.map((product) => {
      if (product.SKU === SKU) {
        return {
          ...product,
          prices: prices,
        };
      }
      return product;
    });

    return updatedProducts;
  }
  const handlePriceChanged = (e, item) => {
    let prices = Number(e.target.value);
    if (!prices) {
      errorNotification("Please enter price");
      prices = item.prices;
      return;
    }
    //#region
    const showTempArr = updatePriceBySKU(tempCombinations, item?.SKU, prices);
    setTempCombinations(showTempArr);
    //#endregion
    //#region
    const showArr = updatePriceBySKU(updateQtyShowRecord, item?.SKU, prices);
    setUpdateQtyShowRecord(showArr);
    //#endregion
  };
  function updateCombinationArrayWithUpdatedPriceValues() {
    console.log("before com" + combinations.length, combinations);
    let updatedArray = [];
    updateQtyShowRecord.forEach((srecord) => {
      updatedArray = combinations.map((item) => {
        if (item.SKU === srecord.SKU) {
          // Modify the properties you want to update
          item.prices = srecord.prices; // For example, update prices to 10
        }
        return item;
      });
    });
    console.log("after com " + combinations.length, combinations);

    return updatedArray;
  }
  const handleUpdatePrice = () => {
    //set final array data
    let updatedProducts = updateCombinationArrayWithUpdatedPriceValues();
    setCombinations(updatedProducts);
    //updated group data
    var groupedData = groupData(updatedProducts);
    setGroupedCombination(groupedData);
    // setTempCombinations([]);
    // setUpdateQtyShowRecord([]);
  };
  //#endregion
  //#region update quantity
  function updateCombinationArrayWithUpdatedValues() {
    console.log("before com" + combinations.length, combinations);
    updateQtyShowRecord.forEach((srecord) => {
      const indexToUpdate = combinations.findIndex(
        (obj1) => obj1.uuid === srecord.uuid
      );
      if (indexToUpdate !== -1) {
        combinations[indexToUpdate].quantities = srecord.quantities;
        combinations[indexToUpdate].lowQuantityLimit = srecord.lowQuantityLimit;
        combinations[indexToUpdate].isAddedStationQty = true;
      }
    });
    console.log("after com " + combinations.length, combinations);

    return combinations;
  }
  const handleAddQty = () => {
    //set final array data
    let updatedProducts = updateCombinationArrayWithUpdatedValues();
    setCombinations(updatedProducts);
    //updated group data
    var groupedData = groupData(updatedProducts);
    setGroupedCombination(groupedData);
    setQtySelectedStation(productStations[0]);
    // setTempCombinations([]);
    // setUpdateQtyShowRecord([]);
  };
  //#endregion
  useEffect(() => {
    let targetedData = tempCombinations?.filter(
      (x) =>
        x.stationIds == qtySelectedStation?.productStationId &&
        x.SKUChecked == true
    );
    setUpdateQtyShowRecord(targetedData);
  }, [qtySelectedStation]);

  const getValueFromRow = () => {
    {
      let val = updateQtyShowRecord?.map((items, index) => {
        let disabled = qtySelectedStation.productStationId == 0;
        return (
          <>
            <StyledTableRow key={index}>
              <StyledTableCell
                width={"50%"}
                align="left"
                component="th"
                scope="row"
              >
                {items?.variantOption}
              </StyledTableCell>
              <StyledTableCell align="">
                <TextField
                  width={"25%"}
                  fullWidth
                  placeholder={placeholders.quantity}
                  label="Quantity"
                  disabled={disabled}
                  onChange={(e) => {
                    handleStationQuantity(e, items);
                  }}
                  value={items?.quantities}
                  sx={
                    {
                      // width: 150,
                    }
                  }
                  onFocus={handleFocus}
                  variant="outlined"
                  size="small"
                ></TextField>
              </StyledTableCell>
              <StyledTableCell width={"25%"} align="">
                <TextField
                  placeholder={placeholders.quantity}
                  label="Low Quantity Limit"
                  disabled={disabled}
                  onChange={(e) => {
                    handleStationLowQuantity(e, items, true);
                  }}
                  value={items?.lowQuantityLimit}
                  sx={
                    {
                      // width: 150,
                    }
                  }
                  fullWidth
                  onFocus={handleFocus}
                  variant="outlined"
                  size="small"
                ></TextField>
              </StyledTableCell>
            </StyledTableRow>{" "}
          </>
        );
      });
      return val;
    }
  };
  //#region default select option select
  useEffect(() => {
    if (stores.length > 0) {
      setValue("store", stores[1]);
    }
  }, [stores]);
  useEffect(() => {
    if (productCategories.length > 0) {
      setValue("productCategoryId", productCategories[1]);
    }
  }, [productCategories]);
  //#endregion
  //#endregion

  useSetNumericInputEffect([openPrices, openQuantities]);
  return (
    <Box sx={styleSheet.pageRoot}>
      {/* <Container maxWidth="xl" fixed sx={{ paddingLeft: "0px" }}> */}
      <div style={{ padding: "10px" }}>
        <form onSubmit={handleSubmit(createProduct)}>
          <GridContainer>
            <GridItem xs={12} textAlign={"right"}>
              <ActionButtonCustom
                onClick={() => {
                  navigate("/products");
                }}
                variant="contained"
                label={"Product Dashboard"}
              />
            </GridItem>
            {/* product detail box */}
            <GridItem>
              <CustomColorLabelledOutline
                label={LanguageReducer?.languageType?.PRODUCT_DETAIL_TEXT}
              >
                <GridContainer>
                  <Grid item xs={6}>
                    <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                      {LanguageReducer?.languageType?.PRODUCTS_STORE}
                    </InputLabel>
                    <SelectComponent
                      name="store"
                      optionProperty={"storeName"}
                      setValue={setValue}
                      control={control}
                      options={stores}
                      isRHF={true}
                      required={true}
                      optionLabel={EnumOptions.STORE.LABEL}
                      optionValue={EnumOptions.STORE.VALUE}
                      {...register("store", {
                        required: {
                          value: true,
                        },
                      })}
                      value={getValues("store")}
                      onChange={(event, newValue) => {
                        const resolvedId = newValue ? newValue : null;
                        setValue("store", resolvedId);
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                      {LanguageReducer?.languageType?.TITLE_TEXT}
                    </InputLabel>
                    <TextField
                      type="text"
                      size="small"
                      id="productName"
                      name="productName"
                      onFocus={handleFocus}
                      fullWidth
                      variant="outlined"
                      {...register("productName", {
                        required: {
                          value: true,
                          message:
                            LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                        },
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message:
                            LanguageReducer?.languageType
                              ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                        },
                      })}
                      error={Boolean(errors.productName)} // set error prop
                      helperText={errors.productName?.message}
                      placeholder={placeholders.category_name}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                      {LanguageReducer?.languageType?.DESCRIPTION_TEXT}
                    </InputLabel>
                    <TextField
                      placeholder="Description"
                      onFocus={handleFocus}
                      size="small"
                      multiline
                      fullWidth
                      rows={3}
                      variant="outlined"
                      id="description"
                      name="description"
                      {...register("description", {
                        required: {
                          value: true,
                          message:
                            LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                        },
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message:
                            LanguageReducer?.languageType
                              ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                        },
                      })}
                      error={Boolean(errors.description)} // set error prop
                      helperText={errors.description?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                      {LanguageReducer?.languageType?.CATEGORY_TEXT}
                    </InputLabel>
                    <Box className={"flex_between"}>
                      <Box flexBasis={"100%"}>
                        <SelectComponent
                          name="productCategoryId"
                          control={control}
                          options={productCategories}
                          getOptionLabel={(option) => option?.categoryName}
                          isRHF={true}
                          required={true}
                          optionLabel={EnumOptions.CATAGORY.LABEL}
                          optionValue={EnumOptions.CATAGORY.VALUE}
                          {...register("productCategoryId", {
                            required: {
                              value: true,
                            },
                          })}
                          value={getValues("productCategoryId")}
                          onChange={(event, newValue) => {
                            const _productCategoryId = newValue
                              ? newValue
                              : null;
                            setValue("productCategoryId", _productCategoryId);
                          }}
                          errors={errors}
                        />
                      </Box>
                      <Button
                        onClick={handleOpen}
                        sx={{
                          ...styleSheet.addStoreButton,
                          padding: "0px",
                          minWidth: "40px !important",
                          height: "37px",
                          borderRadius: "0 4px 4px 0",
                        }}
                        variant="contained"
                      >
                        +
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                      {LanguageReducer?.languageType?.WEIGHT_TEXT}
                    </InputLabel>
                    <TextField
                      placeholder={placeholders.weight}
                      onFocus={handleFocus}
                      type="number"
                      size="small"
                      id="weight"
                      name="weight"
                      inputProps={{
                        step: "any",
                      }}
                      fullWidth
                      variant="outlined"
                      {...register("weight", {
                        required: {
                          value: true,
                          message:
                            LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                        },
                        // pattern: {
                        //   value: /^[0-9]\d*$/,
                        //   message:
                        //     LanguageReducer?.languageType
                        //       ?.WEIGHT_MUST_BE_GREATER_THAN_ZERO_TOAST,
                        // },
                      })}
                      error={Boolean(errors.weight)} // set error prop
                      helperText={errors.weight?.message}
                    ></TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                      {LanguageReducer?.languageType?.SALE_PRICE_TEXT}
                    </InputLabel>
                    <TextField
                      placeholder={placeholders.price}
                      type="number"
                      onFocus={handleFocus}
                      size="small"
                      id="price"
                      inputProps={{
                        step: "any",
                      }}
                      name="price"
                      fullWidth
                      variant="outlined"
                      {...register("price", {
                        required: {
                          value: true,
                          message:
                            LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                        },
                        pattern: {
                          // value: /^[1-9]\d*$/,
                          message:
                            LanguageReducer?.languageType
                              ?.PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST,
                        },
                      })}
                      error={Boolean(errors.price)} // set error prop
                      helperText={errors.price?.message}
                    ></TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel required sx={styleSheet.inputLabelAddProduct}>
                      {LanguageReducer?.languageType?.PURCHASE_PRICE_TEXT}
                    </InputLabel>
                    <TextField
                      placeholder={placeholders.price}
                      type="number"
                      size="small"
                      onFocus={handleFocus}
                      id="purchasePrice"
                      name="purchasePrice"
                      inputProps={{
                        step: "any",
                      }}
                      fullWidth
                      variant="outlined"
                      {...register("purchasePrice", {
                        required: {
                          value: true,
                          message:
                            LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                        },
                        pattern: {
                          // value: /^[1-9]\d*$/,
                          message:
                            LanguageReducer?.languageType
                              ?.PURCHASE_PRICE_MUST_BE_GREATER_THAN_ZERO_TOAST,
                        },
                      })}
                      min={0}
                      error={Boolean(errors.purchasePrice)} // set error prop
                      helperText={errors.purchasePrice?.message}
                    ></TextField>
                    {isLowerPrice && (
                      <FormLabel sx={{ fontSize: "10px" }} error>
                        *Your Purchase price is greater than sale price
                      </FormLabel>
                    )}
                  </Grid>
                </GridContainer>
              </CustomColorLabelledOutline>
            </GridItem>
            {/* feature img box */}
            <GridItem>
              <CustomColorLabelledOutline
                label={"Feature Image"}
                height={"100%"}
              >
                <DragDropFile setProductFile={setProductFile} />
              </CustomColorLabelledOutline>
            </GridItem>
            {/* inventory box */}
            <GridItem xs={optionsCheckbox ? 6 : 12}>
              <CustomColorLabelledOutline
                label={LanguageReducer?.languageType?.INVENTORY_TEXT}
              >
                <GridContainer>
                  <Grid item xs={12}>
                    <Grid item XS={12}>
                      <FormGroup>
                        <FormControlLabel
                          onChange={(e) => {
                            setTrackInventoryCheckbox(!trackInventoryCheckbox);
                          }}
                          value={trackInventoryCheckbox}
                          control={
                            <Checkbox
                              sx={{
                                color: "#563AD5",
                                "&.Mui-checked": {
                                  color: "#563AD5",
                                },
                              }}
                              checked={trackInventoryCheckbox}
                            />
                          }
                          label={
                            LanguageReducer?.languageType?.TRACK_INVENTORY_TEXT
                          }
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        alignContent={"center"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"end"}
                      >
                        <InputLabel
                          required
                          sx={styleSheet.inputLabelAddProduct}
                        >
                          {LanguageReducer?.languageType?.SKU_TEXT}
                        </InputLabel>
                        {/* <FormGroup sx={{ marginTop: "15px" }}>
                          <FormControlLabel
                            onChange={(e) => {
                              handleAutoGenerate(e);
                            }}
                            value={autoGenerateSku}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#563AD5",
                                  "&.Mui-checked": {
                                    color: "#563AD5",
                                  },
                                }}
                                checked={autoGenerateSku}
                              />
                            }
                            label={"Auto Generate SKU"}
                          />
                        </FormGroup> */}
                        <Box mb={0.5}>
                          <ButtonComponent
                            title={"Auto Generate SKU"}
                            onClick={getUniqueAutogeneratedSku}
                          />
                        </Box>
                      </Box>
                      <TextField
                        placeholder={placeholders.sku}
                        size="small"
                        onFocus={handleFocus}
                        id="SKU"
                        name="SKU"
                        fullWidth
                        variant="outlined"
                        {...register("SKU", {
                          required: {
                            value: true,
                            message:
                              LanguageReducer?.languageType
                                ?.FIELD_REQUIRED_TEXT,
                          },
                          pattern: {
                            value: /^(?!\s*$).+/,
                            message:
                              LanguageReducer?.languageType
                                ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                          },
                        })}
                        error={Boolean(errors.SKU)} // set error prop
                        helperText={errors.SKU?.message}
                      ></TextField>
                    </Grid>
                    <br />
                    {!optionsCheckbox ? (
                      trackInventoryCheckbox ? (
                        <Grid item xs={12}>
                          <TableContainer component={Paper}>
                            <Table fullWidth aria-label="customized table">
                              <TableBody>
                                {stationValues.map((row, i) => {
                                  return (
                                    <>
                                      {row.productStationId > 0 && (
                                        <StyledTableRow
                                          key={row.productStationId}
                                        >
                                          <StyledTableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                          >
                                            {row.sname}
                                          </StyledTableCell>
                                          <StyledTableCell align="right">
                                            <Box display={"flex"} gap={1}>
                                              <TextField
                                                onChange={(e) => {
                                                  let newArr = [
                                                    ...stationValues,
                                                  ];
                                                  newArr[i] = {
                                                    sname: newArr[i].sname,
                                                    productStationId:
                                                      row.productStationId,
                                                    quantityAvailable:
                                                      parseInt(
                                                        e.target.value
                                                      ) || 0,
                                                    lowQuantityLimit: newArr[i]
                                                      ?.lowQuantityLimit
                                                      ? newArr[i]
                                                          .lowQuantityLimit
                                                      : 0,
                                                  };
                                                  setStationValues(newArr);
                                                }}
                                                defaulValue={
                                                  row.quantityAvailable || 0
                                                }
                                                value={
                                                  row.quantityAvailable || 0
                                                }
                                                min={0}
                                                type="number"
                                                size="small"
                                                onFocus={handleFocus}
                                                fullWidth
                                                variant="outlined"
                                                label="Quantity"
                                              ></TextField>
                                              <TextField
                                                onChange={(e) => {
                                                  let newArr = [
                                                    ...stationValues,
                                                  ];
                                                  newArr[i] = {
                                                    sname: newArr[i].sname,
                                                    productStationId:
                                                      row.productStationId,
                                                    lowQuantityLimit:
                                                      parseInt(
                                                        e.target.value
                                                      ) || 0,
                                                    quantityAvailable: newArr[i]
                                                      ?.quantityAvailable
                                                      ? newArr[i]
                                                          .quantityAvailable
                                                      : 0,
                                                  };
                                                  console.log(newArr);

                                                  setStationValues(newArr);
                                                }}
                                                defaulValue={
                                                  row.lowQuantityLimit || 0
                                                }
                                                value={
                                                  row.lowQuantityLimit || 0
                                                }
                                                min={0}
                                                type="number"
                                                size="small"
                                                onFocus={handleFocus}
                                                fullWidth
                                                variant="outlined"
                                                label="Low Quantity Amount"
                                              ></TextField>
                                            </Box>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      )}
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      ) : null
                    ) : null}
                    <FormGroup>
                      <FormControlLabel
                        onChange={(e) => {
                          setOptionsCheckbox(!optionsCheckbox);
                          setCombinations([]);
                          setTempCombinations([]);
                          setInputFields(initInputFileds);
                        }}
                        value={optionsCheckbox}
                        control={
                          <Checkbox
                            sx={{
                              color: "#563AD5",
                              "&.Mui-checked": {
                                color: "#563AD5",
                              },
                            }}
                          />
                        }
                        label={
                          LanguageReducer?.languageType
                            ?.THIS_PRODUCT_HAS_OPTIONS_TEXT
                        }
                      />
                    </FormGroup>
                    {optionsCheckbox ? (
                      <>
                        {inputFields.map((data, index) => {
                          return (
                            <Fragment key={index}>
                              <Stack direction="row">
                                <InputLabel
                                  sx={styleSheet.inputLabelAddProduct}
                                >
                                  {
                                    LanguageReducer?.languageType
                                      ?.OPTION_NAME_TEXT
                                  }
                                </InputLabel>
                                {inputFields.length !== 0 ? (
                                  <>
                                    <IconButton
                                      sx={{
                                        marginLeft: "auto",
                                        color: "red",
                                        fontSize: "18px !important",
                                      }}
                                      aria-label="delete"
                                      onClick={() => removeInputFields(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Stack>

                              <TextField
                                onChange={(evnt) => handleChange(index, evnt)}
                                defaultValue=""
                                select
                                size="small"
                                // onFocus={handleFocus}
                                fullWidth
                                variant="outlined"
                              >
                                <MenuItem value="" disabled></MenuItem>
                                {allProductOptions?.map((allProductOption) => {
                                  let disabled = false;

                                  if (inputFields.length > 0) {
                                    if (
                                      inputFields.find(
                                        (x) =>
                                          x.selectedOption ==
                                          allProductOption?.id
                                      )
                                    ) {
                                      disabled = true;
                                    }
                                  }
                                  return (
                                    <MenuItem
                                      key={allProductOption.id}
                                      value={allProductOption.id}
                                      disabled={disabled}
                                    >
                                      {allProductOption.text}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              <Stack
                                alignItems={"center"}
                                mt={1}
                                gap={1}
                                direction={"row"}
                              >
                                <InputLabel
                                  sx={styleSheet.inputLabelAddProduct}
                                >
                                  <Box>{"Option Value"}</Box>
                                </InputLabel>
                                <Tooltip title={"hit enter to add option"}>
                                  <HelpOutline
                                    fontSize={"20px"}
                                    color="primary"
                                  />
                                </Tooltip>
                              </Stack>

                              <Autocomplete
                                multiple
                                id="tags-filled"
                                variant="outlined"
                                size="small"
                                // onFocus={handleFocus}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onChange={(e, value) => {
                                  const list = [...inputFields];
                                  list[index].options = value;
                                  setInputFields(list);
                                  setSelected([]);
                                }}
                                options={[]}
                                freeSolo
                                renderTags={(value, getTagProps) => {
                                  return inputFields[index].options.map(
                                    (option, index) => (
                                      <Chip
                                        key={index}
                                        variant="outlined"
                                        size="small"
                                        label={option}
                                        {...getTagProps({ index })}
                                      />
                                    )
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    placeholder={
                                      optionValuePlaceHolder
                                        ? optionValuePlaceHolder[index]
                                        : "Select option"
                                    }
                                  />
                                )}
                              />
                            </Fragment>
                          );
                        })}
                        <Button
                          sx={{
                            ...styleSheet.addStoreButton,
                            marginTop: "10px",
                          }}
                          fullWidth
                          variant="contained"
                          onClick={addInputField}
                        >
                          + {LanguageReducer?.languageType?.ADD_OPTION_TEXT}
                        </Button>
                      </>
                    ) : null}
                  </Grid>
                  {!optionsCheckbox && (
                    <GridItem xs={12}>
                      <div
                        style={{
                          paddingTop: "20px",
                          textAlign: "Right",
                          alignItems: "Right",
                        }}
                      >
                        {isLoading ? (
                          <Button
                            fullWidth
                            variant="contained"
                            sx={styleSheet.addStoreButton}
                            type="submit"
                          >
                            <CircularProgress sx={{ color: "white" }} />
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            variant="contained"
                            sx={styleSheet.addStoreButton}
                            type="submit"
                          >
                            {LanguageReducer?.languageType?.CREATE_PRODUCT_TEXT}
                          </Button>
                        )}
                      </div>
                    </GridItem>
                  )}
                </GridContainer>
              </CustomColorLabelledOutline>
            </GridItem>
            {/* varient box */}
            <GridItem>
              {optionsCheckbox && (
                <CustomColorLabelledOutline
                  label={LanguageReducer?.languageType?.VARIANTS_TEXT}
                >
                  <GridContainer>
                    <Grid item xs={12}>
                      <Typography
                        sx={styleSheet.inventoryMethodHeading}
                        variant="h6"
                      >
                        {
                          LanguageReducer?.languageType
                            ?.AVAILABLE_INVENTORY_AT_TEXT
                        }
                        {LanguageReducer?.languageType?.ALL_LOCATION_TEXT}
                      </Typography>
                      <hr />
                      <FormGroup>
                        <Box sx={{ width: "100%" }}>
                          <Paper sx={{ width: "100%", mb: 2 }}>
                            {/* {optionsCheckbox ? ( */}
                            <EnhancedTableToolbar
                              numSelected={selected.length}
                              handleOpenPrices={handleOpenPrices}
                              handleOpenQuantities={handleOpenQuantities}
                              handleOpenSKU={handleOpenSKU}
                              handleCloseQuantities={handleCloseQuantities}
                              handleCloseSKU={handleCloseSKU}
                              handleClosePrices={handleClosePrices}
                            />
                            {/* ) : null} */}
                            <TableContainer
                              style={{
                                maxHeight: 500,
                                overflowY: "auto",
                              }}
                            >
                              <Table
                                sx={{ width: "100%" }}
                                aria-labelledby="tableTitle"
                                size={"small"}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        sx={{
                                          color: "#563AD5",
                                          "&.Mui-checked": {
                                            color: "#563AD5",
                                          },
                                        }}
                                        indeterminate={
                                          selected.length ===
                                          groupedCombination.length
                                        }
                                        checked={
                                          selected.length ===
                                          groupedCombination.length
                                        }
                                        onChange={handleSelectAllClick}
                                        inputProps={{
                                          "aria-label": "select all desserts",
                                        }}
                                      />
                                    </TableCell>
                                    {headCells.map((headCell) => (
                                      <TableCell
                                        key={headCell.id}
                                        align={
                                          headCell.numeric ? "right" : "left"
                                        }
                                        padding={
                                          headCell.disablePadding
                                            ? "none"
                                            : "normal"
                                        }
                                      >
                                        <TableSortLabel>
                                          {headCell.label}
                                        </TableSortLabel>
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                {optionsCheckbox ? (
                                  <TableBody>
                                    {groupedCombination.map(
                                      (combination, index) => {
                                        const isItemSelected =
                                          isSelected(combination); //combination.SKUChecked;
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        {
                                        }
                                        return (
                                          <TableRow
                                            hover
                                            onClick={(event) =>
                                              handleClick(
                                                event,
                                                index,
                                                combination
                                              )
                                            }
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            sx={{ cursor: "pointer" }}
                                          >
                                            <TableCell padding="checkbox">
                                              <Checkbox
                                                sx={{
                                                  color: "#563AD5",
                                                  "&.Mui-checked": {
                                                    color: "#563AD5",
                                                  },
                                                }}
                                                checked={isItemSelected}
                                                inputProps={{
                                                  "aria-labelledby": labelId,
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              id={labelId}
                                              scope="row"
                                              padding="none"
                                            >
                                              <CardHeader
                                                avatar={
                                                  <Avatar
                                                    variant="square"
                                                    src={ImageIcon}
                                                    aria-label="Image"
                                                  />
                                                }
                                                title={combination.variants?.map(
                                                  (option, optionIndex) =>
                                                    optionIndex ==
                                                    combination.variants
                                                      ?.length -
                                                      1
                                                      ? `${option}`
                                                      : `${option}/`
                                                )}
                                                subheader={
                                                  combination.SKU == ""
                                                    ? getValues("SKU") +
                                                      "-" +
                                                      index
                                                    : combination.SKU
                                                }
                                              />
                                            </TableCell>
                                            <TableCell align="left">
                                              <CardHeader
                                                avatar={<span></span>}
                                                title={`Price: ${
                                                  combination.prices || 0
                                                }`}
                                                subheader={`${combination.quantities} Available at ${combination.stationCount} locations`}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                ) : null}
                              </Table>
                            </TableContainer>
                          </Paper>
                        </Box>
                      </FormGroup>
                    </Grid>
                    <GridItem xs={12}>
                      <div
                        style={{
                          paddingTop: "20px",
                          textAlign: "Right",
                          alignItems: "Right",
                        }}
                      >
                        {isLoading ? (
                          <Button
                            fullWidth
                            variant="contained"
                            sx={styleSheet.addStoreButton}
                            type="submit"
                          >
                            <CircularProgress sx={{ color: "white" }} />
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            variant="contained"
                            sx={styleSheet.addStoreButton}
                            type="submit"
                          >
                            {LanguageReducer?.languageType?.CREATE_PRODUCT_TEXT}
                          </Button>
                        )}
                      </div>
                    </GridItem>
                  </GridContainer>
                </CustomColorLabelledOutline>
              )}
            </GridItem>
          </GridContainer>
        </form>
      </div>

      <ModalComponent
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        title={LanguageReducer?.languageType?.ADD_CATEGORY_TEXT}
        actionBtn={
          <ModalButtonComponent
            title={LanguageReducer?.languageType?.ADD_CATEGORY_TEXT}
            // loading={isLoading}
            bg={purple}
            onClick={createCategory}
          />
        }
        style={{ overflow: "hidden", height: "unset" }}
      >
        <Box>
          <InputLabel required sx={styleSheet.inputLabelAddProduct}>
            {LanguageReducer?.languageType?.CATEGORY_NAME_TEXT}
          </InputLabel>
          <TextField
            placeholder={placeholders.category_name}
            value={categoryName}
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
            fullWidth
            variant="outlined"
            onFocus={handleFocus}
            size="small"
          />
        </Box>
      </ModalComponent>
      <ModalComponent
        open={openSKU}
        onClose={handleCloseSKU}
        maxWidth="md"
        title={LanguageReducer?.languageType?.EDIT_SKU_TEXT}
        actionBtn={
          <ModalButtonComponent
            title={LanguageReducer?.languageType?.SUBMIT_TEXT}
            // loading={isLoading}
            bg={purple}
            onClick={() => {
              handleUpdateSKU();
              handleCloseSKU();
            }}
          />
        }
        style={{ overflow: "hidden", height: "unset" }}
      >
        <TableContainer component={Paper}>
          <Table fullWidth aria-label="customized table">
            <TableBody>
              {updateQtyShowRecord.map((items, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {items?.variantOption}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TextField
                        placeholder={placeholders.sku}
                        onChange={(e) => {
                          handleSkuChanged(e, items);
                        }}
                        value={items?.SKU}
                        onFocus={handleFocus}
                        fullWidth
                        variant="outlined"
                        size="small"
                      ></TextField>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalComponent>
      <ModalComponent
        open={openPrices}
        onClose={handleClosePrices}
        maxWidth="md"
        title={LanguageReducer?.languageType?.EDIT_PRICES_TEXT}
        actionBtn={
          <ModalButtonComponent
            title={LanguageReducer?.languageType?.SUBMIT_TEXT}
            // loading={isLoading}
            bg={purple}
            onClick={() => {
              handleUpdatePrice();
              handleClosePrices();
            }}
          />
        }
        style={{ overflow: "hidden", height: "unset" }}
      >
        <TableContainer component={Paper}>
          <Table fullWidth aria-label="customized table">
            <TableBody>
              {updateQtyShowRecord?.map((items, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {items?.variantOption}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TextField
                        type="number"
                        placeholder={placeholders.price}
                        onChange={(e) => {
                          handlePriceChanged(e, items);
                        }}
                        value={items?.prices}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onFocus={handleFocus}
                      ></TextField>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalComponent>

      <ModalComponent
        open={openQuantities}
        onClose={handleCloseQuantities}
        maxWidth="md"
        title={LanguageReducer?.languageType?.EDIT_QUNATITIES_TEXT}
        actionBtn={
          <ModalButtonComponent
            title={"Add Quantity"}
            // loading={isLoading}
            bg={purple}
            onClick={() => {
              handleAddQty();
              handleCloseQuantities();
            }}
          />
        }
        style={{ overflow: "hidden", height: "unset" }}
      >
        <Grid container spacing={2} sx={{ mt: "5px" }}>
          <Grid item sm={12} md={12} lg={12}>
            <SelectComponent
              name="reason"
              options={productStations}
              defaulValue={qtySelectedStation}
              value={qtySelectedStation}
              optionLabel={EnumOptions.SELECT_STATION.LABEL}
              optionValue={EnumOptions.SELECT_STATION.VALUE}
              onChange={(e, newValue) => {
                const resolvedId = newValue ? newValue : null;
                setQtySelectedStation(resolvedId);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Table
              sx={{
                ["&.MuiTable-root .MuiCardHeader-root"]: {
                  paddingLeft: "6px", // or whatever the desired gap.
                  paddingRight: "6x", // or whatever the desired gap.
                },
              }}
              fullWidth
              aria-label="customized table"
            >
              <TableBody>{getValueFromRow()}</TableBody>
            </Table>
          </Grid>
        </Grid>
      </ModalComponent>
    </Box>
  );
}
export default Products;
