export const carrierSettlementEnglish = {
  ACCOUNTS_CARRIER_SETTLEMENT_TEXT: "Carrier Settlement",
  ACCOUNTS_CARRIER_SETTLEMENT_NAME: "Name",
  ACCOUNTS_CARRIER_SETTLEMENT_AMOUNT: "Amount",
  ACCOUNTS_CARRIER_SETTLEMENT_ACTION: "Action",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_REF: "Payment Ref.",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_WEBSITE: "Carrier Website",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_DATE: "Payment Date",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_STATUS: "Payment Status",
  ACCOUNTS_CARRIER_SETTLEMENT_RECEIVE_AMOUNT: "Receive Amount",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_ALL_FILES: "Upload All Files",
  ACCOUNTS_CARRIER_SETTLEMENT_VIEW_ALL_IMAGES: "View All Images",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES: "No Images",
};
export const carrierSettlementArabic = {
  ACCOUNTS_CARRIER_SETTLEMENT_TEXT: "تسوية الناقل",
  ACCOUNTS_CARRIER_SETTLEMENT_NAME: "الاسم",
  ACCOUNTS_CARRIER_SETTLEMENT_AMOUNT: "المبلغ",
  ACCOUNTS_CARRIER_SETTLEMENT_ACTION: "الإجراء",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_REF: "مرجع الدفع",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_WEBSITE: "موقع الناقل",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_DATE: "تاريخ الدفع",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_STATUS: "حالة الدفع",
  ACCOUNTS_CARRIER_SETTLEMENT_RECEIVE_AMOUNT: "مبلغ الاستلام",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_ALL_FILES: "تحميل جميع الملفات",
  ACCOUNTS_CARRIER_SETTLEMENT_VIEW_ALL_IMAGES: "عرض جميع الصور",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES: "لا توجد صور",
};
export const carrierSettlementChines = {
  ACCOUNTS_CARRIER_SETTLEMENT_TEXT: "承运人结算",
  ACCOUNTS_CARRIER_SETTLEMENT_NAME: "名称",
  ACCOUNTS_CARRIER_SETTLEMENT_AMOUNT: "金额",
  ACCOUNTS_CARRIER_SETTLEMENT_ACTION: "操作",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_REF: "付款参考",
  ACCOUNTS_CARRIER_SETTLEMENT_CARRIER_WEBSITE: "承运人网站",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_DATE: "付款日期",
  ACCOUNTS_CARRIER_SETTLEMENT_PAYMENT_STATUS: "付款状态",
  ACCOUNTS_CARRIER_SETTLEMENT_RECEIVE_AMOUNT: "收款金额",
  ACCOUNTS_CARRIER_SETTLEMENT_UPLOAD_ALL_FILES: "上传所有文件",
  ACCOUNTS_CARRIER_SETTLEMENT_VIEW_ALL_IMAGES: "查看所有图片",
  ACCOUNTS_CARRIER_SETTLEMENT_NO_IMAGES: "无图片",
};
