export const paymentIntegrationEnglish = {
  INTEGRATION_PAYMENT_INTEGRATION_TEXT: "Payment Integration",
  INTEGRATION_PAYMENT_INTEGRATION_ALL: "All",
  INTEGRATION_PAYMENT_INTEGRATION_ACTIVE: "Active",
  INTEGRATION_PAYMENT_INTEGRATION_INACTIVE: "Inactive",
  INTEGRATION_PAYMENT_INTEGRATION_PAYMENT_PROCESS_NAME: "Payment process Name",
  INTEGRATION_PAYMENT_INTEGRATION_CREATE_DATE: "Create Date",
  INTEGRATION_PAYMENT_INTEGRATION_STATUS: "Status",
  INTEGRATION_PAYMENT_INTEGRATION_DEFAULT: "Default",
  INTEGRATION_PAYMENT_INTEGRATION_ADD_PAYMENT_CONFIG: "Add Payment Config",
  INTEGRATION_PAYMENT_INTEGRATION_MARK_AS_DEFAULT: "Mark As Default",
  INTEGRATION_PAYMENT_INTEGRATION_SELECT_PAYMENT_PROCESSOR:
    "Select Payment Processor",
};
export const paymentIntegrationArabic = {
  INTEGRATION_PAYMENT_INTEGRATION_TEXT: "تكامل الدفع",
  INTEGRATION_PAYMENT_INTEGRATION_ALL: "الكل",
  INTEGRATION_PAYMENT_INTEGRATION_ACTIVE: "نشط",
  INTEGRATION_PAYMENT_INTEGRATION_INACTIVE: "غير نشط",
  INTEGRATION_PAYMENT_INTEGRATION_PAYMENT_PROCESS_NAME: "اسم عملية الدفع",
  INTEGRATION_PAYMENT_INTEGRATION_CREATE_DATE: "تاريخ الإنشاء",
  INTEGRATION_PAYMENT_INTEGRATION_STATUS: "الحالة",
  INTEGRATION_PAYMENT_INTEGRATION_DEFAULT: "افتراضي",
  INTEGRATION_PAYMENT_INTEGRATION_ADD_PAYMENT_CONFIG: "إضافة إعدادات الدفع",
  INTEGRATION_PAYMENT_INTEGRATION_MARK_AS_DEFAULT: "تحديد كافتراضي",
  INTEGRATION_PAYMENT_INTEGRATION_SELECT_PAYMENT_PROCESSOR: "اختر معالج الدفع",
};

export const paymentIntegrationChines = {
  INTEGRATION_PAYMENT_INTEGRATION_TEXT: "支付集成",
  INTEGRATION_PAYMENT_INTEGRATION_ALL: "全部",
  INTEGRATION_PAYMENT_INTEGRATION_ACTIVE: "活跃",
  INTEGRATION_PAYMENT_INTEGRATION_INACTIVE: "不活跃",
  INTEGRATION_PAYMENT_INTEGRATION_PAYMENT_PROCESS_NAME: "支付流程名称",
  INTEGRATION_PAYMENT_INTEGRATION_CREATE_DATE: "创建日期",
  INTEGRATION_PAYMENT_INTEGRATION_STATUS: "状态",
  INTEGRATION_PAYMENT_INTEGRATION_DEFAULT: "默认",
  INTEGRATION_PAYMENT_INTEGRATION_ADD_PAYMENT_CONFIG: "添加支付配置",
  INTEGRATION_PAYMENT_INTEGRATION_MARK_AS_DEFAULT: "标记为默认",
  INTEGRATION_PAYMENT_INTEGRATION_SELECT_PAYMENT_PROCESSOR: "选择支付处理器",
};
