import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "../../.reUseableComponents/Buttons/ButtonComponent";
import avatarIcon from "../../assets/images/avatar.png";
import saFlag from "../../assets/images/saFlag.png";
import Category from "../../assets/images/topNav/Category.png";
import notificationAlert from "../../assets/images/topNav/notificationAlert.png";
import usFlag from "../../assets/images/us_flag.png";
import cnFlag from "../../assets/images/cnFlag.png";
import { styleSheet } from "../../assets/styles/style";
import { changeSideBarMenu } from "../../redux/changeSideBar";
import { updateLanguage } from "../../redux/language";
import { getThisKeyCookie, setThisKeyCookie } from "../../utilities/cookies";
import {
  EnumEmployeeType,
  EnumRoutesUrls,
  EnumUserType,
} from "../../utilities/enum";
import Colors from "../../utilities/helpers/Colors";
import {
  MenuComponent,
  purple,
  useGetLocationOrPath,
  useIsApiCallingFlagReducer,
  useIsUserProfileChange,
  useIsUserProfileSideBarShow,
  useMenu,
  useUserRoleReducer,
} from "../../utilities/helpers/Helpers";
import { handleLogout } from "../sideNavBar";

export const handleDispatchUserProfile = (dispatch, value, navigate) => {
  dispatch(changeSideBarMenu(value));
  navigate(value ? EnumRoutesUrls.PROFILE : EnumRoutesUrls.ANALYTICS);
  setThisKeyCookie("isUserProfileSideBarShow", value);
};

export const getSelectedTab = (navBarMenu, path) => {
  let selectedTab = null;
  let selectedTabIndex = -1;
  // tab index
  selectedTabIndex = navBarMenu.findIndex(
    (data) =>
      data?.tabData?.path === path ||
      data?.subTabData?.some((rt) => rt.path === path) ||
      data?.otherRoutes?.some((other_rt) => other_rt.path === path)
  );

  selectedTab = selectedTabIndex > -1 ? navBarMenu[selectedTabIndex] : null;

  return { selectedTab, selectedTabIndex };
};

export const languagesOptions = [
  {
    name: "English",
    code: "EN",
    dir: "ltr",
    icon: usFlag,
  },
  {
    name: "Chinese",
    code: "CN",
    dir: "ltr",
    icon: cnFlag,
  },
  {
    name: "Arabic",
    code: "AR",
    dir: "rtl",
    icon: saFlag,
  },
];
function TopNavBar(props) {
  const { navBarMenu } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState({});

  const isUserProfileSideBarShow = useIsUserProfileSideBarShow();
  const isUserProfileChange = useIsUserProfileChange();
  const apiCallingData = useIsApiCallingFlagReducer();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const { path } = useGetLocationOrPath();
  const dispatch = useDispatch();

  // const getTitle = () => {};

  const getTitle = (selectedTab) => {
    let title = "";
    if (selectedTab) {
      const selectedTabData = selectedTab.tabData;
      // for collapse tab
      if (selectedTab.isCollapse) {
        // for tab
        if (selectedTabData.path === path) {
          title = selectedTabData?.topTitle
            ? selectedTabData?.topTitle
            : selectedTabData?.sideTitle;
        } else {
          // for subtab
          const selectedSubTabIndex = selectedTab.subTabData.findIndex(
            (sb_tb) => sb_tb.path === path
          );
          if (selectedSubTabIndex > -1) {
            title = selectedTab.subTabData[selectedSubTabIndex].topTitle
              ? selectedTab.subTabData[selectedSubTabIndex].topTitle
              : selectedTab.subTabData[selectedSubTabIndex].sideTitle;
          } else {
            // for other routes
            const selectedOtherRouteIndex = selectedTab.otherRoutes.findIndex(
              (other_rt) => other_rt.path === path
            );

            if (selectedOtherRouteIndex > -1) {
              title = selectedTab.otherRoutes[selectedOtherRouteIndex].topTitle
                ? selectedTab.otherRoutes[selectedOtherRouteIndex].topTitle
                : selectedTab.otherRoutes[selectedOtherRouteIndex].sideTitle;
            }
          }
        }
      }
      // for not collapse tab
      else {
        title = selectedTabData?.topTitle
          ? selectedTabData?.topTitle
          : selectedTabData?.sideTitle;
      }
    } else {
      title = "Forbidden";
    }
    return { title };
  };
  const {
    anchorEl: profileMenuAnchorEl,
    open: profileMenuOpen,
    handleOpen: handleProfileMenuOpen,
    handleClose: handleProfileMenuClose,
  } = useMenu();
  const userRoleId = useUserRoleReducer();
  // useEffect(() => {
  //   dispatch(updateUserRole(1));
  // }, []);
  const ProfileAvatar = useCallback(() => {
    return (
      <Avatar
        sx={{
          width: 35,
          height: 35,
        }}
        src={getThisKeyCookie("companyImage") || avatarIcon}
      />
    );
  }, [isUserProfileChange]);

  const isSuperAdmin =
    getThisKeyCookie("patronTypeId") == EnumUserType.Admin || userRoleId == 0;

  useEffect(() => {
    const defaultLangaugeObj = languagesOptions[0];
    const localStorageLanguage = localStorage.language;
    const isLanguageExist = languagesOptions.some(
      (lang) => lang.name === localStorageLanguage
    );
    dispatch(
      updateLanguage(
        isLanguageExist ? localStorageLanguage : defaultLangaugeObj.name
      )
    );
    localStorage.setItem(
      "language",
      isLanguageExist ? localStorageLanguage : defaultLangaugeObj.name
    );
    const _selectedLanguage = languagesOptions.find(
      (lang) => lang.name === localStorageLanguage
    );
    setSelectedLanguage(_selectedLanguage || defaultLangaugeObj);
  }, []);
  return (
    <Box sx={styleSheet.topNavBar}>
      <Typography variant="h3">
        {getTitle(getSelectedTab(navBarMenu, path).selectedTab).title}
      </Typography>
      <Stack
        sx={styleSheet.topNavBarRight}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        {Object.values(apiCallingData || {}).map(
          (dt) =>
            dt?.loading && (
              <Box className={"flex_between"} gap={1}>
                <CircularProgress size={14} />
                <Typography variant="h3" color={purple} fontWeight={300}>
                  {`${dt?.msg}...`}
                </Typography>
              </Box>
            )
        )}
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Stack
            sx={styleSheet.languageDropdownBox}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <img style={{ width: "23px" }} src={selectedLanguage?.icon} />
            <Box sx={styleSheet.languageDropdownTitle}>
              {selectedLanguage?.code}
            </Box>
          </Stack>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="power-search-menu"
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box sx={{ width: "160px" }}>
            <List disablePadding>
              {languagesOptions.map((lang) => (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setAnchorEl(null);
                      dispatch(updateLanguage(lang.name));
                      localStorage.setItem("language", lang.name);
                      setSelectedLanguage(lang);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <img
                        style={{ width: "23px" }}
                        src={lang.icon}
                        alt="flag icon"
                      />
                    </ListItemIcon>
                    <ListItemText primary={lang.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Menu>
        {/* <IconButton>
          <img src={SearchIcon} style={{ width: "18px" }} alt="SearchIcon" />
        </IconButton> */}
        <IconButton>
          {" "}
          <img
            style={{ width: "23px" }}
            src={notificationAlert}
            alt="notificationAlert"
          />
        </IconButton>
        <IconButton>
          {" "}
          <img style={{ width: "18px" }} src={Category} alt="Category" />
        </IconButton>
        <IconButton onClick={handleProfileMenuOpen}>
          <ProfileAvatar />
          {/* <Avatar
            sx={{
              width: 35,
              height: 35,
            }}
            src={getThisKeyCookie("companyImage") || avatarIcon}
          /> */}
        </IconButton>
        <MenuComponent
          anchorEl={profileMenuAnchorEl}
          open={profileMenuOpen}
          onClose={handleProfileMenuClose}
        >
          <Box p={1.25} minWidth={300}>
            <Box
              className={"flex_center"}
              justifyContent={"start !important"}
              gap={1}
            >
              <Box>
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                  src={getThisKeyCookie("companyImage") || avatarIcon}
                />
              </Box>
              <Box>
                <Typography variant="h5">
                  {getThisKeyCookie("user_name")}
                </Typography>
                <Typography variant="h6" fontSize={13}>
                  {" "}
                  {getThisKeyCookie("email")}
                </Typography>
              </Box>
            </Box>

            <Box className={"flex_between"} mt={1}>
              {isUserProfileSideBarShow && isSuperAdmin ? (
                <ButtonComponent
                  title={"Back to App"}
                  onClick={() => {
                    handleDispatchUserProfile(dispatch, false, navigate);
                    handleProfileMenuClose();
                  }}
                />
              ) : (
                isSuperAdmin && (
                  <ButtonComponent
                    title={"Setting"}
                    onClick={() => {
                      handleDispatchUserProfile(dispatch, true, navigate);
                      handleProfileMenuClose();
                    }}
                  />
                )
              )}
              <ButtonComponent
                title={"Log Out"}
                bg={Colors.danger}
                onClick={() => handleLogout(navigate, LanguageReducer)}
              />
            </Box>
          </Box>
        </MenuComponent>
      </Stack>
    </Box>
  );
}
export default TopNavBar;
