export const saleChannelOrderEnglish = {
  ORDERS_SALE_CHANNEL_ORDERS_S_TEXT: "Sale Channel Order(s)",
  ORDERS_SALE_CHANNEL_ORDERS_TEXT: "Sale Channel Orders",
  ORDERS_SALE_CHANNEL_ORDERS_NO: "Order No",
  ORDERS_SALE_CHANNEL_ORDER_TYPE: "Order Type",
  ORDERS_SALE_CHANNEL_ORDERS_STORE: "Store",
  ORDERS_SALE_CHANNEL_ORDERS_NAME: "Name",
  ORDERS_SALE_CHANNEL_ORDERS_ADDRESS: "Address",
  ORDERS_SALE_CHANNEL_ORDERS_PHONE: "Phone",
  ORDERS_SALE_CHANNEL_ORDERS_ORDER_DATE: "Order Date",
  ORDERS_SALE_CHANNEL_ORDERS_WEIGHT: "Weight",
  ORDERS_SALE_CHANNEL_ORDERS_VAT: "VAT",
  ORDERS_SALE_CHANNEL_ORDERS_AMOUNT: "Amount",
  ORDERS_SALE_CHANNEL_ORDERS_SYNC_ORDER: "Sync Order",
  ORDERS_SALE_CHANNEL_ORDERS_FROM_DATE: "From Date",
  ORDERS_SALE_CHANNEL_ORDERS_TO_DATE: "To Date",
  ORDERS_SALE_CHANNEL_ORDERS_SELECT_STORE: "Select Store",
  ORDERS_SALE_CHANNEL_ORDERS_SELECT_PLEASE: "Select Please",
  ORDERS_SALE_CHANNEL_ORDERS_ACTION: "Action",
  ORDERS_SALE_CHANNEL_ORDERS_UPLOAD_ORDER: "Upload Order",
};
export const saleChannelOrderArabic = {
  ORDERS_SALE_CHANNEL_ORDERS_S_TEXT: "Sale Channel Order(s)",
  ORDERS_SALE_CHANNEL_ORDERS_TEXT: "Sale Channel Orders",
  ORDERS_SALE_CHANNEL_ORDERS_ACTION: "إجراء",
  ORDERS_SALE_CHANNEL_ORDERS_NO: "رقم الطلب ",
  ORDERS_SALE_CHANNEL_ORDER_TYPE: " نوع الطلب",
  ORDERS_SALE_CHANNEL_ORDERS_STORE: "المتجر",
  ORDERS_SALE_CHANNEL_ORDERS_NAME: "الاسم",
  ORDERS_SALE_CHANNEL_ORDERS_ADDRESS: "العنوان",
  ORDERS_SALE_CHANNEL_ORDERS_PHONE: "الهاتف",
  ORDERS_SALE_CHANNEL_ORDERS_ORDER_DATE: "تاريخ الطلب",
  ORDERS_SALE_CHANNEL_ORDERS_WEIGHT: "الوزن",
  ORDERS_SALE_CHANNEL_ORDERS_VAT: "ضريبة القيمة المضافة (VAT)",
  ORDERS_SALE_CHANNEL_ORDERS_AMOUNT: "المبلغ",
  ORDERS_SALE_CHANNEL_ORDERS_SYNC_ORDER: "مزامنة الطلب",
  ORDERS_SALE_CHANNEL_ORDERS_FROM_DATE: "من التاريخ",
  ORDERS_SALE_CHANNEL_ORDERS_TO_DATE: "إلى التاريخ",
  ORDERS_SALE_CHANNEL_ORDERS_SELECT_STORE: "اختر المتجر",
  ORDERS_SALE_CHANNEL_ORDERS_SELECT_PLEASE: "يرجى التحديد",
  ORDERS_SALE_CHANNEL_ORDERS_UPLOAD_ORDER: "رفع الطلب",
};
export const saleChannelOrderChines = {
  ORDERS_SALE_CHANNEL_ORDERS_S_TEXT: "Sale Channel Order(s)",
  ORDERS_SALE_CHANNEL_ORDERS_TEXT: "Sale Channel Orders",
  ORDERS_SALE_CHANNEL_ORDERS_ACTION: "操作",
  ORDERS_SALE_CHANNEL_ORDERS_NO: "订单类型",
  ORDERS_SALE_CHANNEL_ORDER_TYPE: "订单号",
  ORDERS_SALE_CHANNEL_ORDERS_STORE: "店铺",
  ORDERS_SALE_CHANNEL_ORDERS_NAME: "名称",
  ORDERS_SALE_CHANNEL_ORDERS_ADDRESS: "地址",
  ORDERS_SALE_CHANNEL_ORDERS_PHONE: "电话",
  ORDERS_SALE_CHANNEL_ORDERS_ORDER_DATE: "订单日期",
  ORDERS_SALE_CHANNEL_ORDERS_WEIGHT: "重量",
  ORDERS_SALE_CHANNEL_ORDERS_VAT: "增值税",
  ORDERS_SALE_CHANNEL_ORDERS_AMOUNT: "金额",
  ORDERS_SALE_CHANNEL_ORDERS_SYNC_ORDER: "同步订单",
  ORDERS_SALE_CHANNEL_ORDERS_FROM_DATE: "开始日期",
  ORDERS_SALE_CHANNEL_ORDERS_TO_DATE: "结束日期",
  ORDERS_SALE_CHANNEL_ORDERS_SELECT_STORE: "选择店铺",
  ORDERS_SALE_CHANNEL_ORDERS_SELECT_PLEASE: "请选择",
  ORDERS_SALE_CHANNEL_ORDERS_UPLOAD_ORDER: "上传订单",
};
