export const profileEnglish = {
  SETTING_PROFILE_TEXT: "Profile",
  SETTING_PROFILE_PERSONAL_INFORMATION: "Personal Information",
  SETTING_PROFILE_NAME: "Name",
  SETTING_PROFILE_COMPANY_NAME: "Company Name (Optional)",
  SETTING_PROFILE_MOBILE_NO: "Mobile No.",
  SETTING_PROFILE_PHONE_NO: "Phone No. (Optional)",
  SETTING_PROFILE_COUNTRY: "Country",
  SETTING_PROFILE_REGION_CITY: "Region/City",
  SETTING_PROFILE_CITY: "City",
  SETTING_PROFILE_APARTMENT_SUITE_ETC: "Apartment/Suite/Etc",
  SETTING_PROFILE_REGION_TIME_ZONE: "Region Time Zone",
  SETTING_PROFILE_UPDATE: "Update",
  SETTING_PROFILE_USERNAME: "Username",
  SETTING_PROFILE_EMAIL_ADDRESS: "Email Address",
};
export const profileArabic = {
  SETTING_PROFILE_TEXT: "الملف الشخصي",
  SETTING_PROFILE_PERSONAL_INFORMATION: "معلومات شخصية",
  SETTING_PROFILE_NAME: "الاسم",
  SETTING_PROFILE_COMPANY_NAME: "اسم الشركة (اختياري)",
  SETTING_PROFILE_MOBILE_NO: "رقم الجوال",
  SETTING_PROFILE_PHONE_NO: "رقم الهاتف (اختياري)",
  SETTING_PROFILE_COUNTRY: "الدولة",
  SETTING_PROFILE_REGION_CITY: "المنطقة/المدينة",
  SETTING_PROFILE_CITY: "مدينة",
  SETTING_PROFILE_APARTMENT_SUITE_ETC: "شقة/جناح/إلخ",
  SETTING_PROFILE_REGION_TIME_ZONE: "المنطقة الزمنية",
  SETTING_PROFILE_UPDATE: "تحديث",
  SETTING_PROFILE_USERNAME: "اسم المستخدم",
  SETTING_PROFILE_EMAIL_ADDRESS: "عنوان البريد الإلكتروني",
};
export const profileChines = {
  SETTING_PROFILE_TEXT: "个人资料",
  SETTING_PROFILE_PERSONAL_INFORMATION: "个人信息",
  SETTING_PROFILE_NAME: "名称",
  SETTING_PROFILE_COMPANY_NAME: "公司名称（可选）",
  SETTING_PROFILE_MOBILE_NO: "手机号码",
  SETTING_PROFILE_PHONE_NO: "电话号码(可选)",
  SETTING_PROFILE_COUNTRY: "国家",
  SETTING_PROFILE_REGION_CITY: "地区/城市",
  SETTING_PROFILE_CITY: "城市",
  SETTING_PROFILE_APARTMENT_SUITE_ETC: "公寓/套房/等",
  SETTING_PROFILE_REGION_TIME_ZONE: "区域时区",
  SETTING_PROFILE_UPDATE: "更新",
  SETTING_PROFILE_USERNAME: "用户名",
  SETTING_PROFILE_EMAIL_ADDRESS: "电子邮件地址",
};
