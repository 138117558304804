import {
  inventoryArabic,
  inventoryChines,
  inventoryEnglish,
} from "./inventory/inventory";
import {
  inventorySaleArabic,
  inventorySaleChines,
  inventorySaleEnglish,
} from "./inventorySale/inventorySale";
import {
  lowInventoryEnglish,
  lowInventoryArabic,
  lowInventoryChines,
} from "./lowInventory/lowInventory";
import {
  ProductStationArabic,
  ProductStationChines,
  ProductStationEnglish,
} from "./productStation/productStation";
import {
  SaleChannelProductArabic,
  SaleChannelProductChines,
  SaleChannelProductEnglish,
} from "./saleChannelProduct/saleChannelProduct";

export const productsEnglish = {
  ...inventoryEnglish,
  ...lowInventoryEnglish,
  ...inventorySaleEnglish,
  ...SaleChannelProductEnglish,
  ...ProductStationEnglish,
  PRODUCTS_TEXT: "Products",
  PRODUCTS_TEXT_S: "Product(s)",
  PRODUCT_ALL: "All",
  PRODUCT_ACTIVE: "Active",
  PRODUCT_IN_ACTIVE: "In Active",
  PRODUCTS_ADD_PRODUCTS: "Add Products",
  PRODUCTS_SKU: "SKU",
  PRODUCTS_CATEGORY_NAME: "Category Name",
  PRODUCTS_STORE_NAME: "Store Name",
  PRODUCTS_QTY_AVAILABLE: "Qty Available",
  PRODUCTS_WEIGHT: "Weight",
  PRODUCTS_PURCHASE_PRICE: "Purchase Price",
  PRODUCTS_SALE_PRICE: "Sale Price",
  PRODUCTS_STATUS: "Status",
  PRODUCTS_ACTION: "Action",
  PRODUCTS_PRODUCT_DETAIL: "Product Detail",
  PRODUCTS_STORE: "Store",
  PRODUCTS_TITLE: "Title",
  PRODUCTS_DESCRIPTION: "Description",
  PRODUCTS_CATEGORY: "Category",
  PRODUCTS_WEIGHT: "Weight",
  PRODUCTS_SALE_PRICE: "Sale Price",
  PRODUCTS_FEATURE_IMAGE: "Feature Image",
  PRODUCTS_INVENTORY: "Inventory",
  PRODUCTS_TRACK_INVENTORY: "Track Inventory",
  PRODUCTS_AUTO_GENERATE_SKU: "Auto Generate SKU",
  PRODUCTS_DUBAI: "Dubai",
  PRODUCTS_TEST: "Test",
  PRODUCTS_HII: "Hii",
  PRODUCTS_HOW: "How",
  PRODUCTS_STAGE: "Stage (Default)",
  PRODUCTS_RTERTERTGETGEE: "Rtertertgetgee",
  PRODUCTS_QUANTITY: "Quantity",
  PRODUCTS_LOW_QUANTITY_AMOUNT: "Low Quantity Amount",
  PRODUCTS_PRODUCT_HAS_OPTIONS: "This product has options.",
  PRODUCTS_CREATE_PRODUCT: "Create Product",
  PRODUCTS_START_DATE: "Start Date",
  PRODUCTS_END_DATE: "End Date",
  PRODUCTS_SELECT_PLEASE: "Select Please",
  PRODUCTS_CLEAR_FILTER: "Clear Filter",
  PRODUCT_FILTER: "Filter",
};
export const productsArabic = {
  ...inventoryArabic,
  ...lowInventoryArabic,
  ...inventorySaleArabic,
  ...SaleChannelProductArabic,
  ...ProductStationArabic,
  PRODUCTS_TEXT: "منتجات",
  PRODUCTS_TEXT_S: "منتجات",
  PRODUCT_ALL: "الجميع",
  PRODUCT_ACTIVE: "نشيط",
  PRODUCT_IN_ACTIVE: "غير نشط",
  PRODUCTS_ADD_PRODUCTS: "إضافة منتجات",
  PRODUCTS_SKU: "إس كيو يو",
  PRODUCTS_CATEGORY_NAME: "اسم الفئة",
  PRODUCTS_STORE_NAME: "اسم المتجر",
  PRODUCTS_QTY_AVAILABLE: "الكمية المتاحة",
  PRODUCTS_WEIGHT: "الوزن",
  PRODUCTS_PURCHASE_PRICE: "سعر الشراء",
  PRODUCTS_SALE_PRICE: "سعر البيع",
  PRODUCTS_STATUS: "الحالة",
  PRODUCTS_ACTION: "الإجراء",
  PRODUCTS_PRODUCT_DETAIL: "تفاصيل المنتج",
  PRODUCTS_STORE: "المتجر",
  PRODUCTS_TITLE: "العنوان",
  PRODUCTS_DESCRIPTION: "الوصف",
  PRODUCTS_CATEGORY: "الفئة",
  PRODUCTS_WEIGHT: "الوزن",
  PRODUCTS_SALE_PRICE: "سعر البيع",
  PRODUCTS_PURCHASE_PRICE: "سعر الشراء",
  PRODUCTS_FEATURE_IMAGE: "الصورة المميزة",
  PRODUCTS_INVENTORY: "المخزون",
  PRODUCTS_TRACK_INVENTORY: "تتبع المخزون",
  PRODUCTS_AUTO_GENERATE_SKU: "توليد إس كيو يو تلقائيًا",
  PRODUCTS_DUBAI: "دبي",
  PRODUCTS_TEST: "اختبار",
  PRODUCTS_HII: "مرحبًا",
  PRODUCTS_HOW: "كيف",
  PRODUCTS_STAGE: "المرحلة (افتراضي)",
  PRODUCTS_RTERTERTGETGEE: "Rtertertgetgee",
  PRODUCTS_QUANTITY: "الكمية",
  PRODUCTS_LOW_QUANTITY_AMOUNT: "كمية منخفضة",
  PRODUCTS_PRODUCT_HAS_OPTIONS: "هذا المنتج له خيارات.",
  PRODUCTS_CREATE_PRODUCT: "إنشاء منتج",
  PRODUCTS_START_DATE: "تاريخ البدء",
  PRODUCTS_END_DATE: "تاريخ الانتهاء",
  PRODUCTS_SELECT_PLEASE: "يرجى التحديد",
  PRODUCTS_CLEAR_FILTER: "مسح الفلتر",
  PRODUCT_FILTER: "منقي",
};
export const productsChinese = {
  ...inventoryChines,
  ...lowInventoryChines,
  ...inventorySaleChines,
  ...SaleChannelProductChines,
  ...ProductStationChines,
  PRODUCTS_TEXT: "产品",
  PRODUCTS_TEXT_S: "产品",
  PRODUCT_ALL: "全部",
  PRODUCT_ACTIVE: "积极的",
  PRODUCT_IN_ACTIVE: "活跃中",
  PRODUCTS_ADD_PRODUCTS: "添加产品",
  PRODUCTS_SKU: "SKU(库存单位)",
  PRODUCTS_CATEGORY_NAME: "类别名称",
  PRODUCTS_STORE_NAME: "店铺名称",
  PRODUCTS_QTY_AVAILABLE: "可用数量",
  PRODUCTS_WEIGHT: "重量",
  PRODUCTS_PURCHASE_PRICE: "采购价格",
  PRODUCTS_SALE_PRICE: "销售价格",
  PRODUCTS_STATUS: "状态",
  PRODUCTS_ACTION: "操作",
  PRODUCTS_PRODUCT_DETAIL: "产品详情",
  PRODUCTS_STORE: "店铺",
  PRODUCTS_TITLE: "标题",
  PRODUCTS_DESCRIPTION: "描述",
  PRODUCTS_CATEGORY: "类别",
  PRODUCTS_WEIGHT: "重量",
  PRODUCTS_SALE_PRICE: "销售价格",
  PRODUCTS_PURCHASE_PRICE: "采购价格",
  PRODUCTS_FEATURE_IMAGE: "特色图像",
  PRODUCTS_INVENTORY: "库存",
  PRODUCTS_TRACK_INVENTORY: "跟踪库存",
  PRODUCTS_AUTO_GENERATE_SKU: "自动生成SKU",
  PRODUCTS_DUBAI: "迪拜",
  PRODUCTS_TEST: "测试",
  PRODUCTS_HII: "嗨",
  PRODUCTS_HOW: "如何",
  PRODUCTS_STAGE: "阶段（默认）",
  PRODUCTS_RTERTERTGETGEE: "Rtertertgetgee",
  PRODUCTS_QUANTITY: "数量",
  PRODUCTS_LOW_QUANTITY_AMOUNT: "低库存数量",
  PRODUCTS_PRODUCT_HAS_OPTIONS: "此产品有选项。",
  PRODUCTS_CREATE_PRODUCT: "创建产品",
  PRODUCTS_START_DATE: "开始日期",
  PRODUCTS_END_DATE: "结束日期",
  PRODUCTS_SELECT_PLEASE: "请选择",
  PRODUCTS_CLEAR_FILTER: "清除筛选",
  PRODUCT_FILTER: "筛选",
};
