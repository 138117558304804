export const ApiIntegrationEnglish = {
  INTEGRATION_API_INTEGRATION_TEXT: "Api Integration",
  INTEGRATION_API_INTEGRATION_LOGIN: "Login",
  INTEGRATION_API_INTEGRATION_DOWNLOAD_POSTMAN: "Download Postman",
  INTEGRATION_API_INTEGRATION_POST: "POST",
  INTEGRATION_API_INTEGRATION_AUTHORIZATION: "Authorization",
  INTEGRATION_API_INTEGRATION_BEARER_TOKEN: "Bearer Token",
  INTEGRATION_API_INTEGRATION_TOKEN: "Token",
  INTEGRATION_API_INTEGRATION_TOKEN_PLACEHOLDER: "<token>",
  INTEGRATION_API_INTEGRATION_FORMAT: "Format",
  INTEGRATION_API_INTEGRATION_USERNAME: "userName",
  INTEGRATION_API_INTEGRATION_STRING: "String",
  INTEGRATION_API_INTEGRATION_REQUIRED: "Required",
  INTEGRATION_API_INTEGRATION_USERNAME_NOTE: "On login userName is required.",
  INTEGRATION_API_INTEGRATION_BODY: "Body",
  INTEGRATION_API_INTEGRATION_RAW_JSON: "raw(json)",
  INTEGRATION_API_INTEGRATION_RESPONSE: "Response",
  INTEGRATION_API_INTEGRATION_RAW_JSON_RESPONSE: "raw(json)",
  INTEGRATION_API_INTEGRATION_PASSWORD: "Password",
  INTEGRATION_API_INTEGRATION_PASSWORD_STRING: "String",
  INTEGRATION_API_INTEGRATION_PASSWORD_REQUIRED: "Required",
  INTEGRATION_API_INTEGRATION_PASSWORD_NOTE: "On login password is required.",
  INTEGRATION_API_INTEGRATION_SHOW_MORE: "Show more",
  INTEGRATION_API_INTEGRATION_REFRESH_TOKEN: "Refresh Token",
  INTEGRATION_API_INTEGRATION_CREATE_ORDER: "Create Order",
  INTEGRATION_API_INTEGRATION_ORDER_TRACKING: "Order Tracking",
};
export const ApiIntegrationArabic = {
  INTEGRATION_API_INTEGRATION_TEXT: "Api Integration",
  INTEGRATION_API_INTEGRATION_LOGIN: "تسجيل الدخول",
  INTEGRATION_API_INTEGRATION_DOWNLOAD_POSTMAN: "تحميل برنامج Postman",
  INTEGRATION_API_INTEGRATION_POST: "POST",
  INTEGRATION_API_INTEGRATION_AUTHORIZATION: "الترخيص",
  INTEGRATION_API_INTEGRATION_BEARER_TOKEN: "Bearer Token",
  INTEGRATION_API_INTEGRATION_TOKEN: "Token",
  INTEGRATION_API_INTEGRATION_TOKEN_PLACEHOLDER: "<token>",
  INTEGRATION_API_INTEGRATION_FORMAT: "التنسيق",
  INTEGRATION_API_INTEGRATION_USERNAME: "اسم المستخدم",
  INTEGRATION_API_INTEGRATION_STRING: "سلسلة",
  INTEGRATION_API_INTEGRATION_REQUIRED: "مطلوب",
  INTEGRATION_API_INTEGRATION_USERNAME_NOTE: "عند تسجيل الدخول يجب إدخال اسم المستخدم.",
  INTEGRATION_API_INTEGRATION_BODY: "الجسم",
  INTEGRATION_API_INTEGRATION_RAW_JSON: "نص (JSON)",
  INTEGRATION_API_INTEGRATION_RESPONSE: "الاستجابة",
  INTEGRATION_API_INTEGRATION_RAW_JSON_RESPONSE: "نص (JSON)",
  INTEGRATION_API_INTEGRATION_PASSWORD: "كلمة المرور",
  INTEGRATION_API_INTEGRATION_PASSWORD_STRING: "سلسلة",
  INTEGRATION_API_INTEGRATION_PASSWORD_REQUIRED: "مطلوب",
  INTEGRATION_API_INTEGRATION_PASSWORD_NOTE: "عند تسجيل الدخول يجب إدخال كلمة المرور.",
  INTEGRATION_API_INTEGRATION_SHOW_MORE: "عرض المزيد",
  INTEGRATION_API_INTEGRATION_REFRESH_TOKEN: "تحديث الرمز المميز",
  INTEGRATION_API_INTEGRATION_CREATE_ORDER: "إنشاء طلب",
  INTEGRATION_API_INTEGRATION_ORDER_TRACKING: "تتبع الطلب",
};
export const ApiIntegrationChines = {
  INTEGRATION_API_INTEGRATION_TEXT: "Api Integration",
  INTEGRATION_API_INTEGRATION_LOGIN: "登录",
  INTEGRATION_API_INTEGRATION_DOWNLOAD_POSTMAN: "下载 Postman",
  INTEGRATION_API_INTEGRATION_POST: "POST",
  INTEGRATION_API_INTEGRATION_AUTHORIZATION: "授权",
  INTEGRATION_API_INTEGRATION_BEARER_TOKEN: "Bearer Token",
  INTEGRATION_API_INTEGRATION_TOKEN: "Token",
  INTEGRATION_API_INTEGRATION_TOKEN_PLACEHOLDER: "<token>",
  INTEGRATION_API_INTEGRATION_FORMAT: "格式",
  INTEGRATION_API_INTEGRATION_USERNAME: "用户名",
  INTEGRATION_API_INTEGRATION_STRING: "字符串",
  INTEGRATION_API_INTEGRATION_REQUIRED: "必填",
  INTEGRATION_API_INTEGRATION_USERNAME_NOTE: "登录时需要用户名。",
  INTEGRATION_API_INTEGRATION_BODY: "请求体",
  INTEGRATION_API_INTEGRATION_RAW_JSON: "原始 JSON",
  INTEGRATION_API_INTEGRATION_RESPONSE: "响应",
  INTEGRATION_API_INTEGRATION_RAW_JSON_RESPONSE: "原始 JSON",
  INTEGRATION_API_INTEGRATION_PASSWORD: "密码",
  INTEGRATION_API_INTEGRATION_PASSWORD_STRING: "字符串",
  INTEGRATION_API_INTEGRATION_PASSWORD_REQUIRED: "必填",
  INTEGRATION_API_INTEGRATION_PASSWORD_NOTE: "登录时需要密码。",
  INTEGRATION_API_INTEGRATION_SHOW_MORE: "显示更多",
  INTEGRATION_API_INTEGRATION_REFRESH_TOKEN: "刷新 Token",
  INTEGRATION_API_INTEGRATION_CREATE_ORDER: "创建订单",
  INTEGRATION_API_INTEGRATION_ORDER_TRACKING: "订单跟踪",
};