import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { React, useEffect, useRef, useState } from "react";
import CustomReactDatePickerInputFilter from "../../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import { GetAllInvoiceHistory } from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import GeneralTabBar from "../../../components/shared/tabsBar";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  fetchMethod,
  GridContainer,
  GridItem,
} from "../../../utilities/helpers/Helpers";
import BillingHistoryList from "./list";
import { useSelector } from "react-redux";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";
const BillingHistory = (props) => {
  const { startDate, endDate, setStartDate, setEndDate, resetDates } =
    useDateRangeHook();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [invoiceHistory, setInvoiceHistory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const resetRowRef = useRef(false);
  const getOrdersRef = useRef([]);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);

  const getAllInvoiceHistory = async () => {
    setIsLoading(true); // Start loading
    const { response } = await fetchMethod(() => GetAllInvoiceHistory());
    if (response?.isSuccess) {
      setInvoiceHistory(response?.result?.list);
    }
    setIsLoading(false); // End loading
  };

  const handleFilterRest = () => {
    resetDates();
  };

  useEffect(() => {
    getAllInvoiceHistory();
  }, []);

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} md={6}>
        <Box style={{ marginBottom: "20px", marginTop: "10px" }}>
          <Typography>
            <h2>Billing</h2>
          </Typography>
          <Typography>
            <p>Manage your invoice, monthly spend and payment methods.</p>
          </Typography>
        </Box>
        <Card
          style={{
            marginTop: "10px",
            borderRadius: "5px",
          }}
        >
          <CardHeader
            title="Starter Plan"
            subheader="Your plan renews on 26 July 2024."
            titleTypographyProps={{ align: "start", variant: "h6" }}
            subheaderTypographyProps={{ align: "start" }}
            style={{ backgroundColor: "#fff", textAlign: "start" }}
          />
          <Divider />
          <CardContent style={{ backgroundColor: "rgb(54 158 241 / 8%)" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="h6">Starter Plan</Typography>
                <Typography variant="body2" color="textSecondary">
                  Base price for 500 stops
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">
                  $100
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">This month's spend</Typography>
              <Typography variant="body2" color="textSecondary">
                $100
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: "10px" }}
            >
              Usage data can be delayed up to 6 hours.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions style={{ justifyContent: "space-between" }}>
            <Typography variant="caption" color="textSecondary">
              Visa ...8888 will be charged upon renewal.
            </Typography>
            <Button variant="outlined">Cancel Plan</Button>
          </CardActions>
        </Card>
        <Card
          style={{
            marginTop: "10px",
            borderRadius: "5px",
          }}
        >
          <CardHeader
            title="Payment Methods"
            titleTypographyProps={{ align: "start", variant: "h6" }}
            style={{ backgroundColor: "#fff", textAlign: "start" }}
          />
          <Divider />
          <CardContent>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">Visa ...8888</Typography>
              <Typography variant="body2" color="textSecondary">
                Expires 02/2027
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <MoreHorizIcon />
              </Typography>
            </Box>
          </CardContent>
          <Divider />
          <CardActions
            style={{ justifyContent: "space-between", padding: "16px" }}
          >
            <Box style={{ display: "flex", gap: "8px" }}>
              <Button variant="text">
                <AddIcon /> <b>Add new payment method</b>
              </Button>
            </Box>
          </CardActions>
        </Card>
        <Card
          style={{
            marginTop: "10px",
            borderRadius: "5px",
          }}
        >
          <CardHeader
            title="Billing Information"
            titleTypographyProps={{ align: "start", variant: "h6" }}
            style={{ backgroundColor: "#fff", textAlign: "start" }}
          />
          <Divider />
          <CardContent>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginBottom: "20px" }}
                >
                  Email: imhere2333@gmail.com
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Phone number: +971 56 397 8893
                </Typography>
              </Box>
              <Box style={{ marginTop: "16px" }}>
                <EditIcon />
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Card
          style={{
            marginTop: "10px",
            borderRadius: "5px",
          }}
        >
          <CardHeader
            title="Invoice history"
            titleTypographyProps={{ align: "start", variant: "h4" }}
            style={{
              backgroundColor: "#fff",
              textAlign: "start",
            }}
          />
          <Divider />
          <Box sx={styleSheet.pageRoot}>
            <div style={{ paddingTop: "10px" }}>
              {" "}
              <GeneralTabBar
                tabScreen={"Billing"}
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
                disableSearch
                {...props}
              />
              {isFilterOpen ? (
                <Table
                  sx={{ ...styleSheet.generalFilterArea }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <Grid container spacing={2} sx={{ p: "15px 10px" }}>
                        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                          <Grid>
                            <InputLabel
                              sx={{
                                ...styleSheet.inputLabel,
                                overflow: "unset",
                              }}
                            >
                              {
                                LanguageReducer?.languageType
                                  ?.PRODUCTS_START_DATE
                              }
                            </InputLabel>

                            <CustomReactDatePickerInputFilter
                              value={startDate}
                              maxDate={UtilityClass.todayDate()}
                              onClick={(date) => setStartDate(date)}
                              size="small"
                              isClearable

                              // inputProps={{ style: { padding: "2px 5px" } }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                          <Grid>
                            <InputLabel
                              sx={{
                                ...styleSheet.inputLabel,
                                overflow: "unset",
                              }}
                            >
                              {LanguageReducer?.languageType?.PRODUCTS_END_DATE}
                            </InputLabel>
                            <CustomReactDatePickerInputFilter
                              value={endDate}
                              onClick={(date) => setEndDate(date)}
                              size="small"
                              minDate={startDate}
                              maxDate={UtilityClass.todayDate()}
                              disabled={!startDate ? true : false}
                              isClearable
                              // inputProps={{ style: { padding: "2px 5px" } }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                          <Stack
                            alignItems="flex-end"
                            direction="row"
                            spacing={1}
                            sx={{
                              ...styleSheet.filterButtonMargin,
                              height: "100%",
                            }}
                          >
                            <Button
                              sx={{
                                ...styleSheet.filterIcon,
                                minWidth: "100px",
                              }}
                              color="inherit"
                              variant="outlined"
                              onClick={() => {
                                handleFilterRest();
                              }}
                            >
                              {
                                LanguageReducer?.languageType
                                  ?.PRODUCTS_CLEAR_FILTER
                              }
                            </Button>
                            <Button
                              sx={{
                                ...styleSheet.filterIcon,
                                minWidth: "100px",
                              }}
                              variant="contained"
                              onClick={() => {}}
                            >
                              {LanguageReducer?.languageType?.PRODUCT_FILTER}
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </TableRow>
                  </TableHead>
                </Table>
              ) : null}
            </div>
            {/* </Container> */}
          </Box>
          <CardContent sx={{ padding: "0px", paddingBottom: "20px" }}>
            <BillingHistoryList
              invoiceHistory={invoiceHistory || []}
              getOrdersRef={getOrdersRef}
              resetRowRef={resetRowRef}
              isLoading={isLoading}
            />
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default BillingHistory;
