import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";

import React from "react";

export default function TextFieldWithInfoInputAdornment(props) {
  const {
    name,
    value,
    onChange,
    type = "text",
    required = true,
    borderRadius = "10px",
    inputAdornment,
    infoText = "",
    ...others
  } = props;

  return (
    <>
      <Stack sx={{ position: "relative" }} direction={"row"}>
        <TextField
          //   inputClass={error?.message && "error-border"}
          value={value}
          onChange={onChange}
          defaultCountry="ae"
          id={name}
          specialLabel=""
          defaultErrorMessage=""
          prefix="+"
          country={"ae"}
          {...props}
        />
        <Box sx={{ position: "absolute", right: 27, top: 18 }}>
          <Tooltip title={infoText}>
            <InputAdornment position="end">
              <ErrorOutlineIcon
                fontSize="small"
                sx={{ color: "black", cursor: "pointer" }}
              />
            </InputAdornment>
          </Tooltip>
        </Box>
      </Stack>
    </>
  );
}
