export const com2_7Days = [
  {
    Date: "2024-01-01T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 10,
  },
  {
    Date: "2024-01-02T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-01-03T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-04T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-05T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-06T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-01-07T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
];
export const com1_7Days = [
  {
    Date: "2024-01-01T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-02T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-03T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-04T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 70,
  },
  {
    Date: "2024-01-05T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-01-06T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-01-07T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
];
export const com1_18Days = [
  {
    Date: "2024-01-01T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-02T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-03T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-04T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 70,
  },
  {
    Date: "2024-01-05T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-01-06T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-01-07T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-08T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-09T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-01-10T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-11T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-12T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-01-13T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-01-14T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 70,
  },
  {
    Date: "2024-01-15T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-16T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-17T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-18T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
];
export const com2_18Days = [
  {
    Date: "2024-01-01T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 10,
  },
  {
    Date: "2024-01-02T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-01-03T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-04T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-05T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-06T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-01-07T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-08T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-09T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-01-10T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 10,
  },
  {
    Date: "2024-01-11T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-12T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-13T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-14T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-15T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-16T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-01-17T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-18T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
];
export const comp1_60Days = [
  {
    Date: "2024-01-01T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-02T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-03T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-04T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 70,
  },
  {
    Date: "2024-01-05T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-01-06T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-01-07T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-08T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-09T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-01-10T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-11T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-12T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-01-13T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-01-14T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 70,
  },
  {
    Date: "2024-01-15T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-16T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-17T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-18T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-02-01T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-02-02T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-02-03T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-02-04T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 70,
  },
  {
    Date: "2024-02-05T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-02-06T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-02-07T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-02-08T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-09T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-02-10T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-11T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-02-12T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-02-13T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-02-14T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 70,
  },
  {
    Date: "2024-02-15T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-02-16T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-02-17T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-02-18T00:00:00",
    CarrierId: 10009,
    CarrierName: "Quil",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
];

export const comp2_60Days = [
  {
    Date: "2024-01-01T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 10,
  },
  {
    Date: "2024-01-02T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-01-03T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-04T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-05T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-06T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-01-07T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-08T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-09T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-01-10T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 10,
  },
  {
    Date: "2024-01-11T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-12T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-13T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-01-14T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-01-15T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-01-16T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-01-17T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-01-18T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-19T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-20T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-21T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-22T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-23T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-24T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-25T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-26T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-27T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-28T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-29T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-01-30T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-01T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 10,
  },
  {
    Date: "2024-02-02T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-02-03T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-02-04T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-05T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-02-06T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 50,
  },
  {
    Date: "2024-02-07T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-02-08T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-09T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 20,
  },
  {
    Date: "2024-02-10T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 10,
  },
  {
    Date: "2024-02-11T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-02-12T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-02-13T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 80,
  },
  {
    Date: "2024-02-14T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 90,
  },
  {
    Date: "2024-02-15T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 100,
  },
  {
    Date: "2024-02-16T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 60,
  },
  {
    Date: "2024-02-17T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 40,
  },
  {
    Date: "2024-02-18T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-19T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-20T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-21T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-22T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-23T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-24T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-25T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-26T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-27T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-28T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
  {
    Date: "2024-02-29T00:00:00",
    CarrierId: 24,
    CarrierName: "Fastline Express",
    TotalOrder: 1,
    InProgress: 1,
    Delivered: 0,
    DeliveryRatio: 30,
  },
];

export const sampleGraphData = [...com1_18Days, ...com2_18Days];

export const sampleCarrierStat = [
  {
    carrierId: 4,
    carrierName: "Abstract Delivery",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: 1,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 9,
    carrierName: "Aspeed Delivery",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: 1,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 12,
    carrierName: "Camel Delivery Services",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: 1,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 15,
    carrierName: "Cube Ship Express",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: 1,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 46,
    carrierName: "ETajar",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: 1,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 21,
    carrierName: "Expo Express",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: 1,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 24,
    carrierName: "Fastline Express",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: 1,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 26,
    carrierName: "Fawry Delivery",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: 1,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: 1,
    Outfordelivery1: null,
    Outfordelivery2: 1,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 31,
    carrierName: "HK Express",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: 1,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: 1,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 32,
    carrierName: "iFast Express",
    TotalCount: 1,
    AssignInHouse: 1,
    AssignedTocarrier: 1,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: 1,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 33,
    carrierName: "Imepress Delivery",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: 1,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 35,
    carrierName: "Kangaroo Delivery",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: 1,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 10035,
    carrierName: "M Afaq",
    TotalCount: 1,
    AssignInHouse: 5,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: 4,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: 1,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 13,
    carrierName: "Sama Delivery",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: 1,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: 1,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 14,
    carrierName: "SENDIX Delivery",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: 1,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 1,
    carrierName: "TwentyFourExpress",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: 1,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 37,
    carrierName: "Urgent Delivery",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: 1,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 40,
    carrierName: "Weship Bhrain",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: 1,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 41,
    carrierName: "Weship KSA",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: 1,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: null,
    Outfordelivery2: null,
    Outfordelivery3: 1,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
  {
    carrierId: 52,
    carrierName: "Zajil",
    TotalCount: 1,
    AssignInHouse: null,
    AssignedTocarrier: null,
    CustomerRefused: null,
    Delivered: null,
    DeliveryAttempt1: null,
    InOperation: null,
    MobileNotAnswered: null,
    OrderPlaced: null,
    OutForDelivery: null,
    Outfordelivery1: 1,
    Outfordelivery2: null,
    Outfordelivery3: null,
    Returned: null,
    TobePickedUp: null,
    UnAssignfromcarrier: null,
  },
];
