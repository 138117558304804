import {
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { styleSheet } from "../../../assets/styles/style";
import GeneralTabBar from "../../../components/shared/tabsBar";
import NotesList from "./notesList";
import {
  GetAllDeliveryNote,
  GetAllDeliveryTask,
} from "../../../api/AxiosInterceptors";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";

const EnumTabFilter = Object.freeze({
  All: "/delivery-notes",
  InProgress: "/delivery-notes/inprogress",
  Completed: "/delivery-notes/completed",
});

function DeliveryNotes(props) {
  let defaultValues = {
    startDate: null,
    endDate: null,
    deliveryNoteStatusId: 0,
  };
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetDates,
    startDateFormated,
    endDateFormated,
  } = useDateRangeHook();

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isTabFilter, setIsTabFilter] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [allDeliveryNote, setAllDeliveryNote] = useState([]);
  const [isAllListLoading, setIsAllListLoading] = useState([]);
  const resetRowRef = useRef(false);
  const getOrdersRef = useRef([]);
  const [isfilterClear, setIsfilterClear] = useState(false);
  const [deliveryNoteStatusId, setDeliveryNoteStatusId] = useState(
    defaultValues.deliveryNoteStatusId
  );

  const resetFilter = () => {
    setDeliveryNoteStatusId(defaultValues.deliveryNoteStatusId);
  };

  const getFiltersFromState = () => {
    let search = "";
    let filters = {
      filterModel: {
        createdFrom: startDateFormated ? startDateFormated : null,
        createdTo: endDateFormated ? endDateFormated : null,
        start: 0,
        length: 1000,
        search: search,
        sortDir: "desc",
        sortCol: 0,
      },
      deliveryNoteStatusId: deliveryNoteStatusId,
    };
    return filters;
  };
  let getAllDeliveryNote = async () => {
    setIsAllListLoading(true);
    let params = getFiltersFromState();
    let res = await GetAllDeliveryNote(params);
    if (res.data.result !== null) {
      setAllDeliveryNote(res.data.result);
    }
    setIsAllListLoading(false);
    resetRowRef.current = false;
  };

  useEffect(() => {
    getAllDeliveryNote();
  }, []);

  const handleTabChange = (event, filterValue) => {
    resetFilter();
    setIsFilterOpen(false);
    if (filterValue === EnumTabFilter.All) {
      setDeliveryNoteStatusId(0); //0 for all shipments
      setIsShowFilter(true);
    } else if (filterValue == EnumTabFilter.InProgress) {
      setDeliveryNoteStatusId(1); //1 for unassigned shipments
      setIsShowFilter(false);
    } else if (filterValue == EnumTabFilter.Completed) {
      setDeliveryNoteStatusId(2); //2 for Assigned shipments
      setIsShowFilter(false);
    }
    resetRowRef.current = true;
    setIsTabFilter(true);
  };

  useEffect(() => {
    if (isfilterClear) {
      getAllDeliveryNote();
      setIsfilterClear(false);
    }
  }, [isfilterClear]);
  useEffect(() => {
    if (isTabFilter) {
      getAllDeliveryNote();
      setIsTabFilter(false);
    }
  }, [deliveryNoteStatusId]);
  const getlNaviagiotnComponent = () => {
    return (
      <NotesList
        loading={isAllListLoading}
        allDeliveryNote={allDeliveryNote}
        getOrdersRef={getOrdersRef}
        resetRowRef={resetRowRef}
        getAllDeliveryNote={getAllDeliveryNote}
      />
    );
  };
  return (
    <Box sx={styleSheet.pageRoot}>
      <div style={{ padding: "10px" }}>
        {" "}
        <GeneralTabBar
          handleTabChange={handleTabChange}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
          tabData={[
            {
              label:
                LanguageReducer?.languageType?.MY_CARRIER_DELIVERY_TASKS_All,
              route: EnumTabFilter.All,
            },
            {
              label:
                LanguageReducer?.languageType
                  ?.MY_CARRIER_DELIVERY_NOTES_IN_PROGRESS,
              route: EnumTabFilter.InProgress,
            },
            {
              label:
                LanguageReducer?.languageType
                  ?.MY_CARRIER_DELIVERY_NOTES_COMPLETED,
              route: EnumTabFilter.Completed,
            },
          ]}
          {...props}
          disableFilter
          disableSearch
          // width="auto"
        />
        {isFilterOpen ? (
          <Table
            sx={{ ...styleSheet.generalFilterArea }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <Grid container spacing={2} sx={{ p: "6px" }}>
                  <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {
                          LanguageReducer?.languageType
                            ?.MY_CARRIER_DELIVERY_TASKS_START_DATE
                        }
                      </InputLabel>
                      <TextField
                        sx={{ minWidth: "150px" }}
                        type="date"
                        inputProps={{
                          style: {
                            padding: "2px 10px 2px 10px",
                            fontSize: "14px",
                          },
                        }}
                        size="small"
                        id="startDate"
                        name="startDate"
                        fullWidth
                        variant="outlined"
                        value={startDate}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {
                          LanguageReducer?.languageType
                            ?.MY_CARRIER_DELIVERY_TASKS_END_DATE
                        }
                      </InputLabel>
                      <TextField
                        sx={{ minWidth: "150px" }}
                        inputProps={{
                          style: {
                            padding: "2px 10px 2px 10px",
                            fontSize: "14px",
                          },
                        }}
                        type="date"
                        size="small"
                        id="endDate"
                        name="endDate"
                        fullWidth
                        variant="outlined"
                        value={endDate}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                    <Stack alignItems="right" direction="row" spacing={1}>
                      <Button
                        sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                          resetFilter();
                        }}
                      >
                        {LanguageReducer?.languageType?.CLEAR_FILTER_TEXT}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </TableRow>
            </TableHead>
          </Table>
        ) : null}
        <Routes>
          <Route path="/" element={getlNaviagiotnComponent()} />
          <Route path="/inprogress" element={getlNaviagiotnComponent()} />
          <Route path="/completed" element={getlNaviagiotnComponent()} />
        </Routes>
      </div>
    </Box>
  );
}
export default DeliveryNotes;
