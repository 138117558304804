export const deliveryNotesEnglish = {
  MY_CARRIER_DELIVERY_NOTE_S_TEXT: "Delivery Note(s)",
  MY_CARRIER_DELIVERY_NOTES_TEXT: "Delivery Notes",
  MY_CARRIER_DELIVERY_TASKS_All: "All",
  MY_CARRIER_DELIVERY_NOTES_IN_PROGRESS: "In Progress",
  MY_CARRIER_DELIVERY_NOTES_COMPLETED: "Completed",
  MY_CARRIER_DELIVERY_NOTES_NOTE_NO: "Note No.",
  MY_CARRIER_DELIVERY_NOTES_DRIVER: "Driver",
  MY_CARRIER_DELIVERY_NOTES_SHIPMENTS_COUNT: "Shipment(s) Count",
  MY_CARRIER_DELIVERY_NOTES_NOTE_STATUS: "Note Status",
  MY_CARRIER_DELIVERY_NOTES_DATE: "Date",
};
export const deliveryNotesArabic = {
  MY_CARRIER_DELIVERY_NOTE_S_TEXT: "Delivery Note(s)",
  MY_CARRIER_DELIVERY_NOTES_TEXT: "Delivery Notes",
  MY_CARRIER_DELIVERY_TASKS_All: "الجميع",
  MY_CARRIER_DELIVERY_NOTES_IN_PROGRESS: "قيد التنفيذ",
  MY_CARRIER_DELIVERY_NOTES_COMPLETED: "مكتمل",
  MY_CARRIER_DELIVERY_NOTES_NOTE_NO: "رقم الملاحظة",
  MY_CARRIER_DELIVERY_NOTES_DRIVER: "السائق",
  MY_CARRIER_DELIVERY_NOTES_SHIPMENTS_COUNT: "عدد الشحنات",
  MY_CARRIER_DELIVERY_NOTES_NOTE_STATUS: "حالة الملاحظة",
  MY_CARRIER_DELIVERY_NOTES_DATE: "التاريخ",
};
export const deliveryNotesChines = {
  MY_CARRIER_DELIVERY_NOTE_S_TEXT: "Delivery Note(s)",
  MY_CARRIER_DELIVERY_NOTES_TEXT: "Delivery Notes",
  MY_CARRIER_DELIVERY_TASKS_All: "全部",
  MY_CARRIER_DELIVERY_NOTES_IN_PROGRESS: "进行中",
  MY_CARRIER_DELIVERY_NOTES_COMPLETED: "已完成",
  MY_CARRIER_DELIVERY_NOTES_NOTE_NO: "备注编号",
  MY_CARRIER_DELIVERY_NOTES_DRIVER: "司机",
  MY_CARRIER_DELIVERY_NOTES_SHIPMENTS_COUNT: "发货数量",
  MY_CARRIER_DELIVERY_NOTES_NOTE_STATUS: "备注状态",
  MY_CARRIER_DELIVERY_NOTES_DATE: "日期",
};
