import {
  saleChannelOrderArabic,
  saleChannelOrderChines,
  saleChannelOrderEnglish,
} from "./saleChannelOrder/saleChannelOrder";

export const ordersEnglish = {
  ...saleChannelOrderEnglish,
  ORDERS_TEXT: "Orders",
  ORDERS_ORDER_DASHBOARD: "Order Dashboard",
  ORDERS_ALL: "All",
  ORDERS_REGULAR: "Regular",
  ORDERS_FULFILLABLE: "Fulfillable",
  ORDERS_UNFULFILLED: "Unfulfilled",
  ORDERS_UNASSIGNED: "Unassigned",
  ORDERS_FULFILLED: "Fulfilled",
  ORDERS_UNPAID: "Unpaid",
  ORDERS_PAID: "Paid",
  ORDERS_CREATE_REGULAR_ORDER: "Create Regular Order",
  ORDERS_UPDATE_REGULAR_ORDER: "Update Regular Order",
  ORDERS_CREATE_FULFILLABLE_ORDER: "Create Fulfillable Order",
  ORDERS_UPDATE_FULFILLABLE_ORDER: "Update Fulfillable Order",
  ORDERS_UPLOAD_ORDER: "Upload Order",
  ORDERS_ORDER_NO_REF_NO: "Order No./ Ref No",
  ORDERS_STORE_INFO: "Store Info.",
  ORDERS_CUSTOMER_INFO: "Customer Info.",
  ORDERS_PAYMENT_STATUS: "Payment Status",
  ORDERS_FULFILLMENT_STATUS: "Fulfillment Status",
  ORDERS_ORDER_TYPE: "Order Type",
  ORDERS_TRACKING_STATUS: "Tracking Status",
  ORDERS_ITEM_COUNT: "Item Count",
  ORDERS_VAT: "VAT",
  ORDERS_DISCOUNT: "Discount",
  ORDERS_AMOUNT: "Amount",
  ORDERS_STORE: "Store",
  ORDERS_STATIONS: "Stations",
  ORDERS_REF_NO: "Ref No",
  ORDERS_ORDER_DATE: "Order Date",
  ORDERS_CUSTOMER_NAME: "Customer Name",
  ORDERS_CUSTOMER: "Customer",
  ORDERS_EMAIL: "Email",
  ORDERS_PHONE_NO: "Phone No",
  ORDERS_CONTACT_NUMBER: "Contact Number",
  ORDERS_COUNTRY: "Country",
  ORDERS_REGION_CITY: "Region/City",
  ORDERS_AREA_NEIGHBOURHOOD: "Area/Neighbourhood",
  ORDERS_APARTMENT_SUITE_ETC: "Apartment/ Suite/ Etc",
  ORDERS_LATITUDE_AND_LONGITUDE: "Latitude And Longitude",
  ORDERS_GET_ADDRESS: "Get Address",
  ORDERS_FORM_MAP: "Form Map",
  ORDERS_ORDER_DETAILS: "Order Details",
  ORDERS_DESCRIPTION: "Description",
  ORDERS_REMARKS: "Remarks",
  ORDERS_NUMBER_OF_PIECES: "Number Of Pieces",
  ORDERS_TOTAL_WEIGHT: "Total Weight",
  ORDERS_DESCRIPTION_OF_PIECE_1: "Description Of Piece",
  ORDERS_PAYMENT: "Payment",
  ORDERS_SUBTOTAL: "Sub total",
  ORDERS_ADD_DISCOUNT: "Add Discount",
  ORDERS_ADD_SHIPPING: "Add Shipping",
  ORDERS_TAX_VAT_5_PERCENT: "Tax VAT 5 %",
  ORDERS_TOTAL: "Total",
  ORDERS_PAYMENT_METHOD: "Payment Method",
  ORDERS_PREPAID: "Prepaid",
  ORDERS_CASH_ON_DELIVERY: "Cash On Delivery",
  ORDERS_CONFIRM: "Confirm",
  ORDERS_CONFIRM_AND_NEW: "Confirm And New",
  ORDERS_CREATE_FULFILLABLE_ORDER: "Create Fulfillable Order",
  ORDERS_NOTE: "Note",
  ORDERS_FILTER: "Filter",
  ORDERS_ACTION: "Action",
  ORDER_IMPORT: "Import",
  ORDER_SAMPLE_REGULAR: "Sample Regular",
  ORDER_SAMPLE_FULLFILLABLE: "Sample Fullfillable",
  ORDER_DOWNLOAD_EMIRATES: "Download Emirates",
  ORDER_DOWNLOAD_AREA_NEIGHBOURHOOD: "Download Area/Neighbourhood",
  ORDER_PRODUCT: "Product",
  ORDER_ADDRESS: "Address",
  ORDER_START_DATE: "Start Date",
  ORDER_END_DATE: "End Date",
  ORDER_STORE: "Store",
  ORDER_CARRIER: "Carrier",
  ORDER_ORDER_TYPE: "Order Type",
  ORDER_FULFILLMENT_STATUS: "Fullfillment Status",
  ORDER_PAYMENT_STATUS: "Payment Status",
  ORDER_PAYMENT_METHOD: "Payment Method",
  ORDER_STATION: "Station",
  ORDER_SALES_PERSON: "Sales Person",
  ORDER_COUNTRY: "Country",
  ORDER_CARRIER_TRACKING_STATUS: "Carrier Tracking Status",
  ORDER_CLEAR_FILTER: "Clear Filter",
  ORDER_ASSIGN: "Carrier Assign",
  ORDER_EXCEL_EXPORT: "Excel Export",
  ORDER_PRINT_LABELS: "Print Labels",
  ORDER_PRINT_CARRIER_LABELS: "Print Carrier Labels",
  ORDER_REFRESH_TRACKING_STATUS: "Refresh Tracking Status",
  ORDER_UNASSIGN_FROM_CARRIER: "Unassign From Carrier",
  ORDER_BATCH_FULLFILLMENT: "Batch Fulfillment",
  ORDER_DELETE_ORDER: "Delete Order",
};
export const ordersArabic = {
  ...saleChannelOrderArabic,
  ORDERS_TEXT: "طلبات",
  ORDERS_ORDER_DASHBOARD: "لوحة تحكم الطلبات",
  ORDERS_FILTER: "تصفية",
  ORDERS_ACTION: "إجراء",
  ORDERS_ALL: "الكل",
  ORDERS_REGULAR: "منتظم",
  ORDERS_FULFILLABLE: "قابل للتنفيذ",
  ORDERS_UNFULFILLED: "غير مكتمل",
  ORDERS_UNASSIGNED: "غير مخصص",
  ORDERS_FULFILLED: "مكتمل",
  ORDERS_UNPAID: "غير مدفوع",
  ORDERS_PAID: "مدفوع",
  ORDERS_CREATE_REGULAR_ORDER: "إنشاء طلب منتظم",
  ORDERS_CREATE_FULFILLABLE_ORDER: "إنشاء طلب قابل للتنفيذ",
  ORDERS_UPDATE_REGULAR_ORDER: "تحديث الطلب العادي",
  ORDERS_UPDATE_FULFILLABLE_ORDER: "تحديث الطلب القابل للتنفيذ",
  ORDERS_UPLOAD_ORDER: "رفع الطلب",
  ORDERS_ORDER_NO_REF_NO: "رقم الطلب / الرقم المرجعي",
  ORDERS_STORE_INFO: "معلومات المتجر",
  ORDERS_CUSTOMER_INFO: "معلومات العميل",
  ORDERS_PAYMENT_STATUS: "حالة الدفع",
  ORDERS_FULFILLMENT_STATUS: "حالة التنفيذ",
  ORDERS_ORDER_TYPE: "نوع الطلب",
  ORDERS_TRACKING_STATUS: "حالة التتبع",
  ORDERS_ITEM_COUNT: "عدد العناصر",
  ORDERS_VAT: "ضريبة القيمة المضافة (VAT)",
  ORDERS_DISCOUNT: "خصم",
  ORDERS_AMOUNT: "المبلغ",
  ORDERS_STORE: "المتجر",
  ORDERS_STATIONS: "المحطات",
  ORDERS_REF_NO: "الرقم المرجعي",
  ORDERS_ORDER_DATE: "تاريخ الطلب",
  ORDERS_CUSTOMER_NAME: "اسم العميل",
  ORDERS_CUSTOMER: "العميل",
  ORDERS_EMAIL: "البريد الإلكتروني",
  ORDERS_PHONE_NO: "رقم الهاتف",
  ORDERS_CONTACT_NUMBER: "رقم الاتصال",
  ORDERS_COUNTRY: "الدولة",
  ORDERS_REGION_CITY: "المنطقة/المدينة",
  ORDERS_AREA_NEIGHBOURHOOD: "المنطقة/الحي",
  ORDERS_APARTMENT_SUITE_ETC: "الشقة/الجناح/إلخ",
  ORDERS_LATITUDE_AND_LONGITUDE: "خطوط العرض والطول",
  ORDERS_GET_ADDRESS: "احصل على العنوان",
  ORDERS_FORM_MAP: "خريطة النموذج",
  ORDERS_ORDER_DETAILS: "تفاصيل الطلب",
  ORDERS_DESCRIPTION: "الوصف",
  ORDERS_REMARKS: "ملاحظات",
  ORDERS_NUMBER_OF_PIECES: "عدد القطع",
  ORDERS_TOTAL_WEIGHT: "الوزن الإجمالي",
  ORDERS_DESCRIPTION_OF_PIECE_1: "وصف القطعة ",
  ORDERS_PAYMENT: "الدفع",
  ORDERS_SUBTOTAL: "الإجمالي الفرعي",
  ORDERS_ADD_DISCOUNT: "إضافة خصم",
  ORDERS_ADD_SHIPPING: "إضافة شحن",
  ORDERS_TAX_VAT_5_PERCENT: "ضريبة القيمة المضافة 5%",
  ORDERS_TOTAL: "المجموع",
  ORDERS_PAYMENT_METHOD: "طريقة الدفع",
  ORDERS_PREPAID: "مدفوع مسبقًا",
  ORDERS_CASH_ON_DELIVERY: "الدفع عند الاستلام",
  ORDERS_CONFIRM: "تأكيد",
  ORDERS_CONFIRM_AND_NEW: "تأكيد وإنشاء جديد",
  ORDERS_CREATE_FULFILLABLE_ORDER: "إنشاء طلب قابل للتنفيذ",
  ORDERS_NOTE: "ملاحظة",
  ORDER_IMPORT: "يستورد",
  ORDER_SAMPLE_REGULAR: "عينة منتظمة",
  ORDER_SAMPLE_FULLFILLABLE: "عينة قابلة للتنفيذ",
  ORDER_DOWNLOAD_EMIRATES: "تنزيل الإمارات",
  ORDER_DOWNLOAD_AREA_NEIGHBOURHOOD: "تنزيل المنطقة/الحي",
  ORDER_PRODUCT: "منتج",
  ORDER_ADDRESS: "عنوان",
  ORDER_START_DATE: "من التاريخ",
  ORDER_END_DATE: "إلى التاريخ",
  ORDER_STORE: "المتجر",
  ORDER_CARRIER: "الناقل",
  ORDER_ORDER_TYPE: "نوع الطلب",
  ORDER_FULFILLMENT_STATUS: "حالة التنفيذ",
  ORDER_PAYMENT_STATUS: "حالة الدفع",
  ORDER_PAYMENT_METHOD: "طريقة الدفع",
  ORDER_STATION: "المحطة",
  ORDER_SALES_PERSON: "مندوب المبيعات",
  ORDER_COUNTRY: "الدولة",
  ORDER_CARRIER_TRACKING_STATUS: "حالة تتبع الناقل",
  ORDER_CLEAR_FILTER: "مسح الفلتر",
  ORDER_ASSIGN: "تعيين الناقل",
  ORDER_EXCEL_EXPORT: "تصدير إكسل",
  ORDER_PRINT_LABELS: "طباعة الملصقات",
  ORDER_PRINT_CARRIER_LABELS: "طباعة ملصقات الناقل",
  ORDER_REFRESH_TRACKING_STATUS: "تحديث حالة التتبع",
  ORDER_UNASSIGN_FROM_CARRIER: "إلغاء التعيين من الناقل",
  ORDER_BATCH_FULLFILLMENT: "تنفيذ الدفعات",
  ORDER_DELETE_ORDER: "حذف الطلبات",
};
export const ordersChinese = {
  ...saleChannelOrderChines,
  ORDERS_TEXT: "命令",
  ORDERS_FILTER: "筛选",
  ORDERS_ACTION: "操作",
  ORDERS_ALL: "所有",
  ORDERS_REGULAR: "常规",
  ORDERS_FULFILLABLE: "可履行",
  ORDERS_UNFULFILLED: "未履行",
  ORDERS_UNASSIGNED: "未分配",
  ORDERS_FULFILLED: "已完成",
  ORDERS_UNPAID: "未支付",
  ORDERS_PAID: "已支付",
  ORDERS_CREATE_REGULAR_ORDER: "创建常规订单",
  ORDERS_CREATE_FULFILLABLE_ORDER: "创建可履行订单",
  ORDERS_UPDATE_REGULAR_ORDER: "更新常规订单",
  ORDERS_UPDATE_FULFILLABLE_ORDER: "更新可履行订单",
  ORDERS_UPLOAD_ORDER: "上传订单",
  ORDERS_ORDER_NO_REF_NO: "订单号/参考号",
  ORDERS_STORE_INFO: "店铺信息",
  ORDERS_CUSTOMER_INFO: "客户信息",
  ORDERS_PAYMENT_STATUS: "付款状态",
  ORDERS_FULFILLMENT_STATUS: "履行状态",
  ORDERS_ORDER_TYPE: "订单类型",
  ORDERS_TRACKING_STATUS: "跟踪状态",
  ORDERS_ITEM_COUNT: "商品数量",
  ORDERS_VAT: "增值税",
  ORDERS_DISCOUNT: "折扣",
  ORDERS_AMOUNT: "金额",
  ORDERS_STORE: "店铺",
  ORDERS_STATIONS: "站点",
  ORDERS_REF_NO: "参考号",
  ORDERS_ORDER_DATE: "订单日期",
  ORDERS_CUSTOMER_NAME: "客户姓名",
  ORDERS_CUSTOMER: "客户",
  ORDERS_EMAIL: "电子邮件",
  ORDERS_PHONE_NO: "电话号码",
  ORDERS_CONTACT_NUMBER: "联系号码",
  ORDERS_COUNTRY: "国家",
  ORDERS_REGION_CITY: "地区/城市",
  ORDERS_AREA_NEIGHBOURHOOD: "区域/社区",
  ORDERS_APARTMENT_SUITE_ETC: "公寓/套房/等",
  ORDERS_LATITUDE_AND_LONGITUDE: "緯度和經度",
  ORDERS_GET_ADDRESS: "取得地址",
  ORDERS_FORM_MAP: "地图表单",
  ORDERS_ORDER_DETAILS: "订单详情",
  ORDERS_DESCRIPTION: "描述",
  ORDERS_REMARKS: "备注",
  ORDERS_NUMBER_OF_PIECES: "件数",
  ORDERS_TOTAL_WEIGHT: "总重量",
  ORDERS_DESCRIPTION_OF_PIECE_1: "第一件商品描述",
  ORDERS_PAYMENT: "付款",
  ORDERS_SUBTOTAL: "小计",
  ORDERS_ADD_DISCOUNT: "添加折扣",
  ORDERS_ADD_SHIPPING: "添加运费",
  ORDERS_TAX_VAT_5_PERCENT: "税费增值税 5%",
  ORDERS_TOTAL: "总计",
  ORDERS_PAYMENT_METHOD: "付款方式",
  ORDERS_PREPAID: "预付款",
  ORDERS_CASH_ON_DELIVERY: "货到付款",
  ORDERS_CONFIRM: "确认",
  ORDERS_CONFIRM_AND_NEW: "确认并新建",
  ORDERS_CREATE_FULFILLABLE_ORDER: "创建可履行订单",
  ORDERS_ORDER_DASHBOARD: "订单仪表板",
  ORDERS_NOTE: "备注",
  ORDER_IMPORT: "进口",
  ORDER_SAMPLE_REGULAR: "样品常规",
  ORDER_SAMPLE_FULLFILLABLE: "样品可完成",
  ORDER_DOWNLOAD_EMIRATES: "下载阿联酋航空",
  ORDER_DOWNLOAD_AREA_NEIGHBOURHOOD: "下载区域/街区",
  ORDER_PRODUCT: "产品",
  ORDER_ADDRESS: "地址",
  ORDER_START_DATE: "开始日期",
  ORDER_END_DATE: "结束日期",
  ORDER_STORE: "店铺",
  ORDER_CARRIER: "承运人",
  ORDER_ORDER_TYPE: "订单类型",
  ORDER_FULFILLMENT_STATUS: "履行状态",
  ORDER_PAYMENT_STATUS: "付款状态",
  ORDER_PAYMENT_METHOD: "付款方式",
  ORDER_STATION: "站点",
  ORDER_SALES_PERSON: "销售人员",
  ORDER_COUNTRY: "国家",
  ORDER_CARRIER_TRACKING_STATUS: "承运人跟踪状态",
  ORDER_CLEAR_FILTER: "清除筛选",
  ORDER_ASSIGN: "承运商指定",
  ORDER_EXCEL_EXPORT: "导出Excel",
  ORDER_PRINT_LABELS: "打印标签",
  ORDER_PRINT_CARRIER_LABELS: "打印承运标签",
  ORDER_REFRESH_TRACKING_STATUS: "刷新跟踪状态",
  ORDER_UNASSIGN_FROM_CARRIER: "取消指定承运人",
  ORDER_BATCH_FULLFILLMENT: "批量履行",
  ORDER_DELETE_ORDER: "删除订单",
};
