export const shipmentTabEnglish = {
  SETTING_SHIPMENT_TAB_TEXT: "Shipment Tab",
  SETTING_SHIPMENT_TAB_ADD_NEW_TAB: "Add New Tab",
  SETTING_SHIPMENT_TAB_UPDATE_SHIPMENT_TAB: "Update Shipment Tab",
  SETTING_SHIPMENT_TAB_SELECT_STATUS: "Select Status",
};
export const shipmentTabArabic = {
  SETTING_SHIPMENT_TAB_TEXT: "Shipment Tab",
  SETTING_SHIPMENT_TAB_ADD_NEW_TAB: "إضافة علامة تبويب جديدة",
  SETTING_SHIPMENT_TAB_UPDATE_SHIPMENT_TAB: "تحديث علامة التبويب شحنة",
  SETTING_SHIPMENT_TAB_SELECT_STATUS: "اختيار الحالة",
};
export const shipmentTabChines = {
  SETTING_SHIPMENT_TAB_TEXT: "Shipment Tab",
  SETTING_SHIPMENT_TAB_ADD_NEW_TAB: "添加新标签",
  SETTING_SHIPMENT_TAB_UPDATE_SHIPMENT_TAB: "更新发货选项",
  SETTING_SHIPMENT_TAB_SELECT_STATUS: "选择状态",
};
