export const expenseEnglish = {
  MY_CARRIER_EXPENSE_S_TEXT: "Expense(s)",
  MY_CARRIER_EXPENSES_TEXT: "Expenses",
  MY_CARRIER_EXPENSES_NOTE_NO: "Note No.",
  MY_CARRIER_EXPENSES_DRIVER: "Driver",
  MY_CARRIER_EXPENSES_DRIVER_CODE: "DriverCode",
  MY_CARRIER_EXPENSES_EXPENSE_CATEGORY: "Expense Category",
  MY_CARRIER_EXPENSES_EXPENSE_DATE: "Expense Date",
  MY_CARRIER_EXPENSES_START_DATE: "Start Date",
  MY_CARRIER_EXPENSES_END_DATE: "End Date",
  MY_CARRIER_EXPENSES_DRIVER: "Driver",
  MY_CARRIER_EXPENSES_EXPENSE_TYPE: "Expense Type",
  MY_CARRIER_EXPENSES_FILTER: "Filter",
  MY_CARRIER_EXPENSES_CLEAR_FILTER: "Clear Filter",
  MY_CARRIER_EXPENSES_SELECT_PLEASE: "Select Please",
  MY_CARRIER_EXPENSES_PDF_REPORT: "PDF Report",
  MY_CARRIER_EXPENSES_EXCEL_EXPORT: "Excel Export",
  MY_CARRIER_EXPENSES_AMOUNT: "Amount",
  MY_CARRIER_EXPENSES_TASKS_ACTION: "Action",
};

export const expenseArabic = {
  MY_CARRIER_EXPENSE_S_TEXT: "Expense(s)",
  MY_CARRIER_EXPENSES_TEXT: "Expenses",
  MY_CARRIER_EXPENSES_NOTE_NO: "رقم الملاحظة",
  MY_CARRIER_EXPENSES_DRIVER: "السائق",
  MY_CARRIER_EXPENSES_DRIVER_CODE: "رمز السائق",
  MY_CARRIER_EXPENSES_EXPENSE_CATEGORY: "فئة المصاريف",
  MY_CARRIER_EXPENSES_EXPENSE_DATE: "تاريخ المصاريف",
  MY_CARRIER_EXPENSES_START_DATE: "تاريخ البداية",
  MY_CARRIER_EXPENSES_END_DATE: "تاريخ النهاية",
  MY_CARRIER_EXPENSES_DRIVER: "السائق",
  MY_CARRIER_EXPENSES_EXPENSE_TYPE: "نوع المصروفات",
  MY_CARRIER_EXPENSES_FILTER: "تصفية",
  MY_CARRIER_EXPENSES_CLEAR_FILTER: "مسح الفلتر",
  MY_CARRIER_EXPENSES_SELECT_PLEASE: "يرجى التحديد",
  MY_CARRIER_EXPENSES_PDF_REPORT: "تقرير PDF",
  MY_CARRIER_EXPENSES_EXCEL_EXPORT: "تصدير إكسل",
  MY_CARRIER_EXPENSES_AMOUNT: "كمية",
  MY_CARRIER_EXPENSES_TASKS_ACTION: "فعل",
};
export const expenseChines = {
  MY_CARRIER_EXPENSE_S_TEXT: "Expense(s)",
  MY_CARRIER_EXPENSES_TEXT: "Expenses",
  MY_CARRIER_EXPENSES_NOTE_NO: "备注编号",
  MY_CARRIER_EXPENSES_DRIVER: "司机",
  MY_CARRIER_EXPENSES_DRIVER_CODE: "司机代码",
  MY_CARRIER_EXPENSES_EXPENSE_CATEGORY: "费用类别",
  MY_CARRIER_EXPENSES_EXPENSE_DATE: "费用日期",
  MY_CARRIER_EXPENSES_START_DATE: "开始日期",
  MY_CARRIER_EXPENSES_END_DATE: "结束日期",
  MY_CARRIER_EXPENSES_DRIVER: "司机",
  MY_CARRIER_EXPENSES_EXPENSE_TYPE: "费用类型",
  MY_CARRIER_EXPENSES_FILTER: "筛选",
  MY_CARRIER_EXPENSES_CLEAR_FILTER: "清除筛选",
  MY_CARRIER_EXPENSES_SELECT_PLEASE: "请选择",
  MY_CARRIER_EXPENSES_PDF_REPORT: "PDF报告",
  MY_CARRIER_EXPENSES_EXCEL_EXPORT: "Excel导出",
  MY_CARRIER_EXPENSES_AMOUNT: "数量",
  MY_CARRIER_EXPENSES_TASKS_ACTION: "行动",
};
