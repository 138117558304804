import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Box, colors, Menu, Typography } from "@mui/material";
import React, { useState } from "react";
import DataGridProComponent from "../../../../.reUseableComponents/DataGrid/DataGridProComponent";
import { styleSheet } from "../../../../assets/styles/style";
import StatusBadge from "../../../../components/shared/statudBadge";
import { EnumChangeFilterModelApiUrls } from "../../../../utilities/enum";
import UtilityClass from "../../../../utilities/UtilityClass";
import { Link } from "react-router-dom";
import {
  ActionButtonCustom,
  PaidButton,
} from "../../../../utilities/helpers/Helpers";
import Colors from "../../../../utilities/helpers/Colors";

const BillingHistoryList = (props) => {
  let { invoiceHistory, isLoading } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const columns = [
    {
      field: "CreateDate",
      minWidth: 160,
      flex: 1,
      headerName: <Box sx={{ fontWeight: "600" }}>{"Created On"}</Box>,
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="h6">
              {UtilityClass.convertUtcToLocalAndGetDate(params.row.createdOn)}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "Amount",
      minWidth: 160,
      flex: 1,
      headerName: <Box sx={{ fontWeight: "600" }}>{"Amount"}</Box>,
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="h6">{params.row.amount}</Typography>
          </Box>
        );
      },
    },
    {
      field: "Status",
      headerName: <Box sx={{ fontWeight: "600" }}>{"Status"}</Box>,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            gap={1}
            justifyContent={"center"}
            sx={{ textAlign: "center" }}
          >
            <Box>
              <StatusBadge
                title={params.row.statusValue}
                color={params.row.statusValue === "Due" ? "#fff;" : "#fff;"}
                bgColor={
                  params.row.statusValue === "Due" ? "#dc3545;" : "#28a745;"
                }
              />
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Action",
      minWidth: 150,
      flex: 1,
      headerName: <Box sx={{ fontWeight: "600" }}>{"Action"}</Box>,
      renderCell: (params) => {
        return (
          <Box>
            {params.row.statusValue === "Due" && (
              <Box>
                <Link to={params.row.hostInvoiceURL} target="_blank">
                  <ActionButtonCustom
                    label={"Make Payment"}
                    background={Colors.succes}
                  />
                </Link>
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        ...styleSheet.allOrderTable,
        height: "auto",
        paddingBottom: "20px",
      }}
    >
      <DataGridProComponent
        rowPadding={8}
        rows={invoiceHistory}
        columns={columns}
        loading={isLoading}
        headerHeight={40}
        getRowId={(row) => row.rowNum}
        checkboxSelection={false}
        disableSelectionOnClick
        rowsCount={invoiceHistory.length}
        paginationChangeMethod={invoiceHistory}
        paginationMethodUrl={EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.url}
        defaultRowsPerPage={EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.length}
      />
    </Box>
  );
};
export default BillingHistoryList;
