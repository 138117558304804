export const SecurityEnglish = {
  SETING_SECURITY_TEXT: "Security",
  SETING_SECURITY_PASSWORD: "Password",
  SETING_SECURITY_CURRENT_PASSWORD: "Current Password",
  SETING_SECURITY_NEW_PASSWORD: "New Password",
  SETING_SECURITY_CONFIRM_NEW_PASSWORD: "Confirm New Password",
  SETING_SECURITY_API_ACCESS: "Api Access",
  SETING_SECURITY_ROTATE: "Rotate",
  SETING_SECURITY_CLIENT_PUBLIC_KEY: "Client Public Key",
  SETING_SECURITY_CLIENT_Secret_KEY: "Client Secret Key",
  SETING_SECURITY_CLIENT_ENCRYPTED_KEY: "Client Encrypted Key",
};
export const SecurityArabic = {
  SETING_SECURITY_TEXT: "حماية",
  SETING_SECURITY_PASSWORD: "كلمة المرور",
  SETING_SECURITY_CURRENT_PASSWORD: "كلمة المرور الحالية",
  SETING_SECURITY_NEW_PASSWORD: "كلمة المرور الجديدة",
  SETING_SECURITY_CONFIRM_NEW_PASSWORD: "تأكيد كلمة المرور",
  SETING_SECURITY_API_ACCESS: "",
  SETING_SECURITY_ROTATE: "",
  SETING_SECURITY_CLIENT_PUBLIC_KEY: "",
  SETING_SECURITY_CLIENT_Secret_KEY: "",
  SETING_SECURITY_CLIENT_ENCRYPTED_KEY: "",
};
export const SecurityChines = {
  SETING_SECURITY_TEXT: "Security",
  SETING_SECURITY_PASSWORD: "密码",
  SETING_SECURITY_CURRENT_PASSWORD: "当前密码",
  SETING_SECURITY_NEW_PASSWORD: "新密码",
  SETING_SECURITY_CONFIRM_NEW_PASSWORD: "确认密码",
  SETING_SECURITY_API_ACCESS: "",
  SETING_SECURITY_ROTATE: "",
  SETING_SECURITY_CLIENT_PUBLIC_KEY: "",
  SETING_SECURITY_CLIENT_Secret_KEY: "",
  SETING_SECURITY_CLIENT_ENCRYPTED_KEY: "",
};
