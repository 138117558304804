import {
  codPendingArabic,
  codPendingChines,
  codPendingChinese,
  codPendingEnglish,
} from "./codPending/codPending";
import {
  deliveryNotesArabic,
  deliveryNotesChines,
  deliveryNotesEnglish,
} from "./deliveryNotes/deliveryNotes";
import {
  deliveryTaskArabic,
  deliveryTaskChines,
  deliveryTaskEnglish,
} from "./deliveryTasks/deliveryTask";
import {
  driverRecievableArabic,
  driverRecievableChines,
  driverRecievableEnglish,
} from "./driverRecievable/driverRecievable";
import {
  expenseArabic,
  expenseChines,
  expenseEnglish,
} from "./expense/expense";
import {
  pendingForReturnArabic,
  pendingForReturnChines,
  pendingForReturnEnglish,
} from "./pendingForReturn/pendingForReturn";
import { returnArabic, returnChinese, returnEnglish } from "./returns/return";

export const myCarrierEnglish = {
  ...codPendingEnglish,
  ...deliveryNotesEnglish,
  ...deliveryTaskEnglish,
  ...driverRecievableEnglish,
  ...expenseEnglish,
  ...pendingForReturnEnglish,
  ...returnEnglish,
  My_CARRIER: "My Carrier",
};
export const myCarrierArabic = {
  ...codPendingArabic,
  ...deliveryNotesArabic,
  ...deliveryTaskArabic,
  ...driverRecievableArabic,
  ...expenseArabic,
  ...pendingForReturnArabic,
  ...returnArabic,
  My_CARRIER: "ناقلي",
};
export const myCarrierChinese = {
  ...codPendingChinese,
  ...deliveryNotesChines,
  ...deliveryTaskChines,
  ...driverRecievableChines,
  ...expenseChines,
  ...pendingForReturnChines,
  ...returnChinese,
  My_CARRIER: "我的承运人",
};
