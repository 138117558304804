export const userRolesEnglish = {
  SETTING_CHOOSE_ROLES_TEXT: "User Roles",
  SETTING_CHOOSE_ADD_USER_ROLE: "Add User Role",
  SETTING_Name: "Name",
  SETTING_DESCRIPTION: "Description",
};
export const userRolesArabic = {
  SETTING_CHOOSE_ROLES_TEXT: "أدوار المستخدمين",
  SETTING_CHOOSE_ADD_USER_ROLE: "إضافة دور المستخدم",
  SETTING_Name: "الاسم",
  SETTING_DESCRIPTION: "الوصف",
};
export const userRolesChines = {
  SETTING_CHOOSE_ROLES_TEXT: "用户角色",
  SETTING_CHOOSE_ADD_USER_ROLE: "添加用户角色",
  SETTING_Name: "名称",
  SETTING_DESCRIPTION: "描述",
};
