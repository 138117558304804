export const ProductStationEnglish = {
  PRODUCT_PRODUCT_STATION_S_TEXT: "Product Station(s)",
  PRODUCT_PRODUCT_STATIONS_TEXT: "Product Stations",
  PRODUCT_PRODUCT_STATIONS_CODE: "Code",
  PRODUCT_PRODUCT_STATIONS_NAME: "Name",
  PRODUCT_PRODUCT_STATIONS_CREATEDON: "CreatedOn",
  PRODUCT_PRODUCT_STATIONS_STATUS: "Status",
  PRODUCT_PRODUCT_STATIONS_DEFAULT: "Default",
  PRODUCT_PRODUCT_STATIONS_ADD_PRODUCT_STATIONS: "Add Product Stations",
  PRODUCT_PRODUCT_STATIONS_STATION_NAME: "Station Name",
  PRODUCT_PRODUCT_STATIONS_MARK_AS_DEFAULT: "Mark as default",
  PRODUCT_PRODUCT_STATIONS_DISMISS: "Dismiss",
};
export const ProductStationArabic = {
  PRODUCT_PRODUCT_STATION_S_TEXT: "محطة المنتجات",
  PRODUCT_PRODUCT_STATIONS_TEXT: "Product Stations",
  PRODUCT_PRODUCT_STATIONS_CODE: "الرمز",
  PRODUCT_PRODUCT_STATIONS_NAME: "الاسم",
  PRODUCT_PRODUCT_STATIONS_CREATEDON: "تم الإنشاء في",
  PRODUCT_PRODUCT_STATIONS_STATUS: "الحالة",
  PRODUCT_PRODUCT_STATIONS_DEFAULT: "افتراضي",
  PRODUCT_PRODUCT_STATIONS_ADD_PRODUCT_STATIONS: "إضافة محطات المنتجات",
  PRODUCT_PRODUCT_STATIONS_STATION_NAME: "اسم المحطة",
  PRODUCT_PRODUCT_STATIONS_MARK_AS_DEFAULT: "تعيين كافتراضي",
  PRODUCT_PRODUCT_STATIONS_DISMISS: "رفض",
};
export const ProductStationChines = {
  PRODUCT_PRODUCT_STATION_S_TEXT: "产品站点",
  PRODUCT_PRODUCT_STATIONS_TEXT: "Product Stations",
  PRODUCT_PRODUCT_STATIONS_CODE: "代码",
  PRODUCT_PRODUCT_STATIONS_NAME: "名称",
  PRODUCT_PRODUCT_STATIONS_CREATEDON: "创建时间",
  PRODUCT_PRODUCT_STATIONS_STATUS: "状态",
  PRODUCT_PRODUCT_STATIONS_DEFAULT: "默认",
  PRODUCT_PRODUCT_STATIONS_ADD_PRODUCT_STATIONS: "添加产品站点",
  PRODUCT_PRODUCT_STATIONS_STATION_NAME: "站点名称",
  PRODUCT_PRODUCT_STATIONS_MARK_AS_DEFAULT: "设为默认",
  PRODUCT_PRODUCT_STATIONS_DISMISS: "解除",
};