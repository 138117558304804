import {
  Box,
  Typography,
  Grid,
  Stack,
  FormLabel,
  Divider,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styleSheet } from "../../../assets/styles/style";
import JsonViewViewer from "../../../.reUseableComponents/jsonViewer";
import TextFieldWithCopyButton from "../../../.reUseableComponents/TextField/TextFieldWithCopyButton";
import Documentation from "./Documentation";
import { EnumOrderType, EnumRequestType } from "../../../utilities/enum";
import DocumentationOrder from "./DocumentationOrder";

function CreateOrderDocumentation(props) {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [format, setFormat] = useState([
    {
      property: "orderList",
      type: "list",
      isRequired: true,
      description: "Shipra support list of orders ",
      isHeading: true,
    },
    {
      property: "storeId",
      type: "int",
      isRequired: false,
      description:
        "store is optional if you don't specify then default store will be used ",
    },
    {
      property: "channelId",
      type: "int",
      isRequired: false,
      description: "channelid  is optional ",
    },
    {
      property: "orderTypeId",
      type: "int",
      isRequired: true,
      description:
        "We support two type of order one is Regular Order if regular order then there is no product specification.\nother one is Fulfillable order when we have Fulfillable order then must choose product \nValue :  1 for Regular, 2 for fulfillable",
    },
    {
      property: "orderDate",
      type: "DateTime",
      isRequired: true,
      description: "OrderDate is required it could be off UTC format ",
    },
    {
      property: "description",
      type: "string",
      isRequired: false,
      description: "Order description ",
    },
    {
      property: "remarks",
      type: "string",
      isRequired: false,
      description: "Order remarks",
    },
    {
      property: "amount",
      type: "decimal",
      isRequired: true,
      description: "Total amount of order ",
    },
    {
      property: "cShippingCharges",
      type: "decimal",
      isRequired: false,
      description: "Shipping charges for order",
    },
    {
      property: "paymentStatusId",
      type: "int",
      isRequired: false,
      description: "Payment status ",
    },
    {
      property: "weight",
      type: "decimal",
      isRequired: false,
      description: "Total weight of order",
    },
    {
      property: "itemValue",
      type: "decimal",
      isRequired: false,
      description: "order itemValue ",
    },
    {
      property: "orderRequestVia",
      type: "int",
      isRequired: true,
      description: "orderRequestVia always 1 ",
    },
    {
      property: "paymentMethodId",
      type: "int",
      isRequired: true,
      description:
        "paymentMethodId we have two type of payment method 1 for PP(Prepaid) and 2 for COD(Cash on delivery)",
    },
    {
      property: "stationId",
      type: "string",
      isRequired: true,
      description:
        "stationId is required in case of Fulfillable order otherwise it will be default.",
    },
    {
      property: "discount",
      type: "decimal",
      isRequired: false,
      description:
        "discount id optional value it will be total discount on whole amount",
    },
    {
      property: "vat",
      type: "decimal",
      isRequired: false,
      description: "vat value is optional it will be total vat on whole amount",
    },
    {
      property: "orderNote",
      type: "object",
      isRequired: true,
      description: "orderNote is object type ",
      isHeading: true,
    },
    {
      property: "note",
      type: "string",
      isRequired: true,
      description: "note value is required ",
    },
    {
      property: "orderAddress",
      type: "object",
      isRequired: true,
      description:
        "orderAddress object is type of object which contain values of receiver information ",
      isHeading: true,
    },
    {
      property: "customerName",
      type: "string",
      isRequired: true,
      description: "receiver name is required ",
    },
    {
      property: "email",
      type: "string",
      isRequired: false,
      description: "customer email it will be used for invoice sent if exist ",
    },
    {
      property: "mobile1",
      type: "string",
      isRequired: true,
      description: "Customer mobile ",
    },
    {
      property: "mobile2",
      type: "string",
      isRequired: false,
      description: "mobile2 is optional value",
    },
    {
      property: "countryId",
      type: "int",
      isRequired: true,
      description: "countryId is receiver country ",
    },
    {
      property: "regionId",
      type: "int",
      isRequired: true,
      description: "regionId is receiver region ",
    },
    {
      property: "cityId",
      type: "int",
      isRequired: true,
      description: "receiver city ",
    },
    {
      property: "streetAddress",
      type: "string",
      isRequired: true,
      description: "customer street address ",
    },
    {
      property: "latitude",
      type: "decimal",
      isRequired: false,
      description: "receiver latitude ",
    },
    {
      property: "longitude",
      type: "decimal",
      isRequired: false,
      description: "receiver longitude",
    },
    {
      property: "longitude",
      type: "decimal",
      isRequired: false,
      description: "receiver longitude",
    },
    {
      property: "orderItems",
      type: "list",
      isRequired: true,
      description: "orderItems is required it must not be empty",
      isHeading: true,
    },
    {
      property: "productId",
      type: "string",
      isRequired: false,
      description: "productId is required in case of fulfillable order type 2.",
    },
    {
      property: "productStockId",
      type: "string",
      isRequired: false,
      description:
        "productStockId is required in case of fulfillable order type 2.",
    },
    {
      property: "price",
      type: "decimal",
      isRequired: true,
      description: "price of single item",
    },
    {
      property: "description",
      type: "string",
      isRequired: true,
      description: "item description",
    },
    {
      property: "remarks",
      type: "string",
      isRequired: false,
      description: "item remarks",
    },
    {
      property: "quantity",
      type: "int",
      isRequired: true,
      description: "quantity of item minimum 1.",
    },
    {
      property: "discount",
      type: "decimal",
      isRequired: false,
      description: "discount of item",
    },
  ]);
  const [jsonData, setJsonData] = useState({
    orderList: [
      {
        storeId: 2,
        channelId: 0,
        orderTypeId: 2,
        orderDate: "2023-07-12T12:08:41.633Z",
        description: "create order",
        remarks: "create order",
        amount: 20,
        cShippingCharges: 20,
        paymentStatusId: 2,
        weight: 20,
        itemValue: 20,
        orderRequestVia: 20,
        paymentMethodId: 2,
        stationId: 2,
        discount: 200,
        vat: 2,
        orderNote: {
          note: "create order",
        },
        orderAddress: {
          customerName: "alii",
          email: "alii@gmail.com",
          mobile1: "038738942698",
          mobile2: "038738942698",
          countryId: 2,
          regionId: 2,
          cityId: 2,
          streetAddress: "technobatch",
          latitude: 0,
          longitude: 0,
        },
        orderItems: [
          {
            productId: "AEC239E0-6C70-4C97-9D07-6F579337BC12",
            productStockId: 20,
            price: 20,
            description: "create ordertest",
            remarks: "create ordertest",
            quantity: 20,
            discount: 20,
          },
        ],
      },
    ],
  });
  const [responseData, setResponseData] = useState({
    result: {
      data: [
        {
          OrderId: "05dc9113-5544-4a30-8c5d-42ab41012b22",
          OrderNo: "10000024",
        },
      ],
      message: "The action perform successfully",
    },
    isSuccess: true,
    errors: {},
    errorCombined: {},
    errorID: 0,
    statusCode: 0,
  });
  return (
    <>
      <DocumentationOrder
        requestType={EnumRequestType.Post}
        name="Create Order"
        endPoint={`${process.env.REACT_APP_Prod_BaseUrl}/api/CreateOrder`}
        jsonData={jsonData}
        responseData={responseData}
        format={format}
        auth
      />
    </>
  );
}
export default CreateOrderDocumentation;
