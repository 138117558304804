export const carrierEnglish = {
  INTEGRATION_CARRIER_TEXT: "Carriers",
  INTEGRATION_CARRIER_TEXT_S: "Carrier(s)",
  INTEGRATION_CARRIER_ALL_CARRIERS: "All Carriers",
  INTEGRATION_CARRIER_ACTIVE: "Active",
};
export const carrierArabic = {
  INTEGRATION_CARRIER_TEXT: "Carriers",
  INTEGRATION_CARRIER_TEXT_S: "Carrier(s)",
  INTEGRATION_CARRIER_ALL_CARRIERS: "كل الناقلين",
  INTEGRATION_CARRIER_ACTIVE: "نشط",
};
export const carrierChines = {
  INTEGRATION_CARRIER_TEXT: "Carriers",
  INTEGRATION_CARRIER_TEXT_S: "Carrier(s)",
  INTEGRATION_CARRIER_ALL_CARRIERS: "所有承运人",
  INTEGRATION_CARRIER_ACTIVE: "活跃的",
};
