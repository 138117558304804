export const deliveryTaskEnglish = {
  MY_CARRIER_DELIVERY_TASK_S_TEXT: "Delivery Task(s)",
  MY_CARRIER_DELIVERY_TASKS_TEXT: "Delivery Tasks",
  MY_CARRIER_DELIVERY_TASKS_All: "All",
  MY_CARRIER_DELIVERY_TASKS_UNASSIGNED: "Unassigned",
  MY_CARRIER_DELIVERY_TASKS_ASSIGNED: "Assigned",
  MY_CARRIER_DELIVERY_TASKS_CHOOSE_FROM_MAP: "Choose From Map",
  MY_CARRIER_DELIVERY_TASKS_CHOOSE_FROM_GRID: "Choose From Grid",
  MY_CARRIER_DELIVERY_TASKS_ORDER_NO: "Order No.",
  MY_CARRIER_DELIVERY_TASKS_CARRIER_STATUS: "Carrier Status",
  MY_CARRIER_DELIVERY_TASKS_CUSTOMER: "Customer",
  MY_CARRIER_DELIVERY_TASKS_LOCATION: "Location",
  MY_CARRIER_DELIVERY_TASKS_DRIVER: "Driver",
  MY_CARRIER_DELIVERY_TASKS_TASK_STATUS: "Task Status",
  MY_CARRIER_DELIVERY_TASKS_ORDER_DATE: "Order Date",
  MY_CARRIER_DELIVERY_TASKS_COD: "COD",
  MY_CARRIER_DELIVERY_TASKS_COORDINATES: "Coordinates",
  MY_CARRIER_DELIVERY_TASKS_BATCH_REVERT: "Batch Revert",
  MY_CARRIER_DELIVERY_TASKS_FILTER: "Filter",
  MY_CARRIER_DELIVERY_TASKS_START_DATE: "Start Date",
  MY_CARRIER_DELIVERY_TASKS_END_DATE: "End Date",
  MY_CARRIER_DELIVERY_TASKS_STORE: "Store",
  MY_CARRIER_DELIVERY_TASKS_DRIVER: "Driver",
  MY_CARRIER_DELIVERY_TASKS_INCLUDE: "Include",
  MY_CARRIER_DELIVERY_TASKS_EXCLUDE: "Exclude",
  MY_CARRIER_DELIVERY_TASKS_COUNTRY: "Country",
  MY_CARRIER_DELIVERY_TASKS_CARRIER_TRACKING_STATUS: "Carrier Tracking Status",
  MY_CARRIER_DELIVERY_TASKS_DELIVERY_TASK_STATUS: "Delivery Task Status",
  MY_CARRIER_DELIVERY_TASKS_REGION_CITY: "Region/City",
  MY_CARRIER_DELIVERY_TASKS_AREA_NEIGHBOURHOOD: "Area/Neighbourhood",
  MY_CARRIER_DELIVERY_TASKS_SELECT_PLEASE: "Select Please",
  MY_CARRIER_DELIVERY_TASKS_ACTION: "Action",
};
export const deliveryTaskArabic = {
  MY_CARRIER_DELIVERY_TASK_S_TEXT: "Delivery Task(s)",
  MY_CARRIER_DELIVERY_TASKS_TEXT: "Delivery Tasks",
  MY_CARRIER_DELIVERY_TASKS_All: "الجميع",
  MY_CARRIER_DELIVERY_TASKS_UNASSIGNED: "غير معين",
  MY_CARRIER_DELIVERY_TASKS_ASSIGNED: "معين",
  MY_CARRIER_DELIVERY_TASKS_CHOOSE_FROM_MAP: "اختيار من الخريطة",
  MY_CARRIER_DELIVERY_TASKS_CHOOSE_FROM_GRID: "اختر من الشبكة",
  MY_CARRIER_DELIVERY_TASKS_ORDER_NO: "رقم الطلب",
  MY_CARRIER_DELIVERY_TASKS_CARRIER_STATUS: "حالة الناقل",
  MY_CARRIER_DELIVERY_TASKS_CUSTOMER: "العميل",
  MY_CARRIER_DELIVERY_TASKS_LOCATION: "الموقع",
  MY_CARRIER_DELIVERY_TASKS_DRIVER: "السائق",
  MY_CARRIER_DELIVERY_TASKS_TASK_STATUS: "حالة المهمة",
  MY_CARRIER_DELIVERY_TASKS_ORDER_DATE: "تاريخ الطلب",
  MY_CARRIER_DELIVERY_TASKS_COD: "الدفع عند الاستلام",
  MY_CARRIER_DELIVERY_TASKS_COORDINATES: "إحداثيات",
  MY_CARRIER_DELIVERY_TASKS_BATCH_REVERT: "إرجاع التجميع",
  MY_CARRIER_DELIVERY_TASKS_FILTER: "تصفية",
  MY_CARRIER_DELIVERY_TASKS_START_DATE: "تاريخ البداية",
  MY_CARRIER_DELIVERY_TASKS_END_DATE: "تاريخ النهاية",
  MY_CARRIER_DELIVERY_TASKS_STORE: "المتجر",
  MY_CARRIER_DELIVERY_TASKS_DRIVER: "السائق",
  MY_CARRIER_DELIVERY_TASKS_INCLUDE: "شمل",
  MY_CARRIER_DELIVERY_TASKS_EXCLUDE: "استبعاد",
  MY_CARRIER_DELIVERY_TASKS_COUNTRY: "الدولة",
  MY_CARRIER_DELIVERY_TASKS_CARRIER_TRACKING_STATUS: "حالة تتبع الناقل",
  MY_CARRIER_DELIVERY_TASKS_DELIVERY_TASK_STATUS: "حالة مهمة التسليم",
  MY_CARRIER_DELIVERY_TASKS_REGION_CITY: "المنطقة/المدينة",
  MY_CARRIER_DELIVERY_TASKS_AREA_NEIGHBOURHOOD: "المنطقة/الحي",
  MY_CARRIER_DELIVERY_TASKS_SELECT_PLEASE: "يرجى التحديد",
  MY_CARRIER_DELIVERY_TASKS_ACTION: "فعل",
};
export const deliveryTaskChines = {
  MY_CARRIER_DELIVERY_TASK_S_TEXT: "Delivery Task(s)",
  MY_CARRIER_DELIVERY_TASKS_TEXT: "Delivery Tasks",
  MY_CARRIER_DELIVERY_TASKS_All: "全部",
  MY_CARRIER_DELIVERY_TASKS_UNASSIGNED: "未分配",
  MY_CARRIER_DELIVERY_TASKS_ASSIGNED: "已分配",
  MY_CARRIER_DELIVERY_TASKS_CHOOSE_FROM_MAP: "从地图选择",
  MY_CARRIER_DELIVERY_TASKS_CHOOSE_FROM_GRID: "从网格中选择",
  MY_CARRIER_DELIVERY_TASKS_ORDER_NO: "订单号",
  MY_CARRIER_DELIVERY_TASKS_CARRIER_STATUS: "承运人状态",
  MY_CARRIER_DELIVERY_TASKS_CUSTOMER: "客户",
  MY_CARRIER_DELIVERY_TASKS_LOCATION: "位置",
  MY_CARRIER_DELIVERY_TASKS_DRIVER: "司机",
  MY_CARRIER_DELIVERY_TASKS_TASK_STATUS: "任务状态",
  MY_CARRIER_DELIVERY_TASKS_ORDER_DATE: "订单日期",
  MY_CARRIER_DELIVERY_TASKS_COD: "货到付款",
  MY_CARRIER_DELIVERY_TASKS_COORDINATES: "坐标",
  MY_CARRIER_DELIVERY_TASKS_BATCH_REVERT: "批量还原",
  MY_CARRIER_DELIVERY_TASKS_FILTER: "筛选",
  MY_CARRIER_DELIVERY_TASKS_START_DATE: "开始日期",
  MY_CARRIER_DELIVERY_TASKS_END_DATE: "结束日期",
  MY_CARRIER_DELIVERY_TASKS_STORE: "店铺",
  MY_CARRIER_DELIVERY_TASKS_DRIVER: "司机",
  MY_CARRIER_DELIVERY_TASKS_INCLUDE: "包括",
  MY_CARRIER_DELIVERY_TASKS_EXCLUDE: "排除",
  MY_CARRIER_DELIVERY_TASKS_COUNTRY: "国家",
  MY_CARRIER_DELIVERY_TASKS_CARRIER_TRACKING_STATUS: "承运人跟踪状态",
  MY_CARRIER_DELIVERY_TASKS_DELIVERY_TASK_STATUS: "交付任务状态",
  MY_CARRIER_DELIVERY_TASKS_REGION_CITY: "地区/城市",
  MY_CARRIER_DELIVERY_TASKS_AREA_NEIGHBOURHOOD: "区域/社区",
  MY_CARRIER_DELIVERY_TASKS_SELECT_PLEASE: "请选择",
  MY_CARRIER_DELIVERY_TASKS_ACTION: "行动",
};
