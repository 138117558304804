import {
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";
import CustomReactDatePickerInputFilter from "../../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import { GetAllProducts } from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import GeneralTabBar from "../../../components/shared/tabsBar";
import UtilityClass from "../../../utilities/UtilityClass";
import { EnumOptions, viewTypesEnum } from "../../../utilities/enum";
import { useGetAllStores } from "../../../utilities/helpers/HelpersFilter";
import ProducList from "./productList";
function Products(props) {
  const [products, setProducts] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const [viewMode, setViewMode] = useState(viewTypesEnum.TABLE);
  const {
    allStores,
    selectedStore,
    setSelctedStore,
    storeId,
    resetSelctedStore,
  } = useGetAllStores();
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetDates,
    startDateFormated,
    endDateFormated,
  } = useDateRangeHook();
  let getAllProducts = async () => {
    setIsGridLoading(true);
    let res = await GetAllProducts({
      filterModel: {
        createdFrom: startDateFormated ? startDateFormated : null,
        createdTo: endDateFormated ? endDateFormated : null,
        start: 0,
        length: 10000,
        search: "",
        sortDir: "desc",
        sortCol: 0,
      },
      storeId: storeId,
    });
    // console.log("data:::", res.data);
    setIsGridLoading(false);

    if (res.data.result !== null) setProducts(res.data.result.list);
  };
  const [isfilterClear, setIsfilterClear] = useState(false);

  const handleFilterRest = () => {
    resetDates();
    resetSelctedStore();
    setIsfilterClear(true);
  };
  // useEffect(() => {
  //   getAllProducts();
  // }, []);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/products") {
      handleFilterRest();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (isfilterClear) {
      getAllProducts();
      setIsfilterClear(false);
    }
  }, [isfilterClear]);
  return (
    <Box sx={styleSheet.pageRoot}>
      <div style={{ padding: "10px" }}>
        {" "}
        <GeneralTabBar
          tabScreen={"products"}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
          setViewMode={setViewMode}
          viewMode={viewMode}
          tabData={[
            {
              label: LanguageReducer?.languageType?.PRODUCT_ALL,
              route: "/products",
            },
            {
              label: LanguageReducer?.languageType?.PRODUCT_ACTIVE,
              route: "/products/active",
            },
            {
              label: LanguageReducer?.languageType?.PRODUCT_IN_ACTIVE,
              route: "/products/in-active",
            },
          ]}
          disableSearch
          {...props}
        />
        {isFilterOpen ? (
          <Table
            sx={{ ...styleSheet.generalFilterArea }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <Grid container spacing={2} sx={{ p: "15px 10px" }}>
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.PRODUCTS_START_DATE}
                      </InputLabel>

                      <CustomReactDatePickerInputFilter
                        value={startDate}
                        maxDate={UtilityClass.todayDate()}
                        onClick={(date) => setStartDate(date)}
                        size="small"
                        isClearable

                        // inputProps={{ style: { padding: "2px 5px" } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.PRODUCTS_END_DATE}
                      </InputLabel>
                      <CustomReactDatePickerInputFilter
                        value={endDate}
                        onClick={(date) => setEndDate(date)}
                        size="small"
                        minDate={startDate}
                        maxDate={UtilityClass.todayDate()}
                        disabled={!startDate ? true : false}
                        isClearable
                        // inputProps={{ style: { padding: "2px 5px" } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.PRODUCTS_STORE}
                      </InputLabel>
                      <SelectComponent
                        name="reason"
                        height={28}
                        options={allStores}
                        value={selectedStore}
                        optionLabel={EnumOptions.STORE.LABEL}
                        optionValue={EnumOptions.STORE.VALUE}
                        getOptionLabel={(option) => option?.storeName}
                        onChange={(e, val) => {
                          setSelctedStore(val);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Stack
                      alignItems="flex-end"
                      direction="row"
                      spacing={1}
                      sx={{ ...styleSheet.filterButtonMargin, height: "100%" }}
                    >
                      <Button
                        sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                          handleFilterRest();
                        }}
                      >
                        {LanguageReducer?.languageType?.PRODUCTS_CLEAR_FILTER}
                      </Button>
                      <Button
                        sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                        variant="contained"
                        onClick={() => {
                          getAllProducts();
                        }}
                      >
                        {LanguageReducer?.languageType?.PRODUCT_FILTER}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </TableRow>
            </TableHead>
          </Table>
        ) : null}
        <Routes>
          <Route
            path="/"
            element={
              <ProducList
                isGridLoading={isGridLoading}
                products={products}
                getAllProducts={getAllProducts}
                isFilterOpen={isFilterOpen}
                viewMode={viewMode}
              />
            }
          />
          <Route
            path="/active"
            element={
              <ProducList
                isGridLoading={isGridLoading}
                getAllProducts={getAllProducts}
                viewMode={viewMode}
                products={products.filter((product) => {
                  return product.Active === true;
                })}
              />
            }
          />
          <Route
            path="/in-active"
            element={
              <ProducList
                getAllProducts={getAllProducts}
                isGridLoading={isGridLoading}
                viewMode={viewMode}
                products={products.filter((product) => {
                  return product.Active === false;
                })}
              />
            }
          />
        </Routes>
      </div>
      {/* </Container> */}
    </Box>
  );
}
export default Products;
