import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, ButtonBase, Typography } from "@mui/material";
import React, { useState } from "react";
import ButtonComponent from "../../../.reUseableComponents/Buttons/ButtonComponent";
import useDeleteConfirmation from "../../../.reUseableComponents/CustomHooks/useDeleteConfirmation.js";
import DeleteConfirmationModal from "../../../.reUseableComponents/Modal/DeleteConfirmationModal.js";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent.js";
import TextFieldComponent from "../../../.reUseableComponents/TextField/TextFieldComponent";
import TextFieldLableComponent from "../../../.reUseableComponents/TextField/TextFieldLableComponent";
import { OrderIcon, ShipmentIcon } from "../../../components/sideNavBar/icons";
import { EnumOptions } from "../../../utilities/enum/index.js";
import {
  DeleteIconButton,
  EditIconButton,
  GreyBox,
  GridContainer,
  GridItem,
  PageMainBox,
  SelectComponent32Height,
  greyBorder,
  useFormForArray,
} from "../../../utilities/helpers/Helpers";
import { ProfileDetailsBox } from "../../Profile/Profile/Profile";
import UpdateMetafieldModal from "./Modals/UpdateMetafield.js";
import { useSelector } from "react-redux";

const tabsData = [
  { id: 1, title: "Order", icon: <OrderIcon /> },
  { id: 2, title: "Shipments", icon: <ShipmentIcon /> },
];

const metafieldsTypeOptions = [
  { id: 1, label: "text" },
  { id: 2, label: "number" },
  { id: 3, label: "date" },
];

const Tab = ({ title, icon, onClick }) => {
  return (
    <Box
      className={"flex_between"}
      component={ButtonBase}
      onClick={onClick}
      sx={{
        height: 30,
        bgcolor: "#fff",
        border: greyBorder,
        borderRadius: 1.25,
        p: 1,
      }}
    >
      <Box className={"flex_between"} gap={1}>
        {icon}
        <Typography variant="h5">{title}</Typography>
      </Box>
      <ArrowForwardIosIcon fontSize="small" />
    </Box>
  );
};

export default function MetaFields() {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [selectedTab, setSelectedTab] = useState({});
  const singleMetaField = {
    name: "",
    description: "",
    type: "",
  };
  const [metafieldsOld, setMetafieldsOld] = useState([]);
  const [metafields, setMetafields] = useState([]);
  const [selectedMetafield, setSelectedMetafield] = useState({
    open: false,
    data: {},
  });
  const {
    errors,
    handleInvalid,
    handleChange,
    handleChangeAutoComplete,
    setErrors,
  } = useFormForArray(setMetafields);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMetafields([]);
    setMetafieldsOld(metafields);
  };
  const { openDelete, setOpenDelete } = useDeleteConfirmation();
  return (
    <>
      <PageMainBox>
        <GridContainer>
          <GridItem xs={4}>
            <ProfileDetailsBox
              title={
                LanguageReducer?.languageType
                  ?.SETTING_META_FIELD_META_FIELD_DEFINITION
              }
              description={
                LanguageReducer?.languageType
                  ?.SETTING_META_FIELD_ADD_CUSTOM_DATA
              }
            >
              <Box className={"flex_col"} gap={1}>
                {tabsData.map((dt) => (
                  <Tab
                    key={dt.id}
                    title={dt.title}
                    icon={dt.icon}
                    onClick={() => {
                      setErrors([]);
                      setSelectedTab(dt);
                      if (selectedTab.id !== dt.id) {
                        setMetafields([]);
                      }
                    }}
                  />
                ))}
              </Box>
            </ProfileDetailsBox>
          </GridItem>
          <GridItem xs={8}>
            {Object.entries(selectedTab).length > 0 && (
              <ProfileDetailsBox
                title={`${selectedTab.title} ${LanguageReducer?.languageType?.SETTING_META_FIELD_META_FIELD_DEFINITION}`}
                description={
                  LanguageReducer?.languageType
                    ?.SETTING_META_FIELD_ADD_CUSTOM_DATA
                }
                rightBtn={
                  <ButtonComponent
                    title={
                      LanguageReducer?.languageType
                        ?.SETTING_META_FIELD_ADD_META_FIELD
                    }
                    onClick={() => {
                      setErrors([]);
                      setMetafields((prev) => [...prev, singleMetaField]);
                    }}
                  />
                }
              >
                <GridContainer spacing={1}>
                  <GridItem xs={12}>
                    <GridContainer spacing={1}>
                      {metafieldsOld.map((mt_field, index) => (
                        <GridItem xs={12} key={index}>
                          <GreyBox
                            sx={{
                              bgcolor: "white",
                              position: "relative",
                              "&:hover": {
                                button: {
                                  opacity: 1,
                                },
                              },
                            }}
                          >
                            <GridContainer key={index} spacing={1}>
                              <GridItem xs={12}>
                                <TextFieldLableComponent
                                  title={"Name"}
                                  required={false}
                                />
                                <TextFieldComponent
                                  name={`name_${index}`}
                                  value={mt_field.name}
                                  onChange={handleChange}
                                  errors={errors}
                                  disabled={true}
                                />
                              </GridItem>
                              <GridItem xs={12}>
                                <TextFieldLableComponent
                                  title={"Description"}
                                  required={false}
                                />
                                <TextFieldComponent
                                  name={`description_${index}`}
                                  value={mt_field.description}
                                  onChange={handleChange}
                                  required={false}
                                  disabled={true}
                                />
                              </GridItem>
                              <GridItem xs={12}>
                                <TextFieldLableComponent
                                  title={"Type"}
                                  required={false}
                                />
                                <SelectComponent
                                  value={mt_field.type}
                                  name={`type_${index}`}
                                  options={metafieldsTypeOptions}
                                  optionLabel={
                                    EnumOptions.METAFIELD_TYPES.LABEL
                                  }
                                  optionValue={
                                    EnumOptions.METAFIELD_TYPES.VALUE
                                  }
                                  {...SelectComponent32Height}
                                  onChange={handleChangeAutoComplete}
                                  disabled={true}
                                />
                              </GridItem>
                            </GridContainer>
                            <EditIconButton
                              sx={{
                                position: "absolute",
                                zIndex: 10,
                                top: 0,
                                right: 25,
                                opacity: 0,
                              }}
                              onClick={() => {
                                setSelectedMetafield((prev) => ({
                                  ...prev,
                                  open: true,
                                  data: mt_field,
                                }));
                              }}
                            />

                            <DeleteIconButton
                              sx={{
                                position: "absolute",
                                zIndex: 10,
                                top: 0,
                                right: 0,
                                opacity: 0,
                              }}
                              onClick={() => {
                                setOpenDelete(true);

                                // setMetafieldsOld((prev) => {
                                //   const _metaFields = [...prev];
                                //   const _filteredMetaFields = _metaFields.filter(
                                //     (_, ind) => ind !== index
                                //   );
                                //   return _filteredMetaFields;
                                // });
                              }}
                            />
                          </GreyBox>
                        </GridItem>
                      ))}
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12}>
                    {metafields.length > 0 && (
                      <form onSubmit={handleSubmit} onInvalid={handleInvalid}>
                        <GridContainer spacing={1}>
                          {metafields.map((mt_field, index) => (
                            <GridItem xs={12} key={index}>
                              <GreyBox
                                sx={{ bgcolor: "white", position: "relative" }}
                              >
                                <GridContainer key={index} spacing={1}>
                                  <GridItem xs={12}>
                                    <TextFieldLableComponent title={"Name"} />
                                    <TextFieldComponent
                                      name={`name_${index}`}
                                      value={mt_field.name}
                                      onChange={handleChange}
                                      errors={errors}
                                    />
                                  </GridItem>
                                  <GridItem xs={12}>
                                    <TextFieldLableComponent
                                      title={"Description"}
                                      required={false}
                                    />
                                    <TextFieldComponent
                                      name={`description_${index}`}
                                      value={mt_field.description}
                                      onChange={handleChange}
                                      required={false}
                                    />
                                  </GridItem>
                                  <GridItem xs={12}>
                                    <TextFieldLableComponent title={"Type"} />
                                    <SelectComponent
                                      value={mt_field.type}
                                      name={`type_${index}`}
                                      required={true}
                                      options={metafieldsTypeOptions}
                                      optionLabel={
                                        EnumOptions.METAFIELD_TYPES.LABEL
                                      }
                                      optionValue={
                                        EnumOptions.METAFIELD_TYPES.VALUE
                                      }
                                      {...SelectComponent32Height}
                                      errors={errors}
                                      onChange={handleChangeAutoComplete}
                                    />
                                  </GridItem>
                                </GridContainer>
                                <DeleteIconButton
                                  sx={{
                                    position: "absolute",
                                    zIndex: 10,
                                    top: 0,
                                    right: 0,
                                  }}
                                  onClick={() => {
                                    setMetafields((prev) => {
                                      const _metaFields = [...prev];
                                      const _filteredMetaFields =
                                        _metaFields.filter(
                                          (_, ind) => ind !== index
                                        );
                                      return _filteredMetaFields;
                                    });
                                  }}
                                />
                              </GreyBox>
                            </GridItem>
                          ))}
                          <GridItem xs={12} textAlign={"right"}>
                            <ButtonComponent
                              title={
                                LanguageReducer?.languageType
                                  ?.SETTING_META_FIELD_SAVE
                              }
                              type={"submit"}
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </GridItem>
                </GridContainer>
              </ProfileDetailsBox>
            )}
          </GridItem>
        </GridContainer>
      </PageMainBox>
      {selectedMetafield.open && (
        <UpdateMetafieldModal
          open={selectedMetafield.open}
          data={selectedMetafield.data}
          onClose={() => {
            setSelectedMetafield((prev) => ({ ...prev, open: false }));
          }}
        />
      )}
      {openDelete && (
        <DeleteConfirmationModal
          open={openDelete}
          setOpen={setOpenDelete}
          handleDelete={() => {}}
          messageDetails={"All data will be removed of this field."}
        />
      )}
    </>
  );
}
