import {
  carrierSettlementArabic,
  carrierSettlementChines,
  carrierSettlementEnglish,
} from "./carrierSettlement/carrierSettlement";
import {
  codPendingArabic,
  codPendingChines,
  codPendingEnglish,
} from "./codPending/codPending";

export const accountEnglish = {
  ...codPendingEnglish,
  ...carrierSettlementEnglish,
  ACCOUNTS: "Accounts",
};
export const accountArabic = {
  ...codPendingArabic,
  ...carrierSettlementArabic,
  ACCOUNTS: "حسابات",
};
export const accountChinese = {
  ...codPendingChines,
  ...carrierSettlementChines,
  ACCOUNTS: "账户",
};
