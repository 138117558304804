import {
  ApiIntegrationArabic,
  ApiIntegrationChines,
  ApiIntegrationEnglish,
} from "./apiIntegration/apiIntegration";
import {
  carrierArabic,
  carrierChines,
  carrierEnglish,
} from "./carriers/carrier";
import {
  paymentIntegrationArabic,
  paymentIntegrationChines,
  paymentIntegrationEnglish,
} from "./paymentIntegration/paymentIntegration";
import {
  saleChannelArabic,
  saleChannelChinese,
  saleChannelEnglish,
} from "./saleChannels/saleChannel";
import {
  smsIntegrationArabic,
  smsIntegrationChines,
  smsIntegrationEnglish,
} from "./smsIntegration/smsIntegration";

export const integrationEnglish = {
  ...ApiIntegrationEnglish,
  ...carrierEnglish,
  ...paymentIntegrationEnglish,
  ...saleChannelEnglish,
  ...smsIntegrationEnglish,
  INTEGRATION: "Integration",
};
export const integrationArabic = {
  ...ApiIntegrationArabic,
  ...carrierArabic,
  ...paymentIntegrationArabic,
  ...saleChannelArabic,
  ...smsIntegrationArabic,
  INTEGRATION: "اندماج",
};
export const integrationChinese = {
  ...ApiIntegrationChines,
  ...carrierChines,
  ...paymentIntegrationChines,
  ...saleChannelChinese,
  ...smsIntegrationChines,
  INTEGRATION: "一体化",
};
