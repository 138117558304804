import { removeThisKeyCookie } from "../../utilities/cookies";
import { EnumCookieKeys } from "../../utilities/enum";

export default function setLogoutCookie(res) {
  removeThisKeyCookie("isUserProfileSideBarShow");
  removeThisKeyCookie("access_token");
  removeThisKeyCookie("refresh_token");
  removeThisKeyCookie("user_name");
  removeThisKeyCookie("id_token");
  removeThisKeyCookie("expires_in");
  removeThisKeyCookie("patronTypeId");
  removeThisKeyCookie(EnumCookieKeys.TIME_ZONE);
}
